// @ts-ignore
import {CommonModule} from '@angular/common';
import {Component, HostBinding, Input, OnInit, ViewEncapsulation, HostListener, ElementRef} from '@angular/core';
import {faPowerOff} from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';

import {AppsettingsService} from './services/appsettings.service';
import {ProgressLoadingServiceService} from './services/auth/progress-loading-service.service';
import {AppDataFetchService} from "./services/app-data-fetch.service";

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-root]',
  templateUrl: 'app.component.html',
  styleUrls: ['./styles/global.scss'],
})

export class AppComponent implements OnInit {
  title = 'Open BVR';
  config: any;
  logOutURL: string;
  loggedInUserInfo: any;
  faPowerOff = faPowerOff;
  isMenuSmall = true;
  sideBarOpen = false;
  loading = false;

  constructor(public progressLoadingService: ProgressLoadingServiceService,
              private apiDataFetchService: AppDataFetchService,
              private configService: AppsettingsService,
              private el: ElementRef,
              private router: Router) {
    this.config = this.configService.configSettings;
    this.logOutURL = localStorage.getItem('inCognitoSignoutUrl');
  }

  ngOnInit(): void {
    this.loggedInUserInfo = {};
    this.currentLoggedInUser();

    this.progressLoadingService
      .onLoadingChanged
      .subscribe(isLoading => setTimeout(() => this.loading = isLoading, 0));

  }

  currentLoggedInUser(): void {
    this.loggedInUserInfo = this.config.SESSIONS.AUTH_USER;
  }

  isProductActive = () => {
    const currentUrl = this.router.url;
    if (currentUrl) {
      return currentUrl.includes('kpi_product_details');
    }
    return false;
  }

  isDashboardActive = () => {
    const currentUrl = this.router.url;
    if (currentUrl) {
      return currentUrl.includes('kpi_dashboard') ||
        currentUrl.includes('kpi_charts_and_data') ||
        currentUrl.includes('kpi_firm_account_profile');
    }
    return false;
  }

}
