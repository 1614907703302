// dist-chart.component.ts
import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import * as c3 from 'c3';

@Component({
  selector: 'app-dist-chart',
  template: '<div></div>',
  styleUrls: ['./dist-chart.component.less'],
})
export class DistChartComponent implements OnChanges {
  @Input() data: any;
  @Input() rangeMin: number;
  @Input() rangeMax: number;
  @Input() filterFloor: number;
  @Input() filterCeil: number;
  @Input() filterName: string;

  private lineChart: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && this.data) {
      if (this.lineChart) {
        this.lineChart.load(this.data);
      } else {
        this.lineChart = this.drawChart(this.data);
      }
    }

    if ((changes.rangeMin || changes.rangeMax) && this.rangeMin !== undefined && this.rangeMax !== undefined) {
      this.lineChart.regions([
        {
          axis: 'x',
          start: this.rangeMin,
          end: this.rangeMax,
          class: 'filterRegionSelected',
        },
      ]);

      console.warn("need more cleaner method for this");
      this.lineChart = this.drawChart(this.data);

    }
  }

  private drawChart(data: any): any {
    return c3.generate({
      bindto: this.getChartElement(),
      size: {
        width: 600,
        height: 195,
      },
      data: this.data,
      axis: {
        x: {show: false},
        y: {show: false},
      },
      regions: [
        {
          axis: 'x',
          start: this.rangeMin,
          end: this.rangeMax,
          class: 'filterRegionSelected',
        },
      ],
      tooltip: {
        contents: (d, defaultTitleFormat, defaultValueFormat, color) => {
          if (d.length === 1 && d[0].id === 'distribution') {
            // @ts-ignore
            return this.getCustomTooltip(d[0]?.name, d[0]?.x, d[0]?.value);
          } else {
            return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
          }
        },
      },
    });
  }

  private getChartElement(): HTMLElement {
    // You may need to adapt this based on your application structure
    return document.getElementById('c3-chart-placeholder');
  }

  private getCustomTooltip(distributionName: string, xVal: number, distributionVal: number): string {
    const gap = (this.filterCeil - this.filterFloor) / 12; // Assuming histogramBucketSize is always 12
    let distributionDesc = '';
    if (xVal === 0) {
      distributionDesc = 'Range(< ' + Math.round(this.filterFloor + gap * (xVal + 1)) + ')';
    } else if (xVal === 11) {
      distributionDesc = 'Range(>' + Math.round(this.filterFloor + gap * xVal) + ')';
    } else {
      distributionDesc =
        'Range(' + Math.round(this.filterFloor + gap * xVal) + ' to ' + Math.round(this.filterFloor + gap * (xVal + 1)) + ')';
    }
    const toolTipHtml =
      '<table class="c3-tooltip">' +
      '<tr><th colspan="2">' + this.filterName + '</th></tr>' +
      '<tr><td colspan="2">' + distributionDesc + '</td></tr>' +
      '<tr class="c3-tooltip-name--distribution"><td class="name"><span style="background-color:#0db9f0"></span># of records</td><td class="value">' +
      distributionVal +
      '</td></tr>' +
      '</table>';
    return toolTipHtml;
  }

  private getTooltipContent(d: any, defaultTitleFormat: any, defaultValueFormat: any, color: any): any {
    // Implement your tooltip content logic here
  }
}
