import {Component, ElementRef, Input, Output, EventEmitter} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-ds-date',
  template: `
    <input type="text" class="form-control" [ngClass]="{'custom-invalid-date': !isValidDate(dateVal)}"
           [ngModel]="dateVal" (ngModelChange)="onDateChange($event)"
           (keyup)="onKeyUp($event)" (paste)="onPaste($event)" (cut)="onCut($event)" [disabled]="isDisabled"
           [ngModelOptions]="{ standalone: true }">
  `,
  styles: ['.custom-invalid-date { border: 1px solid red; }']
})
export class DsDateComponent {
  @Input() dateVal = '';
  @Output() dateValChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onDateUpdate: EventEmitter<void> = new EventEmitter<void>();
  @Input() isDisabled = false; // Add the disabled property

  constructor(private el: ElementRef) {
  }

  onDateChange(newValue: string): void {
    this.dateVal = newValue;
    this.dateValChange.emit(newValue);
    this.emitDateUpdate();
  }

  isValidDate(dateValue: string): boolean {
    return moment(dateValue, 'MM/DD/YYYY', true).isValid();
  }

  emitDateUpdate(): void {
    this.onDateUpdate.emit();
  }

  onKeyUp(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight']; // Add more if needed
    if (!allowedKeys.includes(event.key) && !this.isNumericOrForwardSlash(event.key)) {
      event.preventDefault();
    }
  }

  onPaste(event: ClipboardEvent): void {
    const pastedData = event.clipboardData?.getData('text');
    if (pastedData && !this.isValidPastedData(pastedData)) {
      event.preventDefault();
    }
  }

  onCut(event: ClipboardEvent): void {
    const cutData = this.el.nativeElement.value.substring(this.el.nativeElement.selectionStart, this.el.nativeElement.selectionEnd);
    if (cutData && !this.isValidPastedData(cutData)) {
      event.preventDefault();
    }
  }

  isNumericOrForwardSlash(key: string): boolean {
    return /^\d$/.test(key) || key === '/';
  }

  isValidPastedData(pastedData: string): boolean {
    return /^[0-9/]+$/.test(pastedData);
  }
}
