import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';
import {faTrash, faSort} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.less']
})
export class DisplayComponent implements OnInit {
  @Input() gridCfg: any;
  @Input() fieldDefinitionsToolTipEnabled: any;
  @Output() removeColumnFromSortTab = new EventEmitter<any>();
  @Output() updateDisplayColsSelection = new EventEmitter<any>();
  faTrash = faTrash;
  faSort = faSort;

  columns = {
    availableCols: [], // Initialize with your data
    selectedCols: [], // Initialize with your data
    filteredColumns: [] // Initialize with your data
  };
  lastDragDroppedEvent: any = {};
  columnSearchText = '';

  constructor() {
  }

  ngOnInit(): void {
    this.columns.availableCols = this.gridCfg.availableCols;
    this.columns.selectedCols = this.gridCfg.defaultCols;
    this.updateForColumnsUpdate(); // Important: calling it at initial, so that screenercomponent "cols" get set to be used by printexcel
  }

  onDnbDragCb(item, list: any): any {
    const index = list.indexOf(item);
    list.splice(index, 1);
    this.updateDisplayColsSelection.emit(this.columns);
  }

  onDropped(event: any, list: any): any {
    if (list && (event.dropEffect === 'copy' || event.dropEffect === 'move')) {
      let index = event.index;
      if (typeof index === 'undefined') {
        index = list.length;
      }
      list.splice(index, 0, event.data);
      this.updateDisplayColsSelection.emit(this.columns);
    }
  }

  removeColFromSelectedCols(colIdx: number): void {
    if (this.columns.selectedCols.length <= 1) {
      console.log("Trying to remove the last column");
      return;
    }
    // First remove any sort applied to this column
    this.removeColumnFromSortTab.emit(this.columns.selectedCols[colIdx]);
    // Remove column from selected
    const removedCols = this.columns.selectedCols.splice(colIdx, 1);
    // Add column to available
    this.columns.availableCols.splice(0, 0, removedCols[0]);
    this.updateForColumnsUpdate();
  }

  private updateForColumnsUpdate(): void {
    this.updateDisplayColsSelection.emit(this.columns);
  }

  filterAvailableColumns(list): any[] {
    if (this.columnSearchText) {
      return _.filter(list, (co) => {
        return co.col_name.toLowerCase().includes(this.columnSearchText.toLowerCase());
      });
    } else {
      return list;
    }
  }

  async applySavedScreenDisplayCols(saved): Promise<void> {
    return new Promise<void>((resolve) => {
      if (saved && saved.selectedCols) {
        this.columns.selectedCols = saved.selectedCols;
        this.columns.availableCols = _.filter(this.columns.availableCols, (o) => {
          return !_.find(this.columns.selectedCols, {col_id: o.col_id});
        });
        this.updateForColumnsUpdate();
        resolve();
      }
      else{
        resolve();
      }
    });
  }


}
