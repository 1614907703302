import {Component, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';
import {ServerDataSource} from "ng2-smart-table";
import {ButtonViewComponent} from "../../building_block/st-buttonview/button-view.component";
import {SuperusersDataFetchService} from "../superusers-data-fetch.service";
import {NgbModal, NgbNavChangeEvent} from "@ng-bootstrap/ng-bootstrap";
import {HttpClient} from "@angular/common/http";
import {AppsettingsService} from "../../../services/appsettings.service";
import {DlgConfirmDeleteComponent} from "../../building_block/modal-dialogs/dlg-confirm-delete";
import {EditUserAccessComponent} from "./edit-user-access/edit-user-access.component";

@Component({
  selector: 'app-user-access-management',
  templateUrl: './user-access-management.component.html',
  styleUrls: ['./user-access-management.component.less']
})
export class UserAccessManagementComponent implements OnInit {
  config: any;
  errorMsgHtml;
  lpAppId = [
    {value: 'dealstats', title: "Deal Stats"},
    {value: 'valuemonitor', title: "Value Monitor"}
  ];
  lpApiKeyStatusId =  [
    {value: 1, title: "Active"},
    {value: 0, title: "Pending"},
    {value: -1, title: "Revoked"}
  ];
  lpAdminLevel =  [
    {title: 'Super User', value: 1},
    {title: 'Admin', value: 2},
    {title: 'Company', value: 3}
  ];

  // API Keys
  source: ServerDataSource;
  settings = {
    actions: {add: false, edit: false, delete: false},
    columns: {
      ActionPlaceholder: {
        title: '',
        type: 'custom',
        renderComponent: ButtonViewComponent,
        onComponentInitFunction: (instance) => {
          instance.buttonUi = {
            showEdit: true,
            showDelete: true,
            editBtnClass: 'btn btn-light',
            deleteBtnClass: 'btn btn-light'
          };
          instance.save.subscribe(row => {
            console.log('Editing: ', row);
            this.openEditUser(row);
          });
          instance.delete.subscribe(row => {
            console.log('Deleting: ', row);
            this.deleteUser(row);
          });
        },
        filter: false
      },
      sub: {
        title: 'Sub'
      },
      email: {
        title: 'Email'
      },
      adminLevel: {
        title: 'Admin Level',
        filter: {
          type: 'list',
          config: {
            selectText: 'Select',
            list: this.lpAdminLevel,
            resetText: 'clear'
          },
        },
        valuePrepareFunction: (data) => {
          if (data == null) {
            return '';
          }
          const found = this.lpAdminLevel.find(o => o.value === data);
          if (found) {
            return found.title;
          }
          return '';
        }
      },
      statusId: {
        title: 'Status',
        filter: {
          type: 'list',
          config: {
            selectText: 'Select',
            list: this.lpApiKeyStatusId,
            resetText: 'clear'
          },
        },
        valuePrepareFunction: (data) => {
          if (data == null) {
            return '';
          }
          const found = this.lpApiKeyStatusId.find(o => o.value === data);
          if (found) {
            return found.title;
          }
          return '';
        }
      },
      createdDatetime: {
        title: 'Created',
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          const raw = new Date(data);
          return this.datePipe.transform(raw, 'short');
        }

      },
      lastUpdatedDatetime: {
        title: 'Last Updated',
        filter: false,
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          const raw = new Date(data);
          return this.datePipe.transform(raw, 'short');
        }
      }
    },
    attr: {
      class: 'table table-st table-responsive'
    },
    pager: {
      display: true,
      perPage: 10
    },
    noDataMessage: ''
  };
  noAccessToGridMessage: any;

  constructor(
    private datePipe: DatePipe,
    private appDataFetchService: SuperusersDataFetchService,
    private modalService: NgbModal,
    private http: HttpClient,
    private configService: AppsettingsService) {
    this.config = this.configService.configSettings;
  }


  ngOnInit(): void {
    this.loadUsers();
  }

  private loadUsers(): void {
    this.source = new ServerDataSource(this.http, {
      endPoint: this.appDataFetchService.listUsers(),
      dataKey: 'result',
      pagerPageKey: 'currentPageNumber',
      pagerLimitKey: 'totalItemsCountPerPage',
      totalKey: 'totalItemsCount'
    });
    this.source.onChanged().subscribe(change => {
    });
  }

  openEditUser(param: any): void {
    const modalRef = this.modalService.open(EditUserAccessComponent, {size: 'xl'});
    modalRef.componentInstance.currentUserSub = param ? param.sub : null;
    modalRef.result.then(() => {
      this.source.refresh();
    }, (reason) => {
      this.errorMsgHtml = JSON.stringify(reason);
    });
  }

  private deleteUser(params: any): void {
    const modalRef = this.modalService.open(DlgConfirmDeleteComponent, {size: 'md'});
    modalRef.componentInstance.inputConfirmText = 'Delete';
    modalRef.componentInstance.proceedStatus.subscribe((receivedEntry) => {
      this.appDataFetchService.deleteUser(null, params).subscribe(data => {
        console.log(data);
        if (data) {
          this.loadUsers();
          modalRef.close();
        } else {
          modalRef.componentInstance.errorMsgHtml = "Unable to delete";
        }
      }, error => {
        modalRef.componentInstance.errorMsgHtml = JSON.stringify(error);
      });
    });
  }


}
