import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal, NgbNavChangeEvent} from "@ng-bootstrap/ng-bootstrap";
import {faChevronCircleDown, faChevronCircleUp} from "@fortawesome/free-solid-svg-icons";
import * as _ from 'lodash';

import {AppsettingsService} from "../../services/appsettings.service";
import {AppDataFetchService} from "../../services/app-data-fetch.service";
import {MasterDataTableComponent} from "./master-data-table/master-data-table.component";
import {SortComponent} from "./sort/sort.component";
import {ModelUtilsService} from "./services/model-utils.service";
import {PrintService} from "./services/print.service";
import {QuickSearchComponent} from "./quick-search/quick-search.component";
import {SearchComponent} from "./search/search.component";
import {DisplayComponent} from "./display/display.component";
import {DlgRecentComponent} from "./tool-bar-dialogs/dlg-recent/dlg-recent.component";
import {DlgSaveComponent} from "./tool-bar-dialogs/dlg-save/dlg-save.component";

@Component({
  selector: 'app-kpi-account-screening',
  templateUrl: './kpi-account-screening.component.html',
  styleUrls: ['./kpi-account-screening.component.less']
})
export class KpiAccountScreeningComponent implements OnInit {
  @ViewChild(QuickSearchComponent) quickSearchComponent: QuickSearchComponent;
  @ViewChild(SearchComponent) advanceSearchComponent: SearchComponent;
  @ViewChild(DisplayComponent) displayComponent: DisplayComponent;
  @ViewChild(MasterDataTableComponent) masterDataTableComponent: MasterDataTableComponent;
  @ViewChild(SortComponent) sortViewChildComponent: SortComponent;


  faChevronCircleUp = faChevronCircleUp;
  faChevronCircleDown = faChevronCircleDown;


  topObjectFramesTabCfg = {
    QUICKSEARCH: {
      POS: 1,
      LABEL: 'Quick Search'
    },
    SEARCH: {
      POS: 2,
      LABEL: 'Search'
    },
    DISPLAY: {
      POS: 3,
      LABEL: 'Display'
    },
    SORT: {
      POS: 4,
      LABEL: 'Sort'
    },
    DOWNLOAD: {
      POS: 5,
      LABEL: 'Download'
    }
  };


  isTopObjFramesCollapsed = false;
  activeTabId = 1;
  gridCfg;
  collapse: any;

  // Screener related var
  fieldDefinitionsToolTipDisabled = true;
  // applied saved screen
  appliedSavedScreen: any = {};
  // applied save scree end

  // final dm
  private filters: any;
  private filtersSearchDM: any;
  private advancedSearchDM: {};
  private columns = {
    selectedCols: undefined
  };
  private sortColsDm: any = {};

  // final dm end

  constructor(public appSettings: AppsettingsService,
              public  appDataFetchService: AppDataFetchService,
              private modelUtilsService: ModelUtilsService,
              private  printSvc: PrintService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    const gridCfgRaw = this.appSettings.settings.colCfg;
    this.fetch(gridCfgRaw).then(r => {
      this.gridCfg = r;
    });

  }

  async fetch(gridCfgRaw): Promise<any> {
    // Static Filter options
    const aggQ: any = {};
    const staticFilterOptionsColIds = _.filter(gridCfgRaw.master_grid_cols, (o: any) => {
      return o.has_static_filter_opt === true;
    }).map((o: any) => {
      return o.col_id;
    });
    _.each(staticFilterOptionsColIds, (v: any) => {
      aggQ[v] = {
        terms: {
          field: v + '.keyword',
          size: 50000
        }
      };
    });

    const staticFilterOptions = await this.appDataFetchService.esFieldAgg({
      searchBody: {
        size: 0,
        aggs: aggQ
      }
    }, {remark: staticFilterOptionsColIds.join(",")});

    // Default Cols
    const defaultCols = _.filter(gridCfgRaw.master_grid_cols, (col) => {
      const defaultCheck = col.hasOwnProperty("default_col") ? (col.default_col) : false;
      const shownCheck = col.hasOwnProperty("shown_in_ui") ? col.shown_in_ui : true;
      return defaultCheck && shownCheck;
    });

    const availableCols = _.filter(gridCfgRaw.master_grid_cols, col => {
      const notDefaultCheck = col.hasOwnProperty("default_col") ? !(col.default_col) : true;
      const shownCheck = col.hasOwnProperty("shown_in_ui") ? col.shown_in_ui : true;
      return notDefaultCheck && shownCheck;
    });

    const advancedSearchCols = _.chain(gridCfgRaw.master_grid_cols)
      .map(col => {
        if (col.hasOwnProperty('enable_advanced_searching') && !col.enable_advanced_searching) {
          return null;
        }
        let searchTypeCode;
        let prePadding;
        let padLength;
        switch (col.col_type) {
          case "numeric":
            searchTypeCode = 2;
            break;
          case "string":
            searchTypeCode = 1;
            break;
          case "numericBounds":
            searchTypeCode = 2;
            break;
          case "datestring":
            searchTypeCode = 4;
            break;
          case "bool":
            searchTypeCode = 5;
            break;
          case "tree":
            searchTypeCode = 2;
            prePadding = col.prepadding;
            padLength = col.pad_length;
            break;
          case "geo":
            searchTypeCode = 1;
            break;
        }
        return {
          col_id: col.col_id,
          col_name: col.col_name,
          col_group: col.col_group,
          formatter: col.formatter,
          search_type: searchTypeCode,
          col_tp: col.col_tp,
          prepadding: prePadding,
          pad_length: padLength
        };
      })
      .without(null)
      .value();

    const advancedSearchConfig = gridCfgRaw.search_config;

    const defaultFilterOrd = gridCfgRaw.default_filter_ord;

    const defaultFilters = _.sortBy(_.filter(gridCfgRaw.master_grid_cols,
      col => defaultFilterOrd.hasOwnProperty(col.col_id)), currCol => defaultFilterOrd[currCol.col_id]);

    const availableFilters = _.filter(gridCfgRaw.master_grid_cols, col => true);


    return {
      valIDsUniverse: null,
      dropDownColsOpts: null,
      staticFilterOptions,
      allCols: gridCfgRaw.master_grid_cols,
      defaultCols,
      availableCols,
      advancedSearchCols,
      advancedSearchConfig,
      defaultFilters,
      availableFilters
    };
  }

  topObjFrameTabChanged($event: NgbNavChangeEvent<any>): void {

  }

  // all communications are routed via central parent: kpi-account-screening-component and final dms
  removeColumnFromSortTab($event: any): void {
    this.sortViewChildComponent.removeColumnFromSortingDueToDisplayColsRemoval($event);
  }

  updateQuickSearchSelection($event: any): void {
    this.filters = $event;
    this.filtersSearchDM = $event.appliedFiltersDM;
    this.updateTableDMForSearch();
  }

  updateAdvSearchSelection($event: any): void {
    this.advancedSearchDM = {
      searchModel: $event.appliedSearchesDM,
      operator: $event.searchCriteria,
      activeSearchColIndex: $event.activeSearchColIndex,
      selectedCols: $event.selectedCols
    };
    this.updateTableDMForSearch();
  }

  updateDisplayColsSelection($event: any): void {
    this.columns = $event;
    const updatedStColsDef = this.modelUtilsService.getCurrentSTColDefs(this.columns.selectedCols);
    this.modelUtilsService.updateSTColDefsForSorting({
      stColDefs: updatedStColsDef,
      sortOrder: this.sortColsDm.selectedCols,
      sortModel: this.sortColsDm.appliedSortFiltersDM
    }, (updatedStColDefs) => {
      if (updatedStColDefs) {
        if (this.masterDataTableComponent) {
          this.masterDataTableComponent.stColDefs = updatedStColDefs;
          this.updateTableDMForSearch();
        }
      }
    });
  }

  updateSortColsSelection($event: any): void {
    this.sortColsDm = $event;
    const stColDefs = this.masterDataTableComponent.stColDefs;
    this.modelUtilsService.updateSTColDefsForSorting({
      stColDefs,
      sortOrder: this.sortColsDm.selectedCols,
      sortModel: this.sortColsDm.appliedSortFiltersDM
    }, (updatedStColDefs) => {
      if (updatedStColDefs) {
        this.masterDataTableComponent.stColDefs = updatedStColDefs;
        this.updateTableDMForSearch();
      }
    });

  }

  updateFiltersDMForTableQuickSort(params): any {
    const sortOrder = (params && params.sortOrder) ? params.sortOrder : null;
    const sortModel = (params && params.sortModel) ? params.sortModel : null;
    this.sortViewChildComponent.updateFiltersDMForTableQuickSort({
      sortOrder,
      sortModel
    });
  }

  private updateTableDMForSearch(): void {
    const f = {
      filters: this.filtersSearchDM,
      searchModel: this.advancedSearchDM,
      sortModel: {
        sort_order: this.sortColsDm.selectedCols,
        sort_model: this.sortColsDm.appliedSortFiltersDM
      }
    };
    this.masterDataTableComponent.updateTableContent(f);
  }

  // publishing
  saveAsExcel(pubOpts: string): void {
    this.printSvc.saveAsExcel(pubOpts, this.columns, this.gridCfg.allCols).then((result) => {
    }, (error) => {
    });
  }

  // save
  saveScreen(reSave?: boolean): void {
    const modalRef = this.modalService.open(DlgSaveComponent, {size: 'md'});
    modalRef.componentInstance.reSave = reSave;
    modalRef.componentInstance.screenerData = {
      filters: this.filters,
      advanced_search: this.advancedSearchDM,
      columns: _.pick(this.columns, ['selectedCols']),
      sortCols: _.pick(this.sortColsDm, ['selectedCols', 'appliedSortFiltersDM'])
    };
    if (reSave) {
      modalRef.componentInstance.originalScreenerInfo = {
        gridId: this.appliedSavedScreen.gridId,
        title: this.appliedSavedScreen.name
      };
    }
    modalRef.result.then((result) => {
      if (result) {
        console.log('saved: ', result);
        this.applySelectedScreen(result.gridId);
      }
    });
  }

  listScreen(): void {
    const modalRef = this.modalService.open(DlgRecentComponent, {size: 'lg'});
    modalRef.result.then((selectedScreen) => {
      if (selectedScreen && selectedScreen.gridId) {
        this.applySelectedScreen(selectedScreen.gridId);
      }
    });
  }

  async applySelectedScreen(selectedScreenGridId: string): Promise<void> {
    this.appliedSavedScreen = {};
    try {
      let savedScreenObj = await this.appDataFetchService.getScreenDetails(selectedScreenGridId).toPromise();
      if (savedScreenObj && savedScreenObj[0] && savedScreenObj[0].data) {
        savedScreenObj = savedScreenObj[0];
        const appliedSavedScreenDataJson = JSON.parse(savedScreenObj.data);

        await this.quickSearchComponent.applySavedScreenFilter(appliedSavedScreenDataJson.filters);
        await this.advanceSearchComponent.applySavedScreenAdvanceSearch(appliedSavedScreenDataJson.advanced_search);
        await this.displayComponent.applySavedScreenDisplayCols(appliedSavedScreenDataJson.columns);
        await this.sortViewChildComponent.applySavedScreenSortCols(appliedSavedScreenDataJson.sortCols);

        this.appliedSavedScreen.name = savedScreenObj.title;
        this.appliedSavedScreen.gridId = savedScreenObj.gridId;
        console.log('Applied selected screen successfully');
      }
    } catch (error) {
      console.error('Error applying selected screen:', error);
    }
  }


  openMenuDialog(params: string): void {
    if (params === 'save-screen' || params === 'save-as-screen') {
      this.saveScreen(params && params === 'save-screen');
    } else if (params === 'recent-screens') {
      this.listScreen();
    }
  }
}
