import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import * as Highcharts from 'highcharts';
import * as _ from 'lodash';
import {faChevronCircleDown, faChevronCircleUp} from "@fortawesome/free-solid-svg-icons";

import {AppDataFetchService} from "../../services/app-data-fetch.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {KpiChartsSettingModalComponent} from "../kpi_charts_setting_modal/kpi-charts-setting-modal.component";
import {HttpClient} from "@angular/common/http";

import {CustomServerDataSource} from "../custom-server-data-source";
import {KpiChartAndDataComponentCfg} from "./kpi-chart-and-data-component-cfg";
import {AppsettingsService} from "../../services/appsettings.service";

@Component({
  selector: 'app-kpi-charts-and-data-base',
  templateUrl: './kpi-charts-and-table-base-component.html',
  styleUrls: ['./kpi-charts-and-table-base.component.less']
})


export class KpiChartsAndTableBaseComponent implements OnInit {
  constructor(private  route: ActivatedRoute,
              private appDataFetchSvc: AppDataFetchService,
              private modalService: NgbModal,
              private http: HttpClient,
              public kpiChartDataComponentCfg: KpiChartAndDataComponentCfg,
              public appSettings: AppsettingsService) {

  }

  Highcharts: typeof Highcharts = Highcharts;
  faChevronCircleUp = faChevronCircleUp;
  faChevronCircleDown = faChevronCircleDown;

  kpiIdLabel = this.appSettings.settings.kpi;
  currentKpiName;
  type1ChartModalSavedStates;

  public chartObj;
  public dataObj;
  public accObj;
  specs = [
    {
      kpiName: this.kpiIdLabel.AVG_ACC.id,
      kpiLabel: this.kpiIdLabel.AVG_ACC.label,
      isChartCollapsed: false,
      isDataCollapsed: true,
      isAccountCollapsed: true,
      chartFunc: this.kpiChartDataComponentCfg.chart1,
      dataEndpoint: this.appDataFetchSvc.fetchKPIAvgAccValDataObjTable(),
      accEndpoint: this.appDataFetchSvc.fetchKPIAvgAccValDataAccObjTable(),
      dataSmartTableCfg: this.kpiChartDataComponentCfg.avgAccValDataObjTableCfg,
      accSmartTableCfg: this.kpiChartDataComponentCfg.avgAccValAccountObjTableCfg,
      kpiType: 1
    },
    {
      kpiName: this.kpiIdLabel.ACT_ACC.id,
      kpiLabel: this.kpiIdLabel.ACT_ACC.label,
      isChartCollapsed: false,
      isDataCollapsed: true,
      isAccountCollapsed: true,
      chartFunc: this.kpiChartDataComponentCfg.chart1,
      dataEndpoint: this.appDataFetchSvc.fetchKPIAvgAccValDataObjTable(),
      accEndpoint: this.appDataFetchSvc.fetchKPIAvgAccValDataAccObjTable(),
      dataSmartTableCfg: this.kpiChartDataComponentCfg.activeAccValDataObjTableCfg,
      accSmartTableCfg: this.kpiChartDataComponentCfg.activeAccValAccountObjTableCfg,
      kpiType: 1

    },
    {
      kpiName: this.kpiIdLabel.TOTAL_ACC.id,
      kpiLabel: this.kpiIdLabel.TOTAL_ACC.label,
      isChartCollapsed: false,
      isDataCollapsed: true,
      isAccountCollapsed: true,
      chartFunc: this.kpiChartDataComponentCfg.chart1,
      dataEndpoint: this.appDataFetchSvc.fetchKPIAvgAccValDataObjTable(),
      accEndpoint: this.appDataFetchSvc.fetchKPIAvgAccValDataAccObjTable(),
      dataSmartTableCfg: this.kpiChartDataComponentCfg.totalAccValDataObjTableCfg,
      accSmartTableCfg: this.kpiChartDataComponentCfg.totalAccValAccountObjTableCfg,
      kpiType: 1
    },
    {
      kpiName: this.kpiIdLabel.AVG_MONTHLY_LOGIN.id,
      kpiLabel: this.kpiIdLabel.AVG_MONTHLY_LOGIN.label,
      isChartCollapsed: false,
      isDataCollapsed: true,
      isAccountCollapsed: true,
      chartFunc: this.kpiChartDataComponentCfg.chart1,
      dataEndpoint: this.appDataFetchSvc.fetchKPIAvgAccValDataObjTable(),
      accEndpoint: this.appDataFetchSvc.fetchKPIAvgAccValDataAccObjTable(),
      dataSmartTableCfg: this.kpiChartDataComponentCfg.avgMonthlyLoginDataObjTableCfg,
      accSmartTableCfg: this.kpiChartDataComponentCfg.avgMonthlyLoginAccountObjTableCfg,
      kpiType: 1
    },
    {
      kpiName: this.kpiIdLabel.ACC_RENEWAL_RATE_VAL.id,
      kpiLabel: this.kpiIdLabel.ACC_RENEWAL_RATE_VAL.label,
      isChartCollapsed: false,
      isDataCollapsed: true,
      isAccountCollapsed: true,
      chartFunc: this.kpiChartDataComponentCfg.chart2,
      dataEndpoint: this.appDataFetchSvc.fetchKPIRenewalRateDataObjTable(),
      accEndpoint: this.appDataFetchSvc.fetchKPIRenewalRateAccObjTable(),
      dataSmartTableCfg: this.kpiChartDataComponentCfg.accRenewalRateDataObjTableCfg,
      accSmartTableCfg: this.kpiChartDataComponentCfg.accRenewalRateAccountObjTableCfg,
      kpiType: 2
    },
    {
      kpiName: this.kpiIdLabel.ACC_RENEWAL_RATE_VOL.id,
      kpiLabel: this.kpiIdLabel.ACC_RENEWAL_RATE_VOL.label,
      isChartCollapsed: false,
      isDataCollapsed: true,
      isAccountCollapsed: true,
      chartFunc: this.kpiChartDataComponentCfg.chart2,
      dataEndpoint: this.appDataFetchSvc.fetchKPIRenewalRateDataObjTable(),
      accEndpoint: this.appDataFetchSvc.fetchKPIRenewalRateAccObjTable(),
      dataSmartTableCfg: this.kpiChartDataComponentCfg.accRenewalRateVolDataObjTableCfg,
      accSmartTableCfg: this.kpiChartDataComponentCfg.accRenewalRateVolAccountObjTableCfg,
      kpiType: 2
    },
    {
      kpiName: this.kpiIdLabel.CON_RATE_VAL.id,
      kpiLabel: this.kpiIdLabel.CON_RATE_VAL.label,
      isChartCollapsed: false,
      isDataCollapsed: true,
      isAccountCollapsed: true,
      chartFunc: this.kpiChartDataComponentCfg.chart2,
      dataEndpoint: this.appDataFetchSvc.fetchKPIRenewalRateDataObjTable(),
      accEndpoint: this.appDataFetchSvc.fetchKPIRenewalRateAccObjTable(),
      dataSmartTableCfg: this.kpiChartDataComponentCfg.convRateValDataObjTableCfg,
      accSmartTableCfg: this.kpiChartDataComponentCfg.convRateValAccountObjTableCfg,
      kpiType: 2
    },
    {
      kpiName: this.kpiIdLabel.CON_RATE_VOL.id,
      kpiLabel: this.kpiIdLabel.CON_RATE_VOL.label,
      isChartCollapsed: false,
      isDataCollapsed: true,
      isAccountCollapsed: true,
      chartFunc: this.kpiChartDataComponentCfg.chart2,
      dataEndpoint: this.appDataFetchSvc.fetchKPIRenewalRateDataObjTable(),
      accEndpoint: this.appDataFetchSvc.fetchKPIRenewalRateAccObjTable(),
      dataSmartTableCfg: this.kpiChartDataComponentCfg.convRateVolDataObjTableCfg,
      accSmartTableCfg: this.kpiChartDataComponentCfg.convRateVolAccountObjTableCfg,
      kpiType: 2
    },

  ];

  pageOpt = {
    dataKey: 'result',
    pagerPageKey: 'currentPageNumber',
    pagerLimitKey: 'totalItemsCountPerPage',
    totalKey: 'totalItemsCount'
  };

  ngOnInit(): void {
    let defaultFirmCar;
    let defaultPctile;
    this.route.queryParams.subscribe(params => {
      defaultFirmCar = params.firmCat;
      defaultPctile = params.pctile;

      this.type1ChartModalSavedStates = this.kpiChartDataComponentCfg.type1ChartModalSavedDefaultStates;
      this.type1ChartModalSavedStates.firmCategory = [defaultFirmCar];
      this.type1ChartModalSavedStates.salesPercentileBucket = [defaultPctile];
      this.currentKpiName = this.route.snapshot.paramMap.get('kpiName');
      this.fetchKPIChartsAndTableData();
    });
  }

  fetchKPIChartsAndTableData(): void {
    const options = _.find(this.specs, {kpiName: this.currentKpiName});
    if (options.kpiType === 2) {
      this.appDataFetchSvc.fetchKPIRenewalRateChart({
        kpiName: this.currentKpiName,
        chartModalFilter: this.type1ChartModalSavedStates
      }).subscribe(
        (data: any) => {
          this.refreshChartAndTables(data);
        }
      );
    } else {
      this.appDataFetchSvc.fetchKPIAvgAccValChart({
        kpiName: this.currentKpiName,
        chartModalFilter: this.type1ChartModalSavedStates
      }).subscribe(
        (data: any) => {
          this.refreshChartAndTables(data);
        }
      );
    }

  }

  private refreshChartAndTables(chartData: any): void {
    const options = _.find(this.specs, {kpiName: this.currentKpiName});
    if (options) {
      this.chartObj = options.chartFunc(this, chartData, this.currentKpiName);
      this.dataObj = new CustomServerDataSource(this.http,
        {
          kpiName: this.currentKpiName,
          chartModalFilter: this.type1ChartModalSavedStates
        },
        _.extend({endPoint: options.dataEndpoint}, this.pageOpt));
      this.accObj = new CustomServerDataSource(this.http,
        {
          kpiName: this.currentKpiName,
          chartModalFilter: this.type1ChartModalSavedStates
        },
        _.extend({endPoint: options.accEndpoint}, this.pageOpt));
    }
  }

  togglePane(paneType: string, chartData: any): void {
    switch (paneType) {
      case 'chart':
        chartData.isChartCollapsed = !chartData.isChartCollapsed;
        break;
      case 'data':
        chartData.isDataCollapsed = !chartData.isDataCollapsed;
        break;
      case 'account':
        chartData.isAccountCollapsed = !chartData.isAccountCollapsed;
        break;
    }
  }

  openKpiChartsSettingModal(): void {
    const modalRef = this.modalService.open(KpiChartsSettingModalComponent, {size: 'xl'});
    modalRef.componentInstance.savedSettings = this.type1ChartModalSavedStates;
    modalRef.componentInstance.kpiSpec = {kpiName: this.currentKpiName};
    modalRef.result.then((result) => {
      if (result) {
        this.type1ChartModalSavedStates = result;
        this.fetchKPIChartsAndTableData();
      }
    }, (reason) => {
    });
  }


}

