<div class="row">
  <div class="col-md-12">
    <div class="panel panel-default">
      <div class="panel-heading"></div>
      <div class="panel-body">
        <div class="row">
          <div class="col-md-3">
            <div ngbDropdown class="d-inline-block" [autoClose]="true">
              <button type="button" class="btn btn-light mt-3 mb-2" id="qsSearchFieldDD" ngbDropdownToggle>
                ADD SEARCH FIELD
              </button>
              <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="qsSearchFieldDD" role="menu">
                <li *ngFor="let filter of itemNotInSelectedList(availableFilters, appliedFilters, 'col_id')"
                    [ngbTooltip]="filter.col_tp"
                    [disableTooltip]="fieldDefinitionsToolTipEnabled">
                  <a ngbDropdownItem
                     (click)="addFilterToAppliedFilters(filter);  setFilterStateChanged(); $event.stopPropagation();">
                    {{filter.col_name}}
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <ul class="list-group">
                <li class="list-group-item"
                    [ngbTooltip]="col.col_tp"
                    [disableTooltip]="fieldDefinitionsToolTipEnabled"
                    style="padding: 6px 6px 6px 6px;"
                    *ngFor="let col of appliedFilters; index as i"
                    (click)="setActiveFilterID(col.col_id);setFilterStateChanged(); $event.stopPropagation();"
                    [ngClass]="{
            'active': col.col_id === activeFilterID,
            'touched': appliedFiltersDM[col.col_id] && appliedFiltersDM[col.col_id]['isTouched'] && col.col_id !== activeFilterID}"
                >
                  {{ col.col_name }}
                  <span class="float-right">
                <fa-icon [icon]="faTrash" class=" cursor-pointer"
                         (click)="removeFilterFromAppliedFilters(i);setFilterStateChanged();$event.stopPropagation(); ">
                </fa-icon>
          </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-9">
            <div *ngIf="activeFilterDM" class="mt-2">
              <div *ngIf="activeFilterDM.type === 'numeric'" class="graph-container">
                <div id="c3-chart-placeholder">
                  <app-dist-chart [data]="activeFilterDM.chartData"
                                  [rangeMin]="activeFilterDM.histMin"
                                  [rangeMax]="activeFilterDM.histMax"
                                  [filterFloor]="activeFilterDM.dataFloor"
                                  [filterCeil]="activeFilterDM.dataCeil"
                                  [filterName]="activeFilterDM.name">

                  </app-dist-chart>
                </div>
                <div style="width: 600px;font-size: 12px;">
                  <p class="text-center" style="margin-bottom: 0px;">
                    {{activeFilterDM.name}}
                  </p>
                </div>
                <ngx-slider
                  [(value)]="activeFilterDM.min"
                  [(highValue)]="activeFilterDM.max"
                  [options]="activeFilterDM.sliderOpts"
                  (userChangeEnd)="activeFilterDM.sliderOpts.userChangeEnd($event)"
                  class="screener-slider"
                ></ngx-slider>

                <div style="margin-top: 4px; height: 100%;" class="rzslider" *ngIf="activeFilterDM">
                  <input type="text" class="form-control d-inline" placeholder="min" style="width: 25%;"
                         [(ngModel)]="activeFilterDM.localMin" (ngModelChange)="activeFilterDM.updChartData()">
                  <input type="text" class="form-control d-inline" placeholder="max" style="width: 25%; float: right;"
                         [(ngModel)]="activeFilterDM.localMax" (ngModelChange)="activeFilterDM.updChartData()">
                </div>
              </div>
              <div *ngIf="activeFilterDM.type === 'string'" class="dashboard-filter-condition">
                <div class="row">
                  <div class="col-md-12">
                    <b>Filter Condition:</b>
                    <div class="form-inline">
                      <div class="">
                        <div class="btn-group" ngbDropdown [autoClose]="true">
                          <button class="btn btn-default dropdown-toggle btn-primary" type="button" ngbDropdownToggle>
                            {{ activeFilterDM.currentCondition.filter_display ? activeFilterDM.currentCondition.filter_display : 'Select Condition' }}

                          </button>
                          <ul class="dropdown-menu" ngbDropdownMenu role="menu">
                            <li *ngFor="let condition of activeFilterDM.allConditions" role="menuitem" ngbDropdownItem>
                              <a (click)="updateFilterCondition(activeFilterDM, condition);
                                  setFilterStateChanged();">{{ condition.filter_display }}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <ng-container [ngSwitch]="!!staticFilterOptions[activeFilterDM.col_id]">
                        <label>
                          <input *ngSwitchCase="false" type="text" [(ngModel)]="activeFilterDM.searchStr"
                                 class="form-control" placeholder="Enter text to filter"
                                 (ngModelChange)="applyStringFilter(activeFilterDM.searchStr); setFilterStateChanged();"
                                 [disabled]="activeFilterDM.currentCondition.filter_value === null">
                        </label>
                        <label>
                          <select *ngSwitchCase="true" [(ngModel)]="activeFilterDM.searchStr" class="form-control"
                                  (ngModelChange)="applyStringFilter(activeFilterDM.searchStr); setFilterStateChanged();"
                                  [disabled]="activeFilterDM.currentCondition.filter_value === null">
                            <option value="ALL" selected>All</option>
                            <option *ngFor="let opt of staticFilterOptions[activeFilterDM.col_id].buckets"
                                    [value]="opt.key">{{ opt.key }}</option>
                          </select>
                        </label>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="activeFilterDM.type === 'numericBounds'" class="dashboard-filter-condition">
                <div class="row">
                  <div class="col-md-12">
                    <b>Filter Condition:</b>
                    <div class="form-inline">
                      <div class="">
                        <div class="btn-group" ngbDropdown [autoClose]="true">
                          <button class="btn btn-default dropdown-toggle btn-primary"
                                  type="button" ngbDropdownToggle>
                            {{  activeFilterDM.currentCondition && activeFilterDM.currentCondition.filter_display ?
                            activeFilterDM.currentCondition.filter_display :
                            'Select Condition' }}
                          </button>
                          <ul class="dropdown-menu" ngbDropdownMenu role="menu">
                            <li ngbDropdownItem *ngFor="let condition of activeFilterDM.allConditions"
                                role="menuitem">
                              <a
                                (click)="updateFilterCondition(activeFilterDM, condition); setFilterStateChanged();">
                                {{ condition.filter_display }}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <input
                        *ngIf="activeFilterDM && activeFilterDM.currentCondition && activeFilterDM.currentCondition.filter_value !== 'BETWEEN'"
                        type="text"
                        [(ngModel)]="activeFilterDM.searchVal"
                        class="form-control"
                        placeholder="Enter value"
                        (ngModelChange)="applyNumericBoundFilter(activeFilterDM.searchVal, undefined, undefined); setFilterStateChanged();"
                        [disabled]="activeFilterDM.currentCondition.filter_value == null">
                    </div>
                  </div>
                </div>
                <div class="row top-buffer"
                     *ngIf="activeFilterDM && activeFilterDM.currentCondition && activeFilterDM.currentCondition.filter_value === 'BETWEEN'">
                  <div class="col-md-6">
                    <input type="text"
                           [(ngModel)]="activeFilterDM.searchBounds.lb"
                           class="form-control"
                           placeholder="From Value"
                           (ngModelChange)="applyNumericBoundFilter(undefined, activeFilterDM.searchBounds.lb, activeFilterDM.searchBounds.ub); setFilterStateChanged();"
                           [disabled]="activeFilterDM.currentCondition.filter_value == null">
                  </div>
                  <div class="col-md-6">
                    <input type="text"
                           [(ngModel)]="activeFilterDM.searchBounds.ub"
                           class="form-control"
                           placeholder="To value"
                           (ngModelChange)="applyNumericBoundFilter(undefined, activeFilterDM.searchBounds.lb, activeFilterDM.searchBounds.ub); setFilterStateChanged();"
                           [disabled]="activeFilterDM.currentCondition.filter_value == null">
                  </div>
                </div>
              </div>

              <div *ngIf="activeFilterDM.type === 'datestring'" class="dashboard-filter-condition">
                <div class="row">
                  <div class="col-md-12">
                    <b>Filter Condition:</b>
                    <div class="form-inline">
                      <div class="">
                        <div class="btn-group" ngbDropdown [autoClose]="true">
                          <button class="btn btn-default dropdown-toggle btn-primary" type="button"
                                  ngbDropdownToggle>
                            {{activeFilterDM && activeFilterDM.currentCondition && activeFilterDM.currentCondition.filter_display ? activeFilterDM.currentCondition.filter_display : 'Select Condition' }}

                          </button>
                          <ul class="dropdown-menu" ngbDropdownMenu role="menu">
                            <li ngbDropdownItem *ngFor="let condition of activeFilterDM.allConditions" role="menuitem">
                              <a
                                (click)="updateFilterConditionAndCalculateFilterDateRange(activeFilterDM, condition); setFilterStateChanged();">{{ condition.filter_display }}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div *ngIf=" activeFilterDM && activeFilterDM.currentCondition
                && activeFilterDM.currentCondition.filter_value === 'BETWEEN'" class="form-group mr-2">
                                  <span class="btn-group">
                                    <span class="form-inline">
                                      <app-ds-date [dateVal]="activeFilterDM.filterBounds.lb"
                                                   (dateValChange)="applyCustomDateFilter(null, activeFilterDM.filterBounds.lb, activeFilterDM.filterBounds.ub); setFilterStateChanged();"
                                                   (onDateUpdate)="setFilterStateChanged()"
                                                   [isDisabled]="activeFilterDM.currentCondition.filter_value === null">
                                      </app-ds-date>
                                    </span>
                                    <label class="control-label">and</label>
                                    <span class="form-inline">
                                      <app-ds-date [dateVal]="activeFilterDM.filterBounds.ub"
                                                   (dateValChange)="applyCustomDateFilter(null, activeFilterDM.filterBounds.lb, activeFilterDM.filterBounds.ub); setFilterStateChanged();"
                                                   (onDateUpdate)="setFilterStateChanged()"
                                                   [isDisabled]="activeFilterDM.currentCondition.filter_value === null">
                                      </app-ds-date>
                                    </span>
                                  </span>
                      </div>
                      <div *ngIf=" activeFilterDM && activeFilterDM.currentCondition &&
              activeFilterDM.currentCondition.filter_value !== 'BETWEEN'" class="form-group mr-2">
                                    <span *ngIf="activeFilterDM.currentCondition.auto_calc" class="">
                                      <input type="text" [(ngModel)]="activeFilterDM.searchStr" class="form-control"
                                             disabled>
                                    </span>
                        <span *ngIf="!activeFilterDM.currentCondition.auto_calc" class="form-inline">
                                      <app-ds-date [dateVal]="activeFilterDM.searchStr"
                                                   (dateValChange)="applyCustomDateFilter(activeFilterDM.searchStr, null, null); setFilterStateChanged();"
                                                   (onDateUpdate)="setFilterStateChanged()"
                                                   [isDisabled]="activeFilterDM.currentCondition.filter_value === null">
                                        </app-ds-date>
                                    </span>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
