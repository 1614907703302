import {Component, Input, OnInit} from '@angular/core';
import {AppDataFetchService} from "../../services/app-data-fetch.service";
import {FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import * as _ from 'lodash';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-kpi-charts-setting-modal',
  templateUrl: './kpi-charts-setting-modal.component.html',
  styleUrls: ['./kpi-charts-setting-modal.component.less']
})
export class KpiChartsSettingModalComponent implements OnInit {
  @Input() savedSettings: any;
  @Input() kpiSpec: any;
  lp: any;
  fg: FormGroup;
  isShowMoreCollapsed = true;

  constructor(private appDataFetchSvc: AppDataFetchService, private formBuilder: FormBuilder, public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.fetchKPIChartsSettingLp().then((lp) => {
      this.lp = lp;
      this.buildForm();
    });
  }

  fetchKPIChartsSettingLp(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.appDataFetchSvc.fetchKPIChartsSettingsLp({kpiName: this.kpiSpec.kpiName}).subscribe(
        (data: any[]) => {
          resolve(data);
        }, error => {
          reject(error);
        });
    });
  }

  buildForm(): void {
    let o = {};
    if (['avg_acc_val'].indexOf(this.kpiSpec.kpiName) > -1) {
      o = {
        firmType: this.createCheckboxes(this.lp.firmType),
        salesPercentileBucket: this.createCheckboxes(this.lp.salesPercentileBucket),
        productGroup: this.createCheckboxes(this.lp.productGroup),
        portal: this.createCheckboxes(this.lp.portal),
        period: this.createCheckboxes(this.lp.period),
        firmCategory: this.createCheckboxes(this.lp.firmCategory),
        timeline: new FormControl(),
      };
    } else if (['average_monthly_login'].indexOf(this.kpiSpec.kpiName) > -1) {
      o = {
        firmType: this.createCheckboxes(this.lp.firmType),
        salesPercentileBucket: this.createCheckboxes(this.lp.salesPercentileBucket),
        portal: this.createCheckboxes(this.lp.portal),
        period: this.createCheckboxes(this.lp.period),
        firmCategory: this.createCheckboxes(this.lp.firmCategory),
        timeline: new FormControl()
      };

    } else if (['total_acc_val'].indexOf(this.kpiSpec.kpiName) > -1) {
      o = {
        firmType: this.createCheckboxes(this.lp.firmType),
        salesPercentileBucket: this.createCheckboxes(this.lp.salesPercentileBucket),
        productGroup: this.createCheckboxes(this.lp.productGroup),
        period: this.createCheckboxes(this.lp.period),
        firmCategory: this.createCheckboxes(this.lp.firmCategory),
        timeline: new FormControl()
      };
    } else {
      o = {
        firmType: this.createCheckboxes(this.lp.firmType),
        salesPercentileBucket: this.createCheckboxes(this.lp.salesPercentileBucket),
        period: this.createCheckboxes(this.lp.period),
        firmCategory: this.createCheckboxes(this.lp.firmCategory),
        timeline: new FormControl()
      };
    }
    this.fg = this.formBuilder.group(o);
    this.updateSelectionWithSaved();

  }

  isGroupPresent(gn: string): any {
    return Object.keys(this.fg.value).includes(gn);
  }

  getGroupName(): any {
    return Object.keys(this.fg.value);
  }

  createCheckboxes(checkboxesData): FormArray {
    const checkboxesArray = checkboxesData.map(() => this.formBuilder.control(false));
    return this.formBuilder.array(checkboxesArray);
  }

  getFormArray(groupName: string): FormArray {
    return this.fg.get(groupName) as FormArray;
  }

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  onCheckboxChange(event: any, groupName: string, index: number): void {
    const indexOfCheckAllCb = _.findIndex(this.lp[groupName], {value: '*'});
    const indexOfDefaultNoneCb = _.findIndex(this.lp[groupName], {value: 'NONE'});

    const indexOfCheckAllCbCheckBoxClickedNow = (index === indexOfCheckAllCb);
    // Check All Case Handling
    if (indexOfCheckAllCb > -1 && indexOfCheckAllCbCheckBoxClickedNow) {
      const formArray = this.getFormArray(groupName);
      const isCheckedAllCbChecked = formArray.controls[indexOfCheckAllCb].value === true;
      if (isCheckedAllCbChecked) {
        // "CheckAllCb" checkbox is checked
        formArray.controls.forEach((control, idx) => {
          if (idx !== indexOfCheckAllCb) {
            control.setValue(true); // Check other checkboxes
            control.disable(); // Disable other checkboxes
          }
        });
      } else if (!isCheckedAllCbChecked) {
        // Uncheck "CheckAllCb" checkbox if any other checkbox is checked
        formArray.controls[indexOfCheckAllCb].setValue(false);
        formArray.controls.forEach((control, idx) => {
          if (idx !== indexOfCheckAllCb) {
            control.setValue(false); // If you want to update everything to false on "CheckAllCb" unchecked..
            control.enable(); // Enable other checkboxes
          }
        });
      }
    }
  }

  onSubmit(): void {
    const selectedCheckboxes = {};

    for (const groupName of Object.keys(this.fg.controls)) {
      if (groupName === 'timeline') {
        selectedCheckboxes[groupName] = [this.fg.controls.timeline.value];
      } else {
        const group = this.fg.get(groupName) as FormArray;
        const checkboxValues = [];

        group.controls.forEach((control, index) => {
          const isChecked = control.value;
          const isDisabled = control.disabled;

          if ((isChecked && !isDisabled) || (isChecked && isDisabled)) {

            checkboxValues.push(this.lp[groupName][index].value);

          }
        });

        selectedCheckboxes[groupName] = checkboxValues;
      }
    }
    this.activeModal.close(selectedCheckboxes);
  }

  updateSelectionWithSaved(): void {
    const savedCheckboxes = this.savedSettings;
    const currGroupName = this.getGroupName();
    if (savedCheckboxes) {
      const parsedCheckboxes = savedCheckboxes;

      for (const groupName of Object.keys(parsedCheckboxes)) {
        if (currGroupName.indexOf(groupName) > -1) {
          if (groupName === 'timeline') {
            this.fg.get(groupName).setValue(parsedCheckboxes[groupName][0]);
          } else {
            const savedValues = parsedCheckboxes[groupName];
            const indexOfCheckAllCb = savedValues.indexOf('*');
            const formArray = this.fg.get(groupName) as FormArray;

            formArray.controls.forEach((control, index) => {
              const checkboxValue = this.lp[groupName][index].value;

              if (savedValues.includes(checkboxValue)) {
                control.setValue(true);
                if (indexOfCheckAllCb > -1) {
                  if (!(indexOfCheckAllCb === index)) {
                    control.disable();
                  }
                }
              }
            });
          }
        }
      }
    } else {

      // No saved checkboxes found, handle initial values or defaults
      // ...
    }
  }

}
