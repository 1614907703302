import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dlg-confirm-delete',
  templateUrl: './dlg-confirm-delete.html'
})


export class DlgConfirmDeleteComponent implements OnInit {
  @Input() bodyText: string;
  @Input() inputConfirmText: string;
  @Input() skipTextBoxInputQuiz: boolean;
  @Input() errorMsgHtml: string;
  @Output() proceedStatus: EventEmitter<any> = new EventEmitter();

  fg!: FormGroup;

  constructor(
    private fb: FormBuilder, public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.fg = this.fb.group({
      confirmTextBox: new FormControl('', [Validators.required, Validators.minLength(1)])
    });
  }

  onSubmit(): void {
    this.errorMsgHtml = null;
    if (this.skipTextBoxInputQuiz === true) {
      this.proceedStatus.emit();
      return;
    }
    if (this.fg.controls.confirmTextBox.status !== 'INVALID'
      && this.fg.controls.confirmTextBox.value === this.inputConfirmText) {
      this.proceedStatus.emit();
    } else {
      console.warn('mismatch answer in delete confirmation box: ', this.fg.controls.confirmTextBox.value);
      this.errorMsgHtml = `That did not match, Type "${this.inputConfirmText}" in the text box`;
    }
  }
}
