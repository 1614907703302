import {Directive, EventEmitter, Output, HostListener} from '@angular/core';

@Directive({
  selector: '[appScrollProgress]'
})
export class ScollProgressDirective {
  @Output() scrollEvent = new EventEmitter<string>();

  constructor() {
  }

  @HostListener('window:scroll', ['$event'])
  doSomething(event) {

    console.log( `Here is ${window.innerHeight} &  ${window.scrollY}`);
  }
}
