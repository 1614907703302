import {Component, Input, OnInit} from '@angular/core';
import * as uuid from 'uuid';
import {NgbActiveModal, NgbDateParserFormatter, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {faCalendar, faEdit, faRedo} from '@fortawesome/free-solid-svg-icons';
import {FormFormatterService} from "../../../../services/form-formatter.service";
import {SuperusersDataFetchService} from "../../superusers-data-fetch.service";

@Component({
  selector: 'app-edit-user-access',
  templateUrl: './edit-user-access.component.html',
  styleUrls: ['./edit-user-access.component.less']
})
export class EditUserAccessComponent implements OnInit {

  @Input() currentUserSub: string;
  fg!: FormGroup;
  lpAdminLevel = [
    {title: 'Super User', value: 1},
    {title: 'Admin', value: 2},
    {title: 'Company', value: 3}
  ];
  lpApiKeyStatusId =  [
    {value: 1, title: "Active"},
    {value: 0, title: "Pending"},
    {value: -1, title: "Revoked"}
  ];
  errorMsgHtml: any;
  faCalendar = faCalendar;
  faEdit = faEdit;
  faRefresh = faRedo;

  constructor(private fb: FormBuilder,
              public activeModal: NgbActiveModal,
              private modalService: NgbModal,
              private formatter: NgbDateParserFormatter,
              private formFormatter: FormFormatterService,
              private appDataFetchService: SuperusersDataFetchService) {
  }

  ngOnInit(): void {
    this.buildForm({});
    this.fetchUserAccessDetails();
  }

  private buildForm(details: any): void {
    this.fg = this.fb.group({
      sub: new FormControl({
        value: details.sub,
        disabled: this.currentUserSub
      }),
      email: new FormControl(details.email, [Validators.required]),
      adminLevel: new FormControl(details.adminLevel, [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      statusId: new FormControl(details.statusId, [Validators.required]),
      createdDatetime: new FormControl(this.formatter.parse(details.createdDatetime)),
      comment: new FormControl(details.comment),
      password: new FormControl('',),
      confirmPassword: new FormControl('',),
    }, {
      validator: this.formFormatter.MustMatch('password', 'confirmPassword')
    });
  }

  private fetchUserAccessDetails(): void {
    if (this.currentUserSub) {
      this.appDataFetchService.fetchUserAccessDetails(null, this.currentUserSub).subscribe(data => {
        if (data) {
          this.buildForm(data);
        } else {
          this.errorMsgHtml = 'An unknown error has occurred';
        }
      }, error => {
        this.errorMsgHtml = JSON.stringify(error);
      });
    }
  }

  onSubmitUpdateUserAccess(): void {
    const o = this.formFormatter.format(this.fg);
    if (o && o.errorMsgHtml) {
      this.errorMsgHtml = o.errorMsgHtml;
    } else {
      console.log(o.formatted);
      this.appDataFetchService.updateUserAccessDetails(null, o.formatted).subscribe(data => {
        if (data) {
          this.activeModal.close(data);
        } else {
          this.errorMsgHtml = 'An unknown error has occurred';
        }
      }, error => {
        this.errorMsgHtml = JSON.stringify(error);
      });
    }
  }

}
