import {Component, Injectable, OnInit} from '@angular/core';
import {AppDataFetchService} from "../../services/app-data-fetch.service";
import {faCalendar, faFilePdf, faFileExcel} from '@fortawesome/free-solid-svg-icons';
import {NgbCalendar, NgbDateAdapter, NgbDateParserFormatter} from "@ng-bootstrap/ng-bootstrap";
import {CustomAdapter, CustomDateParserFormatter} from "../../services/custom-date-picker.service";
import {catchError, map} from "rxjs/operators";
import {Observable, of} from "rxjs";

@Component({
  selector: 'app-kpi-account-review',
  templateUrl: './kpi-account-review.component.html',
  styleUrls: ['./kpi-account-review.component.less'],
  providers: [
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
  ],
})
export class KpiAccountReviewComponent implements OnInit {
  lpAccountNameItems;
  faCalendar = faCalendar;
  faPDF = faFilePdf;
  faFileExcel = faFileExcel;

  selectedAccountName;
  selectedEndDate;
  tableData;
  tableSections = [
    {
      title: 'Sales Recap',
      sectionKey: 'sales_rec_od',
      groupTitle: 'Sales',
      groupSubTitle: 'Subscriptions & one-off products aggregated based on Order Date',
      columns: [
        {label: 'Product Type', align: 'left', type: 'string', field: 'productType'},
        {label: 'Total Sales', align: 'right', type: 'number', field: 'totalSales', formatter: '$'},
        {label: 'Growth', align: 'right', type: 'number', field: 'Growth', formatter: '%'},
        {label: 'Delta Sales', align: 'right', type: 'number', field: 'deltaSales', formatter: '$'},
        {label: 'Period End', align: 'left', type: 'date', field: 'periodEnd'}
      ]
    },
    {
      title: 'Product Recap',
      sectionKey: 'prod_rec_od',
      columns: [
        {label: 'Product Type', align: 'left', type: 'string', field: 'productType'},
        {label: 'Total Sales', align: 'right', type: 'number', field: 'totalSales', formatter: '$'},
        {label: 'Growth', align: 'right', type: 'number', field: 'Growth', formatter: '%'},
        {label: 'Delta Sales', align: 'right', type: 'number', field: 'deltaSales', formatter: '$'},
        {label: 'Licenses', align: 'right', type: 'number', field: 'Licenses'},
        {label: 'Delta Licenses', align: 'right', type: 'number', field: 'deltaLicenses'},
        {label: 'Period End', align: 'left', type: 'date', field: 'periodEnd'}
      ],
      children: [
        {
          title: 'New Product in Portfolio',
          sectionKey: 'new_pf_od',
          columns: [
            {label: 'Product Type', align: 'left', type: 'string', field: 'productType'},
            {label: 'Total Sales', align: 'right', type: 'number', field: 'totalSales', formatter: '$'},
            {label: 'Growth', align: 'right', type: 'number', field: 'Growth', formatter: '%'},
            {label: 'Delta Sales', align: 'right', type: 'number', field: 'deltaSales', formatter: '$'},
            {label: 'Licenses', align: 'right', type: 'number', field: 'Licenses'},
            {label: 'Delta Licenses', align: 'right', type: 'number', field: 'deltaLicenses'},
            {label: 'Period End', align: 'left', type: 'date', field: 'periodEnd'}
          ]
        },
        {
          title: 'Products No Longer in Portfolio',
          sectionKey: 'nl_prod_od',
          columns: [
            {label: 'Product Type', align: 'left', type: 'string', field: 'productType'},
            {label: 'Total Sales', align: 'right', type: 'number', field: 'totalSales', formatter: '$'},
            {label: 'Growth', align: 'right', type: 'number', field: 'Growth', formatter: '%'},
            {label: 'Delta Sales', align: 'right', type: 'number', field: 'deltaSales', formatter: '$'},
            {label: 'Licenses', align: 'right', type: 'number', field: 'Licenses'},
            {label: 'Delta Licenses', align: 'right', type: 'number', field: 'deltaLicenses'},
            {label: 'Period End', align: 'left', type: 'date', field: 'periodEnd'}
          ]
        },
        {
          title: 'Products with Sales Increase',
          sectionKey: 'prod_sales_inc_od',
          columns: [
            {label: 'Product Type', align: 'left', type: 'string', field: 'productType'},
            {label: 'Total Sales', align: 'right', type: 'number', field: 'totalSales', formatter: '$'},
            {label: 'Growth', align: 'right', type: 'number', field: 'Growth', formatter: '%'},
            {label: 'Delta Sales', align: 'right', type: 'number', field: 'deltaSales', formatter: '$'},
            {label: 'Licenses', align: 'right', type: 'number', field: 'Licenses'},
            {label: 'Delta Licenses', align: 'right', type: 'number', field: 'deltaLicenses'},
            {label: 'Period End', align: 'left', type: 'date', field: 'periodEnd'}
          ]
        },
        {
          title: 'Products with Stable Sales',
          sectionKey: 'prod_stable_sales_od',
          columns: [
            {label: 'Product Type', align: 'left', type: 'string', field: 'productType'},
            {label: 'Total Sales', align: 'right', type: 'number', field: 'totalSales', formatter: '$'},
            {label: 'Growth', align: 'right', type: 'number', field: 'Growth', formatter: '%'},
            {label: 'Delta Sales', align: 'right', type: 'number', field: 'deltaSales', formatter: '$'},
            {label: 'Licenses', align: 'right', type: 'number', field: 'Licenses'},
            {label: 'Delta Licenses', align: 'right', type: 'number', field: 'deltaLicenses'},
            {label: 'Period End', align: 'left', type: 'date', field: 'periodEnd'}
          ]
        },
        {
          title: 'Products with Sales Decrease',
          sectionKey: 'prod_sales_dec_od',
          columns: [
            {label: 'Product Type', align: 'left', type: 'string', field: 'productType'},
            {label: 'Total Sales', align: 'right', type: 'number', field: 'totalSales', formatter: '$'},
            {label: 'Growth', align: 'right', type: 'number', field: 'Growth', formatter: '%'},
            {label: 'Delta Sales', align: 'right', type: 'number', field: 'deltaSales', formatter: '$'},
            {label: 'Licenses', align: 'right', type: 'number', field: 'Licenses'},
            {label: 'Delta Licenses', align: 'right', type: 'number', field: 'deltaLicenses'},
            {label: 'Period End', align: 'left', type: 'date', field: 'periodEnd'}
          ]
        }
      ]
    },
    {
      title: 'Subscription Audit LTM',
      sectionKey: 'sub_aud_ltm_od',
      columns: [
        {label: 'mpSub', align: 'left', type: 'string', field: 'mpSub'},
        {label: 'full Name', align: 'left', type: 'string', field: 'full Name'},
        {label: 'Product Type', align: 'left', type: 'string', field: 'Product Type'},
        {label: 'Product', align: 'left', type: 'string', field: 'Product'},
        {label: 'Order Date', align: 'left', type: 'date', field: 'Order Date'},
        {label: '1st Issue', align: 'left', type: 'date', field: '1st Issue'},
        {label: 'Expire Issue', align: 'left', type: 'date', field: 'Expire Issue'},
        {label: 'Amount', align: 'right', type: 'number', field: 'Amount', formatter: '$'},
        {label: 'Period', align: 'left', type: 'string', field: 'period'},
        {label: 'productType', align: 'left', type: 'string', field: 'productType'},
        {label: 'openItemType', align: 'left', type: 'string', field: 'openItemType'},
        {label: 'Type Check', align: 'left', type: 'string', field: 'Type Check'}
      ]
    },
    {
      title: 'Subscription Audit LTM-1',
      sectionKey: 'sub_aud_ltm1_od',
      columns: [
        {label: 'mpSub', align: 'left', type: 'string', field: 'mpSub'},
        {label: 'full Name', align: 'left', type: 'string', field: 'full Name'},
        {label: 'Product Type', align: 'left', type: 'string', field: 'Product Type'},
        {label: 'Product', align: 'left', type: 'string', field: 'Product'},
        {label: 'Order Date', align: 'left', type: 'date', field: 'Order Date'},
        {label: '1st Issue', align: 'left', type: 'date', field: '1st Issue'},
        {label: 'Expire Issue', align: 'left', type: 'date', field: 'Expire Issue'},
        {label: 'Amount', align: 'right', type: 'number', field: 'Amount', formatter: '$'},
        {label: 'Period', align: 'left', type: 'string', field: 'period'},
        {label: 'productType', align: 'left', type: 'string', field: 'productType'},
        {label: 'openItemType', align: 'left', type: 'string', field: 'openItemType'},
        {label: 'Type Check', align: 'left', type: 'string', field: 'Type Check'}
      ]
    },
    {
      title: 'Subscription Line items excluded from the review',
      subTitle: 'openItemType: X = cancel, TRAN = Transfer',
      sectionKey: 'sub_ltm_excluded_od',
      columns: [
        {label: 'mpSub', align: 'left', type: 'string', field: 'mpSub'},
        {label: 'full Name', align: 'left', type: 'string', field: 'full Name'},
        {label: 'Product Type', align: 'left', type: 'string', field: 'Product Type'},
        {label: 'Product', align: 'left', type: 'string', field: 'Product'},
        {label: 'Order Date', align: 'left', type: 'date', field: 'Order Date'},
        {label: '1st Issue', align: 'left', type: 'date', field: '1st Issue'},
        {label: 'Expire Issue', align: 'left', type: 'date', field: 'Expire Issue'},
        {label: 'Amount', align: 'right', type: 'number', field: 'Amount', formatter: '$'},
        {label: 'Period', align: 'left', type: 'string', field: 'period'},
        {label: 'productType', align: 'left', type: 'string', field: 'productType'},
        {label: 'openItemType', align: 'left', type: 'string', field: 'openItemType'},
        {label: 'Type Check', align: 'left', type: 'string', field: 'Type Check'}
      ]
    },

    {
      title: 'Sales Recap',
      sectionKey: 'sales_rec',
      groupTitle: 'Value',
      groupSubTitle: 'Subscriptions aggregated based on 1st Issue date & one-off products aggregated based on Order Date',
      columns: [
        {label: 'Product Type', align: 'left', type: 'string', field: 'productType'},
        {label: 'Total Sales', align: 'right', type: 'number', field: 'totalSales', formatter: '$'},
        {label: 'Growth', align: 'right', type: 'number', field: 'Growth', formatter: '%'},
        {label: 'Delta Sales', align: 'right', type: 'number', field: 'deltaSales', formatter: '$'},
        {label: 'Period End', align: 'left', type: 'date', field: 'periodEnd'}
      ]
    },
    {
      title: 'Product Recap',
      sectionKey: 'prod_rec',
      columns: [
        {label: 'Product Type', align: 'left', type: 'string', field: 'productType'},
        {label: 'Total Sales', align: 'right', type: 'number', field: 'totalSales', formatter: '$'},
        {label: 'Growth', align: 'right', type: 'number', field: 'Growth', formatter: '%'},
        {label: 'Delta Sales', align: 'right', type: 'number', field: 'deltaSales', formatter: '$'},
        {label: 'Licenses', align: 'right', type: 'number', field: 'Licenses'},
        {label: 'Delta Licenses', align: 'right', type: 'number', field: 'deltaLicenses'},
        {label: 'Period End', align: 'left', type: 'date', field: 'periodEnd'}
      ],
      children: [
        {
          title: 'New Product in Portfolio',
          sectionKey: 'new_pf',
          columns: [
            {label: 'Product Type', align: 'left', type: 'string', field: 'productType'},
            {label: 'Total Sales', align: 'right', type: 'number', field: 'totalSales', formatter: '$'},
            {label: 'Growth', align: 'right', type: 'number', field: 'Growth', formatter: '%'},
            {label: 'Delta Sales', align: 'right', type: 'number', field: 'deltaSales', formatter: '$'},
            {label: 'Licenses', align: 'right', type: 'number', field: 'Licenses'},
            {label: 'Delta Licenses', align: 'right', type: 'number', field: 'deltaLicenses'},
            {label: 'Period End', align: 'left', type: 'date', field: 'periodEnd'}
          ]
        },
        {
          title: 'Products No Longer in Portfolio',
          sectionKey: 'nl_prod',
          columns: [
            {label: 'Product Type', align: 'left', type: 'string', field: 'productType'},
            {label: 'Total Sales', align: 'right', type: 'number', field: 'totalSales', formatter: '$'},
            {label: 'Growth', align: 'right', type: 'number', field: 'Growth', formatter: '%'},
            {label: 'Delta Sales', align: 'right', type: 'number', field: 'deltaSales', formatter: '$'},
            {label: 'Licenses', align: 'right', type: 'number', field: 'Licenses'},
            {label: 'Delta Licenses', align: 'right', type: 'number', field: 'deltaLicenses'},
            {label: 'Period End', align: 'left', type: 'date', field: 'periodEnd'}
          ]
        },
        {
          title: 'Products with Sales Increase',
          sectionKey: 'prod_sales_inc',
          columns: [
            {label: 'Product Type', align: 'left', type: 'string', field: 'productType'},
            {label: 'Total Sales', align: 'right', type: 'number', field: 'totalSales', formatter: '$'},
            {label: 'Growth', align: 'right', type: 'number', field: 'Growth', formatter: '%'},
            {label: 'Delta Sales', align: 'right', type: 'number', field: 'deltaSales', formatter: '$'},
            {label: 'Licenses', align: 'right', type: 'number', field: 'Licenses'},
            {label: 'Delta Licenses', align: 'right', type: 'number', field: 'deltaLicenses'},
            {label: 'Period End', align: 'left', type: 'date', field: 'periodEnd'}
          ]
        },
        {
          title: 'Products with Stable Sales',
          sectionKey: 'prod_stable_sales',
          columns: [
            {label: 'Product Type', align: 'left', type: 'string', field: 'productType'},
            {label: 'Total Sales', align: 'right', type: 'number', field: 'totalSales', formatter: '$'},
            {label: 'Growth', align: 'right', type: 'number', field: 'Growth', formatter: '%'},
            {label: 'Delta Sales', align: 'right', type: 'number', field: 'deltaSales', formatter: '$'},
            {label: 'Licenses', align: 'right', type: 'number', field: 'Licenses'},
            {label: 'Delta Licenses', align: 'right', type: 'number', field: 'deltaLicenses'},
            {label: 'Period End', align: 'left', type: 'date', field: 'periodEnd'}
          ]
        },
        {
          title: 'Products with Sales Decrease',
          sectionKey: 'prod_sales_dec',
          columns: [
            {label: 'Product Type', align: 'left', type: 'string', field: 'productType'},
            {label: 'Total Sales', align: 'right', type: 'number', field: 'totalSales', formatter: '$'},
            {label: 'Growth', align: 'right', type: 'number', field: 'Growth', formatter: '%'},
            {label: 'Delta Sales', align: 'right', type: 'number', field: 'deltaSales', formatter: '$'},
            {label: 'Licenses', align: 'right', type: 'number', field: 'Licenses'},
            {label: 'Delta Licenses', align: 'right', type: 'number', field: 'deltaLicenses'},
            {label: 'Period End', align: 'left', type: 'date', field: 'periodEnd'}
          ]
        }
      ]
    },
    {
      title: 'Subscription Audit LTM',
      sectionKey: 'sub_aud_ltm',
      columns: [
        {label: 'mpSub', align: 'left', type: 'string', field: 'mpSub'},
        {label: 'full Name', align: 'left', type: 'string', field: 'full Name'},
        {label: 'Product Type', align: 'left', type: 'string', field: 'Product Type'},
        {label: 'Product', align: 'left', type: 'string', field: 'Product'},
        {label: 'Order Date', align: 'left', type: 'date', field: 'Order Date'},
        {label: '1st Issue', align: 'left', type: 'date', field: '1st Issue'},
        {label: 'Expire Issue', align: 'left', type: 'date', field: 'Expire Issue'},
        {label: 'Amount', align: 'right', type: 'number', field: 'Amount', formatter: '$'},
        {label: 'Period', align: 'left', type: 'string', field: 'period'},
        {label: 'productType', align: 'left', type: 'string', field: 'productType'},
        {label: 'openItemType', align: 'left', type: 'string', field: 'openItemType'},
        {label: 'Type Check', align: 'left', type: 'string', field: 'Type Check'}
      ]
    },
    {
      title: 'Subscription Audit LTM-1',
      sectionKey: 'sub_aud_ltm1',
      columns: [
        {label: 'mpSub', align: 'left', type: 'string', field: 'mpSub'},
        {label: 'full Name', align: 'left', type: 'string', field: 'full Name'},
        {label: 'Product Type', align: 'left', type: 'string', field: 'Product Type'},
        {label: 'Product', align: 'left', type: 'string', field: 'Product'},
        {label: 'Order Date', align: 'left', type: 'date', field: 'Order Date'},
        {label: '1st Issue', align: 'left', type: 'date', field: '1st Issue'},
        {label: 'Expire Issue', align: 'left', type: 'date', field: 'Expire Issue'},
        {label: 'Amount', align: 'right', type: 'number', field: 'Amount', formatter: '$'},
        {label: 'Period', align: 'left', type: 'string', field: 'period'},
        {label: 'productType', align: 'left', type: 'string', field: 'productType'},
        {label: 'openItemType', align: 'left', type: 'string', field: 'openItemType'},
        {label: 'Type Check', align: 'left', type: 'string', field: 'Type Check'}
      ]
    },
    {
      title: 'Subscription Line items excluded from the review',
      subTitle: 'openItemType: X = cancel, TRAN = Transfer',
      sectionKey: 'sub_ltm_excluded',
      columns: [
        {label: 'mpSub', align: 'left', type: 'string', field: 'mpSub'},
        {label: 'full Name', align: 'left', type: 'string', field: 'full Name'},
        {label: 'Product Type', align: 'left', type: 'string', field: 'Product Type'},
        {label: 'Product', align: 'left', type: 'string', field: 'Product'},
        {label: 'Order Date', align: 'left', type: 'date', field: 'Order Date'},
        {label: '1st Issue', align: 'left', type: 'date', field: '1st Issue'},
        {label: 'Expire Issue', align: 'left', type: 'date', field: 'Expire Issue'},
        {label: 'Amount', align: 'right', type: 'number', field: 'Amount', formatter: '$'},
        {label: 'Period', align: 'left', type: 'string', field: 'period'},
        {label: 'productType', align: 'left', type: 'string', field: 'productType'},
        {label: 'openItemType', align: 'left', type: 'string', field: 'openItemType'},
        {label: 'Type Check', align: 'left', type: 'string', field: 'Type Check'}
      ]
    },
    {
      title: 'Data, News & Research Activity',
      sectionKey: 'data_news_research_act',
      groupTitle: 'User Activity and Training',
      columns: [
        {label: 'Product Category', align: 'left', type: 'string', field: 'Product Category'},
        {label: 'Product', align: 'left', type: 'string', field: 'Product'},
        {label: 'Action', align: 'left', type: 'string', field: 'Action'},
        {label: 'Total Count last 12 Months', align: 'right', type: 'number', field: 'Total Count last 12 Months ACT'},
        {
          label: 'Delta from Previous 12 Month Period',
          align: 'right',
          type: 'number',
          field: 'Delta from Previous 12 Month Period ACT'
        },
        {label: 'Delta %', align: 'right', type: 'number', field: 'Delta % ACT', formatter: '%'},
        {label: 'Unique Users Last 12 Months', align: 'right', type: 'number', field: 'Unique Users Last 12 Months'},
        {label: 'Delta %', align: 'right', type: 'number', field: 'Delta % EM', formatter: '%'},
        {label: 'Period End', align: 'left', type: 'date', field: 'Period End'}
      ],
      children: [
        {
          title: 'Top 5 Products with the highest activity Increase',
          sectionKey: 'top_5_highest_act_inc',
          columns: [
            {label: 'Product Category', align: 'left', type: 'string', field: 'Product Category'},
            {label: 'Product', align: 'left', type: 'string', field: 'Product'},
            {label: 'Action', align: 'left', type: 'string', field: 'Action'},
            {label: 'Total Count last 12 Months', align: 'right', type: 'number', field: 'Total Count last 12 Months'},
            {
              label: 'Delta from Previous 12 Month Period',
              align: 'right',
              type: 'number',
              field: 'Delta from Previous 12 Month Period'
            },
            {label: 'Delta %', align: 'right', type: 'number', field: 'Delta %', formatter: '%'},
            {label: 'Period End', align: 'left', type: 'date', field: 'Period End'}
          ]
        },
        {
          title: 'Top 5 Products with the Highest Activity Decrease',
          sectionKey: 'top_5_highest_act_dec',
          columns: [
            {label: 'Product Category', align: 'left', type: 'string', field: 'Product Category'},
            {label: 'Product', align: 'left', type: 'string', field: 'Product'},
            {label: 'Action', align: 'left', type: 'string', field: 'Action'},
            {label: 'Total Count last 12 Months', align: 'right', type: 'number', field: 'Total Count last 12 Months'},
            {
              label: 'Delta from Previous 12 Month Period',
              align: 'right',
              type: 'number',
              field: 'Delta from Previous 12 Month Period'
            },
            {label: 'Delta %', align: 'right', type: 'number', field: 'Delta %', formatter: '%'},
            {label: 'Period End', align: 'left', type: 'date', field: 'Period End'}
          ]
        }
      ]
    },
    {
      title: 'Training Activity',
      sectionKey: 'train_act',
      columns: [
        {label: 'Total Webinars', align: 'left', type: 'number', field: 'Total Webinars'},
        {
          label: 'Delta from Previous 12 Month Period',
          align: 'right',
          type: 'number',
          field: 'Delta from Previous 12 Month Period WD'
        },
        {label: 'Delta %', align: 'right', type: 'number', field: 'Delta % WD', formatter: '%'},
        {label: 'Total Participants', align: 'right', type: 'number', field: 'Total Participants'},
        {
          label: 'Delta from Previous 12 Months Period',
          align: 'right',
          type: 'number',
          field: 'Delta from Previous 12 Months Period TD',
        },
        {label: 'Delta %', align: 'right', type: 'number', field: 'Delta % TD', formatter: '%'},
        {label: 'Unique Participants', align: 'right', type: 'number', field: 'Unique Participants'},
        {
          label: 'Delta from Previous 12 Months Period',
          align: 'right',
          type: 'number',
          field: 'Delta from Previous 12 Months Period PD'
        },
        {label: 'Delta %', align: 'right', type: 'number', field: 'Delta % PD', formatter: '%'},
        {label: 'Total CE Credits', align: 'right', type: 'number', field: 'Total CE Credits'},
        {
          label: 'Delta from Previous 12 Months Period',
          align: 'right',
          type: 'number',
          field: 'Delta from Previous 12 Months Period CD'
        },
        {label: 'Delta %', align: 'right', type: 'number', field: 'Delta % CD', formatter: '%'},
        {label: 'Period End', align: 'left', type: 'date', field: 'Period End'}
      ],
      children: [
        {
          title: 'Webinars Attended in the Last 12 Months',
          sectionKey: 'webinar_last_12M',
          columns: [
            {label: 'Date', align: 'left', type: 'date', field: 'Date'},
            {label: 'Webinar Title', align: 'left', type: 'string', field: 'Webinar Title'}
          ]
        },
        {
          title: 'Unique Webinars Participants in the Last 12 Months and Total CE Credits Earned',
          sectionKey: 'uniq_webinar_ce_earned',
          columns: [
            {label: 'Last Name', align: 'left', type: 'string', field: 'Last Name'},
            {label: 'First Name', align: 'left', type: 'string', field: 'First Name'},
            {label: 'Total CE Credits Earned', align: 'right', type: 'number', field: 'Total CE Credits Earned'}
          ]
        },
        {
          title: 'Webinar Participation Details Last 24 Months',
          sectionKey: 'webinar_part_last_24M',
          columns: [
            {label: 'Date', align: 'left', type: 'date', field: 'Date'},
            {label: 'Webinar Title', align: 'left', type: 'string', field: 'Webinar Title'},
            {label: 'Last Name', align: 'left', type: 'string', field: 'Last Name'},
            {label: 'First Name', align: 'left', type: 'string', field: 'First Name'},
            {label: 'CE Credits Earned', align: 'right', type: 'number', field: 'CE Credits Earned'}
          ]
        }
      ]
    },
    {
      title: 'Top 50 Searches',
      sectionKey: 'dummy_top50_searches',
      columns: [],
      children: [
        {
          title: 'BVR Website',
          sectionKey: 'top_search_bvrwebsit',
          columns: [
            {label: 'BVR Website', align: 'left', type: 'string', field: 'BVR Website'},
            {label: 'Query', align: 'left', type: 'string', field: 'Query'}
          ]
        },
        {
          title: 'BRGO',
          sectionKey: 'top_search_brgo',
          columns: [
            {label: 'BRGO', align: 'left', type: 'string', field: 'BRGO'},
            {label: 'Query', align: 'left', type: 'string', field: 'Query'}
          ]
        },
        {
          title: 'BVResearch Pro',
          sectionKey: 'top_search_bvrpro',
          columns: [
            {label: 'BVResearch Pro', align: 'left', type: 'string', field: 'BVResearch Pro'},
            {label: 'Query', align: 'left', type: 'string', field: 'Query'}
          ]
        },
        {
          title: 'BVU',
          sectionKey: 'top_search_bvu',
          columns: [
            {label: 'BVU', align: 'left', type: 'string', field: 'BVU'},
            {label: 'Query', align: 'left', type: 'string', field: 'Query'}
          ]
        },
        {
          title: 'BVLaw',
          sectionKey: 'top_search_bvlaw',
          columns: [
            {label: 'BVLaw', align: 'left', type: 'string', field: 'BVLaw'},
            {label: 'Query', align: 'left', type: 'string', field: 'Query'}
          ]
        },
        {
          title: 'BVWire',
          sectionKey: 'top_search_bvwire',
          columns: [
            {label: 'BVWire', align: 'left', type: 'string', field: 'BVWire'},
            {label: 'Query', align: 'left', type: 'string', field: 'Query'}
          ]
        },
        {
          title: 'Product',
          sectionKey: 'top_search_bvproducts',
          columns: [
            {label: 'Product', align: 'left', type: 'string', field: 'Product'},
            {label: 'Query', align: 'left', type: 'string', field: 'Query'}
          ]
        }
      ]
    }
  ];

  constructor(private appDataFetchSvc: AppDataFetchService, private ngbCalendar: NgbCalendar,
              private dateAdapter: NgbDateAdapter<string>) {
  }

  ngOnInit(): void {
    this.onSearchAccountName('', true);
    this.selectedEndDate = this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
  }

  onSearchAccountName(searchString: any, byPassLengthCheck?: boolean): void {
    let q = searchString.term || '';
    q = q.trim().toLowerCase();
    if (q.length >= 1 || byPassLengthCheck === true) {
      this.appDataFetchSvc.lpAccountName(q).subscribe((result) => {
        this.lpAccountNameItems = result;
      }, (err) => {

      });
    } else {
      this.lpAccountNameItems = [];
    }
  }

  toSqlDate(dtStr: any): string {
    if (dtStr) {
      const dateSplit = dtStr.split('/');
      const date = {
        day: parseInt(dateSplit[1], 10),
        month: parseInt(dateSplit[0], 10),
        year: parseInt(dateSplit[2], 10),
      };
      return date ? date.year + "-" + ('0' + date.month).slice(-2)
        + "-" + ('0' + date.day).slice(-2) : null;
    }
    return dtStr;
  }

  onInputFinished(ev: any): void {
    if (this.selectedAccountName && this.selectedEndDate) {
      const sqlSelectedEndData = this.toSqlDate(this.selectedEndDate);
      this.refreshAccountReviewTableData(this.selectedAccountName, sqlSelectedEndData);
    }
  }

  refreshAccountReviewTableData(accountName: string, endDateStr: string): void {
    this.appDataFetchSvc.accountReviewTableData({accountName, endDateStr}).subscribe((tableData) => {
      this.tableData = tableData;
    }, (err) => {
      console.error(err);
      this.tableData = null;
    });
  }


  printPDF(): void {
    // @ts-ignore
    winPrint();
  }

  // Excel Printing
  async printExcel(): Promise<any> {
    const docParams: any = {};
    if (this.selectedAccountName && this.selectedEndDate) {
      const sqlSelectedEndData = this.toSqlDate(this.selectedEndDate);
      docParams.accountName = this.selectedAccountName;
      docParams.endDateStr = sqlSelectedEndData;
      try {
        const resultGenExcel = await this.runGenerateAccReviewExcel(docParams).toPromise();
        this.triggerDownloadFromBlobURL(resultGenExcel, 'KPI Account Review- ' + this.selectedAccountName + '.xlsx');
        return resultGenExcel;
      } catch (errGenExcel) {
        throw errGenExcel;
      }
    }
  }

  runGenerateAccReviewExcel(docParams: any): Observable<Blob> {
    return this.appDataFetchSvc.generateAccountReviewExcel(docParams).pipe(
      map((data: Blob) => new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})),
      catchError((error) => {
        console.error(error);
        return of(null);
      })
    );
  }

  private triggerDownloadFromBlobURL(blob: Blob, fileName: string): void {
    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
      link.setAttribute('type', 'hidden'); // make it hidden if needed
      // @ts-ignore
      link.href = URL.createObjectURL(blob);
      // @ts-ignore
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }
}
