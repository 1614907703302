import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-publishing',
  templateUrl: './publishing.component.html',
  styleUrls: ['./publishing.component.less']
})
export class PublishingComponent implements OnInit {
  @Input() gridCfg: any;
  @Output() saveAsExcel = new EventEmitter<any>();

  publishOpts = 'DISPLAYED-GRID';

  faExcel = faFileExcel;
  printInProgress: false;

  constructor() {
  }

  ngOnInit(): void {
  }

  onPublishExcel(publishOpts: any): any {
    this.saveAsExcel.emit(publishOpts);
  }
}
