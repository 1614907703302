<div class="mt-3">
  <div class="card card-custom mb-4" style="overflow-x: hidden;" *ngIf="formAccProfileDisplayedData">
    <div class="card-header" style="padding: 0 15px 0 0.25rem;">
      <div class="card-title" style="display: block">
        <button type="button" class="btn" style="padding: 0"
                (click)="togglePane('fromaccountprofile');"
                [attr.aria-expanded]="!isFormAccProfileCollapsed"
                aria-controls="collapseIframe">
          <fa-icon [icon]="faChevronCircleDown" class="fa-cheveron fa-gray"
                   *ngIf="isFormAccProfileCollapsed;else tplChevUp"></fa-icon>
          <ng-template #tplChevUp>
            <fa-icon [icon]="faChevronCircleUp" class="fa-cheveron fa-gray"></fa-icon>
          </ng-template>
        </button>
        {{formAccProfileDisplayedData.firmName}}
      </div>
      <div class="card-toolbar">
        <div class="text-muted">
          As of {{formAccProfileDisplayedData.asOfDate | date: 'MM/dd/yyyy'}}
        </div>
      </div>
    </div>
    <div class="card-body p-2" #collapse="ngbCollapse" [(ngbCollapse)]="isFormAccProfileCollapsed">
      <div class="row">
        <div class="col-md-8">
          <table class="table table-borderless text-center">
            <tr class="font-weight-bold">
              <td>Rank</td>
              <td>LTM Spend</td>
              <td>LTM New Spend</td>
            </tr>
            <tr class="light-grey ">
              <td>{{formAccProfileDisplayedData.Rank}}</td>
              <td>{{formAccProfileDisplayedData.LTMSpend | currency : 'USD' : 'symbol' : '1.0-0'}}</td>
              <td>{{formAccProfileDisplayedData.LTMNewSpend | currency : 'USD' : 'symbol' : '1.0-0'}}</td>
            </tr>

            <tr class="font-weight-bold">
              <td>Type</td>
              <td>Category</td>
              <td>Sales Percentile</td>
            </tr>

            <tr class="light-grey ">
              <td>{{formAccProfileDisplayedData.Type}}</td>
              <td>{{formAccProfileDisplayedData.Category}}</td>
              <td>{{formAccProfileDisplayedData.SalesPercentile}}</td>
            </tr>

            <tr class="font-weight-bold">
              <td>Portal</td>
              <td>New</td>
              <td>Country</td>
            </tr>

            <tr class="light-grey ">
              <td>{{formAccProfileDisplayedData.Portal}}</td>
              <td>{{formAccProfileDisplayedData.New}}</td>
              <td>{{formAccProfileDisplayedData.Country}}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-4">
          <highcharts-chart *ngIf="spendByProductChartObj"
                            [Highcharts]="Highcharts"
                            [options]="spendByProductChartObj"
                            [oneToOne]="true"
                            style="height: 250px; display: block;margin: auto 0;"
          ></highcharts-chart>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8">
          <highcharts-chart *ngIf="spendByAllProductLtmObj"
                            [Highcharts]="Highcharts"
                            [options]="spendByAllProductLtmObj"
                            [oneToOne]="true"
                            style="height: 250px; display: block;margin: auto 0;"
          ></highcharts-chart>
        </div>
        <div class="col-md-4">
          <highcharts-chart *ngIf="spendByProductVerticalChartObj"
                            [Highcharts]="Highcharts"
                            [options]="spendByProductVerticalChartObj"
                            [oneToOne]="true"
                            style="height: 250px; display: block;margin: auto 0;"
          ></highcharts-chart>
        </div>
      </div>
    </div>
  </div>

  <div class="card card-custom mb-4">
    <div class="card-header" style="padding: 0 15px 0 0.25rem;">
      <div class="card-toolbar">
        <button type="button" class="btn"
                (click)="togglePane('aggdata');"
                [attr.aria-expanded]="!isAggDataCollapsed"
                aria-controls="collapseIframe">
          <fa-icon [icon]="faChevronCircleDown" class="fa-cheveron fa-gray"
                   *ngIf="isAggDataCollapsed;else tplChevUp"></fa-icon>
          <ng-template #tplChevUp>
            <fa-icon [icon]="faChevronCircleUp" class="fa-cheveron fa-gray"></fa-icon>
          </ng-template>
        </button>
        <div class="card-title">Aggregated Data</div>
      </div>
    </div>
    <div class="card-body p-2" #collapse="ngbCollapse" [(ngbCollapse)]="isAggDataCollapsed">
      <ng2-smart-table class="smart-table-wrapper"  [settings]="kpiChartDataComponentCfg.firmProfileAggDataCfg"
                       [source]="aggDataObj"></ng2-smart-table>
    </div>
  </div>

  <div class="card card-custom mb-4">
    <div class="card-header" style="padding: 0 15px 0 0.25rem;">
      <div class="card-toolbar">
        <button type="button" class="btn"
                (click)="togglePane('productdata');"
                [attr.aria-expanded]="!isProductDataCollapsed"
                aria-controls="collapseIframe">
          <fa-icon [icon]="faChevronCircleDown" class="fa-cheveron fa-gray"
                   *ngIf="isProductDataCollapsed;else tplChevUp"></fa-icon>
          <ng-template #tplChevUp>
            <fa-icon [icon]="faChevronCircleUp" class="fa-cheveron fa-gray"></fa-icon>
          </ng-template>
        </button>
        <div class="card-title">Product Data</div>
      </div>
    </div>
    <div class="card-body p-2" #collapse="ngbCollapse" [(ngbCollapse)]="isProductDataCollapsed">
      <ng2-smart-table class="smart-table-wrapper"  [settings]="kpiChartDataComponentCfg.firmProfileProductDataCfg"
                       [source]="productDataObj"></ng2-smart-table>
    </div>
  </div>

  <div class="card card-custom mb-4">
    <div class="card-header" style="padding: 0 15px 0 0.25rem;">
      <div class="card-toolbar">
        <button type="button" class="btn"
                (click)="togglePane('salesdetails');"
                [attr.aria-expanded]="!isSalesDetailsCollapsed"
                aria-controls="collapseIframe">
          <fa-icon [icon]="faChevronCircleDown" class="fa-cheveron fa-gray"
                   *ngIf="isSalesDetailsCollapsed;else tplChevUp"></fa-icon>
          <ng-template #tplChevUp>
            <fa-icon [icon]="faChevronCircleUp" class="fa-cheveron fa-gray"></fa-icon>
          </ng-template>
        </button>
        <div class="card-title">Sales Details</div>
      </div>
    </div>
    <div class="card-body p-2" #collapse="ngbCollapse" [(ngbCollapse)]="isSalesDetailsCollapsed">
      <ng2-smart-table class="smart-table-wrapper"  [settings]="kpiChartDataComponentCfg.firmProfileSalesDetailsDataCfg"
                       [source]="salesDetailsDataObj"></ng2-smart-table>
    </div>
  </div>

  <div class="card card-custom mb-4">
    <div class="card-header" style="padding: 0 15px 0 0.25rem;">
      <div class="card-toolbar">
        <button type="button" class="btn"
                (click)="togglePane('activity');"
                [attr.aria-expanded]="!isActivityCollapsed"
                aria-controls="collapseIframe">
          <fa-icon [icon]="faChevronCircleDown" class="fa-cheveron fa-gray"
                   *ngIf="isActivityCollapsed;else tplChevUp"></fa-icon>
          <ng-template #tplChevUp>
            <fa-icon [icon]="faChevronCircleUp" class="fa-cheveron fa-gray"></fa-icon>
          </ng-template>
        </button>
        <div class="card-title">Activity</div>
      </div>
    </div>
    <div class="card-body p-2" #collapse="ngbCollapse" [(ngbCollapse)]="isActivityCollapsed">
      <ng2-smart-table class="smart-table-wrapper"  [settings]="kpiChartDataComponentCfg.firmProfileProfileActivityCfg"
                       [source]="profileActivityDataObj"></ng2-smart-table>
    </div>
  </div>
</div>
