<div class="card-main-wrapper mb-4">
  <div>

    <div class="card card-custom" *ngIf="asOfVal">
      <div class="card-header"
           style="padding: 0 15px 0 0.25rem;justify-content: flex-start;align-items: flex-start;flex-wrap: unset;min-height: unset;gap:10px;">
        <div>
          <b style="padding-right: 5px;">Account Category</b>
          <ng-select *ngIf="lpFirmCat" class="custom d-inline-block"
                     style="min-width: 150px;"
                     [items]="lpFirmCat"
                     appearance="outline"
                     [searchable]="false"
                     [clearable]="false"
                     bindLabel="label"
                     dropdownPosition="bottom"
                     bindValue="val"
                     labelForId="label"
                     placeholder=""
                     [virtualScroll]="true"
                     [(ngModel)]="lpFirmCatSelected"
                     (change)="refreshTiles()">
            <ng-template ng-option-tmp let-item="item">
              <div><span> </span><span>{{item.label}}</span></div>
            </ng-template>
          </ng-select>
        </div>

        <div>
          <b style="padding-right: 5px;">Account Percentile</b>
          <ng-select *ngIf="lpPercentile" class="custom d-inline-block"
                     style="min-width: 150px;"
                     [items]="lpPercentile"
                     appearance="outline"
                     [searchable]="false"
                     [clearable]="false"
                     bindLabel="label"
                     dropdownPosition="bottom"
                     bindValue="val"
                     labelForId="label"
                     placeholder=""
                     [virtualScroll]="true"
                     [(ngModel)]="lpPercentileSelected"
                     (change)="refreshTiles()">
            <ng-template ng-option-tmp let-item="item">
              <div><span> </span><span>{{item.label}}</span></div>
            </ng-template>
          </ng-select>
        </div>

        <div class="card-title" style="margin-left: auto">
          As of {{asOfVal  }}
        </div>

      </div>

      <div class="card-body p-2">
        <div id="ind-stats-wrapper" *ngIf="tilesData">


          <div class="img-tile-responsive" (click)="openKpiChartsAndData(kpiIdLabel.AVG_ACC.id)">
            <div class="img-tile-gallery img-tile-card img-tile-card-2">
              <div class="img-text-top">
                <p class="text-tile-white">{{kpiIdLabel.AVG_ACC.label}}</p>
              </div>
              <div class="img-text-bottom" [ngClass]="{'text-tile-green': tilesData.avgAccVal.Pct>0,
                'text-tile-red': tilesData.avgAccVal.Pct<0,
                'text-tile-neutral': tilesData.avgAccVal.Pct==0}">
                <span [ngSwitch]="true">
                  <span *ngSwitchCase="tilesData.avgAccVal.Pct>0">
                    <fa-icon [icon]="faArrowUp"></fa-icon>
                  </span>
                  <span *ngSwitchCase="tilesData.avgAccVal.Pct<0">
                    <fa-icon [icon]="faArrowDown"></fa-icon>
                  </span>
                  <span *ngSwitchCase="tilesData.avgAccVal.Pct==0">
                    <fa-icon [icon]="faArrowRight"></fa-icon>
                  </span>
                </span>

                <span>
                  {{tilesData.avgAccVal.Cu | currency : 'USD' : 'symbol' : '1.0-0'}}
                </span>

                <span>
                  {{tilesData.avgAccVal.Pct}}%
                </span>
              </div>
              <div style="position: absolute;  bottom: 10px;width: 100%;">
                <b><span
                  class="text-tile-blue"> {{tilesData.avgAccVal.value | currency : 'USD' : 'symbol' : '1.0-0'}}</span></b>
              </div>
            </div>
          </div>

          <div class="img-tile-responsive">
            <div class="img-tile-gallery img-tile-card img-tile-card-2" style="cursor: unset" >
              <div class="img-text-top">
                <p class="text-tile-white">{{kpiIdLabel.AVG_ACC_YTD.label}}</p>
              </div>
              <div class="img-text-bottom" [ngClass]="{'text-tile-green': tilesData.avgAccValYtd.Pct>0,
                'text-tile-red': tilesData.avgAccValYtd.Pct<0,
                'text-tile-neutral': tilesData.avgAccValYtd.Pct==0}">
                <span [ngSwitch]="true">
                  <span *ngSwitchCase="tilesData.avgAccValYtd.Pct>0">
                    <fa-icon [icon]="faArrowUp"></fa-icon>
                  </span>
                  <span *ngSwitchCase="tilesData.avgAccValYtd.Pct<0">
                    <fa-icon [icon]="faArrowDown"></fa-icon>
                  </span>
                  <span *ngSwitchCase="tilesData.avgAccValYtd.Pct==0">
                    <fa-icon [icon]="faArrowRight"></fa-icon>
                  </span>
                </span>

                <span>
                  {{tilesData.avgAccValYtd.Cu | currency : 'USD' : 'symbol' : '1.0-0'}}
                </span>

                <span>
                  {{tilesData.avgAccValYtd.Pct}}%
                </span>
              </div>
              <div style="position: absolute;  bottom: 10px;width: 100%;">
                <b><span
                  class="text-tile-blue"> {{tilesData.avgAccValYtd.value | currency : 'USD' : 'symbol' : '1.0-0'}}</span></b>
              </div>
            </div>
          </div>

          <div class="img-tile-responsive" (click)="openKpiChartsAndData(kpiIdLabel.ACT_ACC.id)">
            <div class="img-tile-gallery img-tile-card img-tile-card-2">
              <div class="img-text-top">
                <p class="text-tile-white">{{kpiIdLabel.ACT_ACC.label}}</p>
              </div>
              <div class="img-text-bottom" [ngClass]="{'text-tile-green': tilesData.activeAcc.Pct>0,
                'text-tile-red': tilesData.activeAcc.Pct<0,
                'text-tile-neutral': tilesData.activeAcc.Pct==0}">
                <span [ngSwitch]="true">
                  <span *ngSwitchCase="tilesData.activeAcc.Pct>0">
                    <fa-icon [icon]="faArrowUp"></fa-icon>
                  </span>
                  <span *ngSwitchCase="tilesData.activeAcc.Pct<0">
                    <fa-icon [icon]="faArrowDown"></fa-icon>
                  </span>
                  <span *ngSwitchCase="tilesData.activeAcc.Pct==0">
                    <fa-icon [icon]="faArrowRight"></fa-icon>
                  </span>
                </span>

                <span>
                  {{tilesData.activeAcc.Cu | number }}
                </span>

                <span>
                  {{tilesData.activeAcc.Pct}}%
                </span>
              </div>
              <div style="position: absolute;  bottom: 10px;width: 100%;">
                <b><span class="text-tile-blue"> {{tilesData.activeAcc.value | number }}</span></b>
              </div>
            </div>
          </div>

          <div class="img-tile-responsive">
            <div class="img-tile-gallery img-tile-card img-tile-card-2"  style="cursor: unset">
              <div class="img-text-top">
                <p class="text-tile-white">{{kpiIdLabel.ACT_ACC_YTD.label}}</p>
              </div>
              <div class="img-text-bottom" [ngClass]="{'text-tile-green': tilesData.activeAccYtd.Pct>0,
                'text-tile-red': tilesData.activeAccYtd.Pct<0,
                'text-tile-neutral': tilesData.activeAccYtd.Pct==0}">
                <span [ngSwitch]="true">
                  <span *ngSwitchCase="tilesData.activeAccYtd.Pct>0">
                    <fa-icon [icon]="faArrowUp"></fa-icon>
                  </span>
                  <span *ngSwitchCase="tilesData.activeAccYtd.Pct<0">
                    <fa-icon [icon]="faArrowDown"></fa-icon>
                  </span>
                  <span *ngSwitchCase="tilesData.activeAccYtd.Pct==0">
                    <fa-icon [icon]="faArrowRight"></fa-icon>
                  </span>
                </span>

                <span>
                  {{tilesData.activeAccYtd.Cu | number }}
                </span>

                <span>
                  {{tilesData.activeAccYtd.Pct}}%
                </span>
              </div>
              <div style="position: absolute;  bottom: 10px;width: 100%;">
                <b><span class="text-tile-blue"> {{tilesData.activeAccYtd.value | number }}</span></b>
              </div>
            </div>
          </div>

          <div class="img-tile-responsive" (click)="openKpiChartsAndData(kpiIdLabel.TOTAL_ACC.id)">
            <div class="img-tile-gallery img-tile-card img-tile-card-2">
              <div class="img-text-top">
                <p class="text-tile-white">{{kpiIdLabel.TOTAL_ACC.label}}</p>
              </div>
              <div class="img-text-bottom" [ngClass]="{'text-tile-green': tilesData.totalAccVal.Pct>0,
                'text-tile-red': tilesData.totalAccVal.Pct<0,
                'text-tile-neutral': tilesData.totalAccVal.Pct==0}">
                <span [ngSwitch]="true">
                  <span *ngSwitchCase="tilesData.totalAccVal.Pct>0">
                    <fa-icon [icon]="faArrowUp"></fa-icon>
                  </span>
                  <span *ngSwitchCase="tilesData.totalAccVal.Pct<0">
                    <fa-icon [icon]="faArrowDown"></fa-icon>
                  </span>
                  <span *ngSwitchCase="tilesData.totalAccVal.Pct==0">
                    <fa-icon [icon]="faArrowRight"></fa-icon>
                  </span>
                </span>

                <span>
                  {{tilesData.totalAccVal.Cu | currency : 'USD' : 'symbol' : '1.0-0'}}
                </span>

                <span>
                  {{tilesData.totalAccVal.Pct}}%
                </span>
              </div>
              <div style="position: absolute;  bottom: 10px;width: 100%;">
                <b><span
                  class="text-tile-blue"> {{tilesData.totalAccVal.value | currency : 'USD' : 'symbol' : '1.0-0'}}</span></b>
              </div>
            </div>
          </div>

          <div class="img-tile-responsive">
            <div class="img-tile-gallery img-tile-card img-tile-card-2"  style="cursor: unset">
              <div class="img-text-top">
                <p class="text-tile-white">{{kpiIdLabel.TOTAL_ACC_YTD.label}}</p>
              </div>
              <div class="img-text-bottom" [ngClass]="{'text-tile-green': tilesData.totalAccValYtd.Pct>0,
                'text-tile-red': tilesData.totalAccValYtd.Pct<0,
                'text-tile-neutral': tilesData.totalAccValYtd.Pct==0}">
                <span [ngSwitch]="true">
                  <span *ngSwitchCase="tilesData.totalAccValYtd.Pct>0">
                    <fa-icon [icon]="faArrowUp"></fa-icon>
                  </span>
                  <span *ngSwitchCase="tilesData.totalAccValYtd.Pct<0">
                    <fa-icon [icon]="faArrowDown"></fa-icon>
                  </span>
                  <span *ngSwitchCase="tilesData.totalAccValYtd.Pct==0">
                    <fa-icon [icon]="faArrowRight"></fa-icon>
                  </span>
                </span>

                <span>
                  {{tilesData.totalAccValYtd.Cu | currency : 'USD' : 'symbol' : '1.0-0'}}
                </span>

                <span>
                  {{tilesData.totalAccValYtd.Pct}}%
                </span>
              </div>
              <div style="position: absolute;  bottom: 10px;width: 100%;">
                <b><span
                  class="text-tile-blue"> {{tilesData.totalAccValYtd.value | currency : 'USD' : 'symbol' : '1.0-0'}}</span></b>
              </div>
            </div>
          </div>

          <div class="img-tile-responsive" (click)="openKpiChartsAndData(kpiIdLabel.ACC_RENEWAL_RATE_VAL.id)">
            <div class="img-tile-gallery img-tile-card img-tile-card-2">
              <div class="img-text-top">
                <p class="text-tile-white">{{kpiIdLabel.ACC_RENEWAL_RATE_VAL.label}}</p>
              </div>
              <div class="img-text-bottom" [ngClass]="{'text-tile-green': tilesData.accRenewalRateVal.Pct>0,
                'text-tile-red': tilesData.accRenewalRateVal.Pct<0,
                'text-tile-neutral': tilesData.accRenewalRateVal.Pct==0}">
                <span [ngSwitch]="true">
                  <span *ngSwitchCase="tilesData.accRenewalRateVal.Pct>0">
                    <fa-icon [icon]="faArrowUp"></fa-icon>
                  </span>
                  <span *ngSwitchCase="tilesData.accRenewalRateVal.Pct<0">
                    <fa-icon [icon]="faArrowDown"></fa-icon>
                  </span>
                  <span *ngSwitchCase="tilesData.accRenewalRateVal.Pct==0">
                    <fa-icon [icon]="faArrowRight"></fa-icon>
                  </span>
                </span>

                <span>
                  {{tilesData.accRenewalRateVal.Cu | number }}pt
                </span>

                <span>
                  {{tilesData.accRenewalRateVal.Pct}}%
                </span>
              </div>
              <div style="position: absolute;  bottom: 10px;width: 100%;">
                <b><span class="text-tile-blue"> {{tilesData.accRenewalRateVal.value  }}%</span></b>
              </div>
            </div>
          </div>

          <div class="img-tile-responsive" (click)="openKpiChartsAndData(kpiIdLabel.ACC_RENEWAL_RATE_VOL.id)">
            <div class="img-tile-gallery img-tile-card img-tile-card-2">
              <div class="img-text-top">
                <p class="text-tile-white">{{kpiIdLabel.ACC_RENEWAL_RATE_VOL.label}}</p>
              </div>
              <div class="img-text-bottom" [ngClass]="{'text-tile-green': tilesData.accRenewalRateVol.Pct>0,
                'text-tile-red': tilesData.accRenewalRateVol.Pct<0,
                'text-tile-neutral': tilesData.accRenewalRateVol.Pct==0}">
                <span [ngSwitch]="true">
                  <span *ngSwitchCase="tilesData.accRenewalRateVol.Pct>0">
                    <fa-icon [icon]="faArrowUp"></fa-icon>
                  </span>
                  <span *ngSwitchCase="tilesData.accRenewalRateVol.Pct<0">
                    <fa-icon [icon]="faArrowDown"></fa-icon>
                  </span>
                  <span *ngSwitchCase="tilesData.accRenewalRateVol.Pct==0">
                    <fa-icon [icon]="faArrowRight"></fa-icon>
                  </span>
                </span>

                <span>
                  {{tilesData.accRenewalRateVol.Cu | number }}pt
                </span>

                <span>
                  {{tilesData.accRenewalRateVol.Pct}}%
                </span>
              </div>
              <div style="position: absolute;  bottom: 10px;width: 100%;">
                <b><span class="text-tile-blue"> {{tilesData.accRenewalRateVol.value }}%</span></b>
              </div>
            </div>
          </div>

          <div class="img-tile-responsive" (click)="openKpiChartsAndData(kpiIdLabel.CON_RATE_VAL.id)">
            <div class="img-tile-gallery img-tile-card img-tile-card-2">
              <div class="img-text-top">
                <p class="text-tile-white">{{kpiIdLabel.CON_RATE_VAL.label}}</p>
              </div>
              <div class="img-text-bottom" [ngClass]="{'text-tile-green': tilesData.accConversionRateVal.Pct>0,
                'text-tile-red': tilesData.accConversionRateVal.Pct<0,
                'text-tile-neutral': tilesData.accConversionRateVal.Pct==0}">
                <span [ngSwitch]="true">
                  <span *ngSwitchCase="tilesData.accConversionRateVal.Pct>0">
                    <fa-icon [icon]="faArrowUp"></fa-icon>
                  </span>
                  <span *ngSwitchCase="tilesData.accConversionRateVal.Pct<0">
                    <fa-icon [icon]="faArrowDown"></fa-icon>
                  </span>
                  <span *ngSwitchCase="tilesData.accConversionRateVal.Pct==0">
                    <fa-icon [icon]="faArrowRight"></fa-icon>
                  </span>
                </span>

                <span>
                  {{tilesData.accConversionRateVal.Cu | number }}pt
                </span>

                <span>
                  {{tilesData.accConversionRateVal.Pct}}%
                </span>
              </div>
              <div style="position: absolute;  bottom: 10px;width: 100%;">
                <b><span class="text-tile-blue"> {{tilesData.accConversionRateVal.value }}%</span></b>
              </div>
            </div>
          </div>

          <div class="img-tile-responsive" (click)="openKpiChartsAndData(kpiIdLabel.CON_RATE_VOL.id)">
            <div class="img-tile-gallery img-tile-card img-tile-card-2">
              <div class="img-text-top">
                <p class="text-tile-white">{{kpiIdLabel.CON_RATE_VOL.label}}</p>
              </div>
              <div class="img-text-bottom" [ngClass]="{'text-tile-green': tilesData.accConversionRateVol.Pct>0,
                'text-tile-red': tilesData.accConversionRateVol.Pct<0,
                'text-tile-neutral': tilesData.accConversionRateVol.Pct==0}">
                <span [ngSwitch]="true">
                  <span *ngSwitchCase="tilesData.accConversionRateVol.Pct>0">
                    <fa-icon [icon]="faArrowUp"></fa-icon>
                  </span>
                  <span *ngSwitchCase="tilesData.accConversionRateVol.Pct<0">
                    <fa-icon [icon]="faArrowDown"></fa-icon>
                  </span>
                  <span *ngSwitchCase="tilesData.accConversionRateVol.Pct==0">
                    <fa-icon [icon]="faArrowRight"></fa-icon>
                  </span>
                </span>

                <span>
                  {{tilesData.accConversionRateVol.Cu | number }}pt
                </span>

                <span>
                  {{tilesData.accConversionRateVol.Pct}}%
                </span>
              </div>
              <div style="position: absolute;  bottom: 10px;width: 100%;">
                <b><span class="text-tile-blue"> {{tilesData.accConversionRateVol.value }}%</span></b>
              </div>
            </div>
          </div>

          <div class="img-tile-responsive" (click)="openKpiChartsAndData(kpiIdLabel.AVG_MONTHLY_LOGIN.id)">
            <div class="img-tile-gallery img-tile-card img-tile-card-2">
              <div class="img-text-top">
                <p class="text-tile-white">{{kpiIdLabel.AVG_MONTHLY_LOGIN.label}}</p>
              </div>
              <div class="img-text-bottom" [ngClass]="{'text-tile-green': tilesData.activity.Pct>0,
                'text-tile-red': tilesData.activity.Pct<0,
                'text-tile-neutral': tilesData.activity.Pct==0}">
                <span [ngSwitch]="true">
                  <span *ngSwitchCase="tilesData.activity.Pct>0">
                    <fa-icon [icon]="faArrowUp"></fa-icon>
                  </span>
                  <span *ngSwitchCase="tilesData.activity.Pct<0">
                    <fa-icon [icon]="faArrowDown"></fa-icon>
                  </span>
                  <span *ngSwitchCase="tilesData.activity.Pct==0">
                    <fa-icon [icon]="faArrowRight"></fa-icon>
                  </span>
                </span>

                <span>
                  {{tilesData.activity.Cu | number }}
                </span>

                <span>
                  {{tilesData.activity.Pct}}%
                </span>
              </div>
              <div style="position: absolute;  bottom: 10px;width: 100%;">
                <b><span class="text-tile-blue"> {{tilesData.activity.value | number }}</span></b>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>
  </div>
</div>
