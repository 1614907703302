import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import * as lodash from 'lodash';
import {AppDataFetchService} from '../../../services/app-data-fetch.service';
import {ElasticService} from './elastic.service';
import {ModelUtilsService} from './model-utils.service';

import {Observable, of} from 'rxjs';
import {map, catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  static printInProgress = false;

  constructor(
    private toastr: ToastrService,
    private appDataFetchService: AppDataFetchService,
    private elasticService: ElasticService,
    private modelUtilsService: ModelUtilsService
  ) {
  }

  private static setPrintInProgress(): void {
    PrintService.printInProgress = true;
  }

  private static endPrintInProgress(): void {
    PrintService.printInProgress = false;
  }

  public saveAsExcel(pubOpts: string, columns, masterGridAllCols): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      if (this.checkIfPrintingPossible()) {
        this.toastr.info('Please wait while we are preparing your export.', 'Exporting');
        PrintService.setPrintInProgress();

        try {
          const preparedPrintCols = await this.prepareColsForAllColsPrinting(
            pubOpts,
            columns.selectedCols,
            columns.availableCols
          );

          const colDefs = this.createColDefs(preparedPrintCols);
          const responsePrintExcel = await this.printExcel({
            cols: preparedPrintCols,
            options: pubOpts,
            colDefs,
            masterGridCols: masterGridAllCols,
          });

          PrintService.endPrintInProgress();
          resolve(responsePrintExcel);
        } catch (error) {
          PrintService.endPrintInProgress();
          reject(error);
        }
      } else {
        this.toastr.error('Please wait for previous export to be completed.', 'Exporting');
        reject(null);
      }
    });
  }

  private async prepareColsForAllColsPrinting(pubOpts: string, printCols: any[], availableCols: any[]): Promise<any[]> {
    if (['ALL-GRID'].includes(pubOpts)) {
      try {
        const responseData = await this.appDataFetchService.getColsPrintOrder().toPromise();
        const ordData = responseData.reduce((acc, val) => {
          acc[val.name] = val.column_id;
          return acc;
        }, {});
        const preparedPrintCols = lodash
          .chain(printCols)
          .concat(availableCols)
          .sortBy((col) => ordData[col.col_id])
          .value();
        return preparedPrintCols;
      } catch (err) {
        console.error(err);
        return printCols;
      }
    }

    return printCols;
  }

  private createColDefs(preparedPrintCols: any[]): any {
    return preparedPrintCols.reduce((acc, col) => {
      acc[col.col_id] = this.modelUtilsService.getSTColDefForCol(col);
      return acc;
    }, {});
  }

  private async printExcel(pubArgs: any): Promise<any> {
    const currentESSearchBody = await this.elasticService.getFilteredESBody(pubArgs.checkedRows);

    const docParams = {
      currentESSearchBody,
      pubCols: pubArgs.cols,
      pubOpts: pubArgs.options,
      pubColDefs: pubArgs.colDefs,
      masterGridCols: pubArgs.masterGridCols,
    };

    try {
      const resultGenExcel = await this.generateExcel(docParams).toPromise();
      this.triggerDownloadFromBlobURL(resultGenExcel, 'KPI Firm Study.xlsx');
      return resultGenExcel;
    } catch (errGenExcel) {
      throw errGenExcel;
    }
  }

  private generateExcel(params: any): Observable<Blob> {
    const bodyParams = {
      print_data: {
        esBody: params.currentESSearchBody,
        cols: params.pubCols,
        colDefs: params.pubColDefs,
        opts: params.pubOpts,
        masterGridCols: params.masterGridCols,
      },
    };

    return this.appDataFetchService.generateExcel(bodyParams).pipe(
      map((data: Blob) => new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})),
      catchError((error) => {
        console.error(error);
        return of(null);
      })
    );
  }

  private checkIfPrintingPossible(): boolean {
    if (PrintService.printInProgress) {
      this.toastr.error('Currently processing previous document request. Please wait.', 'Error');
      return false;
    }
    return true;
  }

  private triggerDownloadFromBlobURL(blob: Blob, fileName: string): void {
    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
      link.setAttribute('type', 'hidden'); // make it hidden if needed
      // @ts-ignore
      link.href = URL.createObjectURL(blob);
      // @ts-ignore
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }
}
