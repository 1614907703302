import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {UserAccessManagementComponent} from "./components/superusers/user-access-management/user-access-management.component";
import {MainComponent} from "./components/main/main.component";
import {KpiChartsAndTableBaseComponent} from "./components/kpi_charts_and_data/kpi-charts-and-table-base.component";
import {KpiFirmAccountProfileComponent} from "./components/kpi_firm_account_profile/kpi-firm-account-profile.component";
import {KpiAccountScreeningComponent} from "./components/kpi_account_screening/kpi-account-screening.component";
import {KpiProductDetailsComponent} from "./components/kpi_product_details/kpi-product-details.component";
import {KpiAccountReviewComponent} from "./components/kpi_account_review/kpi-account-review.component";


const routes: Routes = [
  {path: '', redirectTo: '/kpi_dashboard', pathMatch: 'full'}, // Redirect to KPI Dashboard by default
  {path: 'kpi_dashboard', component: MainComponent}, // Route for KPI Dashboard
  {path: 'kpi_charts_and_data/:kpiName', component: KpiChartsAndTableBaseComponent},
  {path: 'kpi_firm_account_profile/:firm', component: KpiFirmAccountProfileComponent},
  {path: 'kpi_account_screening', component: KpiAccountScreeningComponent},
  {path: 'kpi_product_details/:productTypeIdSelected', component: KpiProductDetailsComponent},
  {path: 'kpi_account_review', component: KpiAccountReviewComponent},
  {path: 'superusers', component: UserAccessManagementComponent}
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
