import {Injectable} from '@angular/core';
import * as moment from 'moment';
import * as lodash from 'lodash';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})

// tslint:disable:triple-equals
export class ElasticService {
  constructor() {
  }

  "use strict";
  private DATE_FORMAT = "MM/dd/yyyy";
  private ES_QUERY_DELAY = 800;
  private ENUMS = {
    INCLUSION_SELECTED: Object.freeze({
      ALL: 0,
      SOME: 1,
      NONE: 2
    })
  };

  private timeoutPromise;
  private criteriaRaw: any = {
    filters: {},
    searchModel: {},
    quickColFiltersModel: {},
    globalQuickSearchStr: "",
    sortModel: {},
    valIDsUniverse: [],
    size: 10
  };

  private static getWildcardPredicate(filterId, colValue, isString): any {
    let predicate: {};
    let colWildCardStr: any;
    predicate = {};
    colWildCardStr = colValue.toLowerCase();
    if (isString) {
      const lowercaseSearchFieldFilerId = filterId + ".lowercaseSearchField";
      predicate[lowercaseSearchFieldFilerId] = "*" + colWildCardStr + "*";
    } else {
      predicate[filterId + ".raw"] = "*" + colWildCardStr + "*";
    }
    return predicate;
  }

  private static getWildcardExpression(inclusionType, currFilterId, filterString): any {
    const expr: {} = {};
    expr[inclusionType] = [];
    expr[inclusionType].push({
      wildcard: ElasticService.getWildcardPredicate(currFilterId, filterString, true)
    });
    return expr;
  }

  private static getTermPredicate(filterId, eq): any {
    const predicate = {};
    predicate[filterId] = eq;
    return predicate;
  }


  private formatDate(dt): any {
    if (!dt) {
      return "";
    }
    let dtObj;
    try {
      dtObj = new Date(dt);
    } catch (e) {
      return "";
    }
    return moment(dtObj).format('MM/DD/YYYY');
  }

  private getRangePredicate(filterId, lb, ub, lbe, ube, isDate?): any {
    const predicate = {};
    let convertToNumber = false;
    predicate[filterId] = {};
    if (isDate) {
      predicate[filterId].format = this.DATE_FORMAT;
    } else {
      convertToNumber = true;
    }
    if (lb !== undefined) {
      predicate[filterId].gt = convertToNumber ? parseFloat(lb) : lb;
    }
    if (ub !== undefined) {
      predicate[filterId].lt = convertToNumber ? parseFloat(ub) : ub;
    }
    if (lbe !== undefined) {
      predicate[filterId].gte = convertToNumber ? parseFloat(lbe) : lbe;
    }
    if (ube !== undefined) {
      predicate[filterId].lte = convertToNumber ? parseFloat(ube) : ube;
    }

    return predicate;
  }

  getTreeSearchInnerModel(treeFilterDM: any): any {
    if (!treeFilterDM.selectedNodes.length) {
      console.log("No nodes to search");
      return null;
    }

    const targetFields: string[] = [];
    let searchWord: string;

    if (treeFilterDM.treeType === 'sic') {
      searchWord = 'sic';
    } else {
      searchWord = 'naics';
    }

    if (treeFilterDM.fieldOptions.primary) {
      targetFields.push(searchWord + 'Primary');
    }

    if (treeFilterDM.fieldOptions.other) {
      targetFields.push(searchWord + 'Secondary');
    }

    if (!targetFields.length) {
      console.error("No fields to search. Control should not reach here");
      return null;
    }

    const selectedTreeFilters = treeFilterDM.selectedNodes;

    const searchQuery = {
      should: [] as any[],
      minimum_should_match: 1,
    };

    selectedTreeFilters.forEach((filterId: any) => {
      targetFields.forEach((field: string) => {
        const currTerm: { [key: string]: any } = {};
        currTerm[field] = filterId;
        searchQuery.should.push({
          term: currTerm,
        });
      });
    });

    return searchQuery;
  }

  getFilterSearchModel(filters: any): any {
    const unusedFiltersComparator: any[] = [];
    const filterSearchESArray = lodash.map(filters, (currFilter: any) => {
      if (!currFilter || !currFilter.hasOwnProperty('isTouched') || !currFilter.isTouched) {
        return null;
      }
      unusedFiltersComparator.push(currFilter.id);

      const currFilterId = currFilter.id;

      if (currFilter.type === 'string') {
        if (!currFilter.filterStr.trim().length) {
          return null;
        }

        if (currFilter.currentCondition.filter_value === 'CONTAINS') {
          return ElasticService.getWildcardExpression('must', currFilterId, currFilter.filterStr);
        } else {
          const expr = ElasticService.getWildcardExpression('should', currFilterId, currFilter.filterStr);

          if (!expr) {
            return null;
          } else {
            return {
              must_not: [{
                bool: expr
              }]
            };
          }
        }
      } else if (currFilter.type === 'numeric') {
        if (currFilter.includeNull && currFilter.includeNull === true) {
          if (isNaN(parseInt(currFilter.localMin, 10)) && isNaN(parseInt(currFilter.localMax, 10))) {
            return null;
          }

          return {
            minimum_should_match: 1,
            should: [
              {
                bool: {
                  must_not: {
                    exists: {
                      field: currFilterId
                    }
                  }
                }
              },
              {
                range: this.getRangePredicate(currFilterId, undefined, undefined, currFilter.min, currFilter.max)
              }
            ]
          };
        } else {
          return {
            must: {
              range: this.getRangePredicate(currFilterId, undefined, undefined, currFilter.min, currFilter.max)
            }
          };
        }
      } else if (currFilter.type === 'numericBounds') {
        if (currFilter.currentCondition) {
          if (currFilter.currentCondition.filter_value === 'BETWEEN') {
            if (currFilter.filterBounds.lb !== undefined && currFilter.filterBounds.ub !== undefined) {
              return {
                must: {
                  range: this.getRangePredicate(currFilterId, undefined, undefined, currFilter.filterBounds.lb, currFilter.filterBounds.ub)
                }
              };
            }
            return null;
          } else {
            if (currFilter.filterSingleBound === undefined || currFilter.filterSingleBound === '') {
              return null;
            }

            let rangePredicate: any = {};

            switch (currFilter.currentCondition.filter_value) {
              case 'LT':
                rangePredicate = this.getRangePredicate(currFilterId, undefined, currFilter.filterSingleBound, undefined, undefined);
                break;
              case 'GT':
                rangePredicate = this.getRangePredicate(currFilterId, currFilter.filterSingleBound, undefined, undefined, undefined);
                break;
              case 'LTE':
                rangePredicate = this.getRangePredicate(currFilterId, undefined, undefined, undefined, currFilter.filterSingleBound);
                break;
              case 'GTE':
                rangePredicate = this.getRangePredicate(currFilterId, undefined, undefined, currFilter.filterSingleBound, undefined);
                break;
              case 'EQ':
                return {
                  must: {
                    term: ElasticService.getTermPredicate(currFilterId, currFilter.filterSingleBound)
                  }
                };
              default:
                return null;
            }

            return {
              must: {
                range: rangePredicate
              }
            };
          }
        } else {
          return null;
        }
      } else if (currFilter.type === 'bool') {
        if (currFilter.filterVal === undefined) {
          return null;
        }

        const boolVal = currFilter.filterVal ? 1 : 0;

        return {
          must: {
            term: ElasticService.getTermPredicate(currFilterId, boolVal)
          }
        };
      } else if (currFilter.type === 'tree') {
        if (currFilter.isDisabled) {
          console.log('No category selected. Ignoring this criteria');
          return null;
        }

        return this.getTreeSearchInnerModel(currFilter);
      } else if (currFilter.type === 'geo') {
        if (currFilter.isDisabled) {
          console.warn('No geo selected. Ignoring');
          return null;
        } else {
          const searchQuery = {
            should: [],
            minimum_should_match: 0
          };

          _.each(currFilter.selectedGeoGroupFilter, (v, k) => {
            if (v) {
              const currTerm: { [key: string]: any } = {};
              currTerm[k + '.lowercaseSearchField'] = v;
              searchQuery.should.push({
                term: currTerm
              });

              searchQuery.minimum_should_match++;
            }
          });

          console.log(JSON.stringify(searchQuery));
          return searchQuery;
        }
      } else if (currFilter.type === 'datestring') {
        if (!currFilter.currentCondition || !currFilter.currentCondition.filter_value) {
          return null;
        }

        if (!currFilter.singleDate && !(currFilter.bounds && currFilter.bounds.lb && currFilter.bounds.ub)) {
          return null;
        }

        const singleDate = this.formatDate(currFilter.singleDate);
        let rangeLb: any;
        let rangeUb: any;

        if (!singleDate) {
          rangeLb = this.formatDate(currFilter.bounds.lb);
          rangeUb = this.formatDate(currFilter.bounds.ub);
        }

        let rangePredicate: any;

        switch (currFilter.currentCondition.filter_value) {
          case 'LT':
            rangePredicate = this.getRangePredicate(currFilterId, undefined, singleDate, undefined, undefined, true);
            break;
          case 'GT':
            rangePredicate = this.getRangePredicate(currFilterId, singleDate, undefined, undefined, undefined, true);
            break;
          case 'LTE':
            rangePredicate = this.getRangePredicate(currFilterId, undefined, undefined, undefined, singleDate, true);
            break;
          case 'GTE':
            rangePredicate = this.getRangePredicate(currFilterId, undefined, undefined, singleDate, undefined, true);
            break;
          case 'EQ':
            return {
              must: {
                term: ElasticService.getTermPredicate(currFilterId, singleDate)
              }
            };
          default:
            rangePredicate = this.getRangePredicate(currFilterId, undefined, undefined, rangeLb, rangeUb, true);
            break;
        }

        return {
          must: {
            range: rangePredicate
          }
        };
      }
    });

    const unusedFilters = lodash.filter(unusedFiltersComparator, (filter, idx) => lodash.isNil(filterSearchESArray[idx]));

    if (unusedFilters.length) {
      // $rootScope.$broadcast("elastic_q_clear_unused_filters", unusedFilters);
      // Broadcasting in Angular may be handled differently based on your application structure
      // You might need to use Angular services or events for this purpose
    }

    const filteredFilterSearchESArray = _.filter(filterSearchESArray, (obj) => obj);

    if (!filteredFilterSearchESArray.length) {
      return null;
    } else {
      const boolMustArray = _.map(filteredFilterSearchESArray, (obj) => ({bool: obj}));

      return {
        bool: {
          must: boolMustArray
        }
      };
    }
  }

  getAdvancedSearchModel(advancedSearch: any): any {
    if (!advancedSearch.searchModel || !advancedSearch.searchModel.length) {
      return null;
    }

    const advancedSearchESArray = _.map(advancedSearch.searchModel, (searchCriteria: any) => {
      const currFilterId = searchCriteria.col_id;

      if (searchCriteria.search_type === 1) {
        // String CONTAINS/NOT_CONTAINS
        if (!searchCriteria.strExpr) {
          return null;
        }
        if (searchCriteria.currentCondition.value === 'CONTAINS') {
          return ElasticService.getWildcardExpression('must', currFilterId, searchCriteria.strExpr);
        } else {
          const expr = ElasticService.getWildcardExpression('should', currFilterId, searchCriteria.strExpr);

          if (!expr) {
            return null;
          } else {
            return {
              must_not: [{
                bool: expr
              }]
            };
          }
        }
      } else if (searchCriteria.search_type === 2 || searchCriteria.search_type === 3) {
        if (!searchCriteria.currentCondition) {
          return null;
        }

        // Numeric Comparisons
        let rangePredicate: any;

        switch (searchCriteria.currentCondition.value) {
          case 'BETWEEN':
            if (!searchCriteria.bounds || isNaN(searchCriteria.bounds.lb) || searchCriteria.bounds.lb === ''
              || isNaN(searchCriteria.bounds.ub) || searchCriteria.bounds.ub === '') {
              return null;
            }

            return {
              must: {
                range: this.getRangePredicate(currFilterId, undefined, undefined, searchCriteria.bounds.lb, searchCriteria.bounds.ub)
              }
            };

          case 'LT':
            // tslint:disable-next-line:triple-equals
            if (searchCriteria.singleBound === '' || searchCriteria.singleBound == undefined) {
              return null;
            }
            rangePredicate = this.getRangePredicate(currFilterId, undefined, searchCriteria.singleBound, undefined, undefined);
            break;

          case 'GT':
            rangePredicate = this.getRangePredicate(currFilterId, searchCriteria.singleBound, undefined, undefined, undefined);
            break;

          case 'LTE':
            rangePredicate = this.getRangePredicate(currFilterId, undefined, undefined, undefined, searchCriteria.singleBound);
            break;

          case 'GTE':
            rangePredicate = this.getRangePredicate(currFilterId, undefined, undefined, searchCriteria.singleBound, undefined);
            break;

          case 'EQ':
            return {
              must: {
                term: ElasticService.getTermPredicate(currFilterId, searchCriteria.singleBound)
              }
            };

          default:
            return null;
        }

        return {
          must: {
            range: rangePredicate
          }
        };
      } else if (searchCriteria.search_type === 4) {
        if (!searchCriteria.currentCondition || !searchCriteria.currentCondition.value) {
          return null;
        }

        if ((['GT', 'GTE', 'LT', 'LTE', 'EQ', 'BETWEEN'].indexOf(searchCriteria.currentCondition.value) > -1)
          && (!searchCriteria.singleDate)
          && (!searchCriteria.bounds || !(searchCriteria.bounds.lb && searchCriteria.bounds.ub))
        ) {
          return null;
        }

        const singleDate = this.formatDate(searchCriteria.singleDate);
        let rangeLb: any;
        let rangeUb: any;

        if (searchCriteria.bounds && ((searchCriteria.bounds.lb != null) || (searchCriteria.bounds.ub != null))) {
          rangeLb = this.formatDate(searchCriteria.bounds.lb);
          rangeUb = this.formatDate(searchCriteria.bounds.ub);
        }

        let rangePredicate: any;

        switch (searchCriteria.currentCondition.value) {
          case 'LT':
            rangePredicate = this.getRangePredicate(currFilterId, undefined, singleDate, undefined, undefined, true);
            break;

          case 'GT':
            rangePredicate = this.getRangePredicate(currFilterId, singleDate, undefined, undefined, undefined, true);
            break;

          case 'LTE':
            rangePredicate = this.getRangePredicate(currFilterId, undefined, undefined, undefined, singleDate, true);
            break;

          case 'GTE':
            rangePredicate = this.getRangePredicate(currFilterId, undefined, undefined, singleDate, undefined, true);
            break;

          case 'EQ':
            return {
              must: {
                term: ElasticService.getTermPredicate(currFilterId, singleDate)
              }
            };

          default:
            rangePredicate = this.getRangePredicate(currFilterId, undefined, undefined, rangeLb, rangeUb, true);
            break;
        }

        return {
          must: {
            range: rangePredicate
          }
        };
      } else if (searchCriteria.search_type == 5) {
        // tslint:disable-next-line:triple-equals
        if (searchCriteria.boolValue == undefined) {
          return null;
        }
        const boolVal = searchCriteria.boolValue ? 1 : 0;
        return {
          must: {
            term: ElasticService.getTermPredicate(currFilterId, boolVal)
          }
        };
      } else if (searchCriteria.search_type === 7) {
        if (searchCriteria.boolValue == undefined) {
          return null;
        }

        if (searchCriteria.boolValue) {
          return {
            must: {
              exists: {
                field: currFilterId
              }
            }
          };
        } else {
          return {
            must_not: {
              exists: {
                field: currFilterId
              }
            }
          };
        }
      }
    });

    const filteredAdvancedSearchESArray = _.filter(advancedSearchESArray, (obj) => obj);

    if (!filteredAdvancedSearchESArray.length) {
      return null;
    } else {
      let advancedSearchESModel: any;
      const advancedSearchInnerPredicate = _.map(filteredAdvancedSearchESArray, (obj) => ({bool: obj}));

      if (advancedSearch.operator === 'AND') {
        advancedSearchESModel = {
          bool: {
            must: advancedSearchInnerPredicate
          }
        };
      } else {
        advancedSearchESModel = {
          bool: {
            should: advancedSearchInnerPredicate,
            minimum_should_match: 1
          }
        };
      }

      return advancedSearchESModel;
    }
  }

  getSortModel(sortModel: any): any[] {
    const sortESArray = _.map(sortModel.sort_order, (currSortCol: any) => {
      const currFilterId = currSortCol.col_id;

      // tslint:disable-next-line:triple-equals
      if (!sortModel.sort_model.hasOwnProperty(currFilterId) || sortModel.sort_model[currFilterId].sort_order == 'UNSORTED') {
        return null;
      }

      const currSortModelObj: any = {};
      // tslint:disable-next-line:triple-equals
      const sortColKey = ((currSortCol.col_type == 'string') || (currSortCol.col_type == 'geo'))
        ? `${currFilterId}.keyword`
        : currFilterId;

      currSortModelObj[sortColKey] = {
        order: sortModel.sort_model[currFilterId].sort_order.toLowerCase(),
      };

      return currSortModelObj;
    });

    const filteredSortESArray = _.filter(sortESArray, (obj) => obj);

    if (!filteredSortESArray.length) {
      return [];
    }

    return filteredSortESArray;
  }

  getQuickColFiltersESModel(quickColFiltersModel: any): any {
    const quickColESArray = _.map(quickColFiltersModel, (searchObj: any, currFilterId: string) => {
      if (searchObj.type === 'term') {
        // If string type we need to search on keyword field
        if (typeof searchObj.data === 'string') {
          currFilterId += '.keyword';
        }

        return {
          must: {
            term: ElasticService.getTermPredicate(currFilterId, searchObj.data),
          },
        };
      } else if (searchObj.type === 'wildcard') {
        return ElasticService.getWildcardExpression('must', currFilterId, searchObj.data);
      }

      return null;
    });

    if (!quickColESArray.length) {
      return null;
    } else {
      const filteredQuickColESArray = _.chain(quickColESArray)
        .without(null)
        .map((obj) => ({
          bool: obj,
        }))
        .value();

      return {
        bool: {
          must: filteredQuickColESArray,
        },
      };
    }
  }


  generateMasterSearchModelFromRaw(criteriaRaw: any): any {
    let filterSearchModel: any = {};
    let advancedSearchModel: any = {};
    let quickColFiltersESModel: any = {};
    let globalQuickSearchModel: any = {};
    let sortModel: any[] = [];
    const searchSize: number = criteriaRaw.size === undefined ? 10 : criteriaRaw.size;
    const searchFrom: number = criteriaRaw.from === undefined ? 0 : criteriaRaw.from;

    // Build elastic-search model for filters
    if (criteriaRaw.filters && Object.keys(criteriaRaw.filters).length) {
      filterSearchModel = this.getFilterSearchModel(criteriaRaw.filters);
    }

    // Build elastic-search model for advanced search
    if (criteriaRaw.searchModel && Object.keys(criteriaRaw.searchModel).length) {
      advancedSearchModel = this.getAdvancedSearchModel(criteriaRaw.searchModel);
    }

    // Elastic-search model for quick column filters
    if (criteriaRaw.quickColFiltersModel && Object.keys(criteriaRaw.quickColFiltersModel).length) {
      quickColFiltersESModel = this.getQuickColFiltersESModel(criteriaRaw.quickColFiltersModel);
    }

    // Build elastic-search model for sorting
    if (criteriaRaw.sortModel && Object.keys(criteriaRaw.sortModel).length) {
      sortModel = this.getSortModel(criteriaRaw.sortModel);
    }

    // Global Quicksearch Model
    if (criteriaRaw.globalQuickSearchStr.trim().length) {
      globalQuickSearchModel = {};
    }

    const finalSearchMustArray = [];
    const finalSearchQueryObj = {
      bool: undefined
    };
    const finalSearchModel = {
      query: undefined,
      sort: undefined,
      size: undefined,
      from: undefined
    };

    if (filterSearchModel && Object.keys(filterSearchModel).length) {
      finalSearchMustArray.push(filterSearchModel);
    }

    if (advancedSearchModel && Object.keys(advancedSearchModel).length) {
      finalSearchMustArray.push(advancedSearchModel);
    }

    if (quickColFiltersESModel && Object.keys(quickColFiltersESModel).length) {
      finalSearchMustArray.push(quickColFiltersESModel);
    }

    if (finalSearchMustArray && finalSearchMustArray.length) {
      finalSearchQueryObj.bool = {
        must: finalSearchMustArray,
      };
      finalSearchModel.query = finalSearchQueryObj;
    }

    if (globalQuickSearchModel && globalQuickSearchModel.length) {
      if (!finalSearchModel.query) {
        // If not create first
        finalSearchModel.query = {
          bool: {},
        };
      }

      if (!finalSearchModel.query.bool.should || !finalSearchModel.query.bool.should.length) {
        finalSearchModel.query.bool.should = [];
      }

      finalSearchModel.query.bool.should = finalSearchModel.query.bool.should.concat(globalQuickSearchModel);
      finalSearchModel.query.bool.minimum_should_match = 1;
    }

    // Default sort to main
    finalSearchModel.sort = {};

    if (sortModel.length) {
      finalSearchModel.sort = sortModel.concat(finalSearchModel.sort);
    }

    finalSearchModel.size = searchSize;
    finalSearchModel.from = searchFrom;

    return finalSearchModel;
  }


  generateSearchBody(params: any): any {
    const iCriteriaRaw = {
      filters: params && params.filters ? params.filters : this.criteriaRaw.filters,
      searchModel: params && params.searchModel ? params.searchModel : this.criteriaRaw.searchModel,
      quickColFiltersModel: params && params.quickColFiltersModel ? params.quickColFiltersModel : this.criteriaRaw.quickColFiltersModel,
      sortModel: params && params.sortModel ? params.sortModel : this.criteriaRaw.sortModel,
      globalQuickSearchStr: params && params.globalQuickSearch !== undefined ?
        params.globalQuickSearch : this.criteriaRaw.globalQuickSearchStr,
      valIDsUniverse: params && params.valIDsUniverse && params.valIDsUniverse.length ?
        params.valIDsUniverse : this.criteriaRaw.valIDsUniverse,
      size: params && params.size !== undefined ? params.size : this.criteriaRaw.size,
      from: params && params.from !== undefined ? params.from : 0,
    };

    /*IS THIS CORRECT? RITESH*/
    this.criteriaRaw = iCriteriaRaw;

    const masterDSearchBody = this.generateMasterSearchModelFromRaw(iCriteriaRaw);

    return {
      masterDSearchBody,
    };
  }


  getESBody(params: any): Promise<any> {
    return new Promise((resolve) => {
      if (this.timeoutPromise) {
        clearTimeout(this.timeoutPromise);
      }

      this.timeoutPromise = setTimeout(() => {
        const esBody = this.generateSearchBody(params);
        return resolve(esBody);
      }, this.ES_QUERY_DELAY);
      return this.timeoutPromise;
    });
  }

  filteredESGenerateFn(checkedRowsModel: any): any {
    const esBody = this.generateSearchBody({});

    if (!checkedRowsModel || (!Object.keys(checkedRowsModel).length)) {
      return esBody;
    }

    // tslint:disable-next-line:triple-equals
    if (checkedRowsModel.selectionState == this.ENUMS.INCLUSION_SELECTED.ALL) {
      return esBody;
    }

    switch (checkedRowsModel.selectionState) {
      case this.ENUMS.INCLUSION_SELECTED.ALL:
        break;
      case this.ENUMS.INCLUSION_SELECTED.NONE:
        // Impossible query
        if (!esBody.query) {
          esBody.query = {
            bool: {}
          };
        }
        if (!esBody.query.bool.must) {
          esBody.query.bool.must = [];
        }
        esBody.query.bool.must.push({
          bool: {
            must: [{
              term: {
                TargetCountry: "IMPOSSIBLESEARCHCOUNTRY"
              }
            }]
          }
        });
        break;
      case this.ENUMS.INCLUSION_SELECTED.SOME:
        if (!esBody.query) {
          esBody.query = {
            bool: {}
          };
        }
        const rowConditions = _.chain(checkedRowsModel.affectedRows)
          .reduce((acc, itemKey) => {
            const itemKeyArr = itemKey.split("-");
            // tslint:disable-next-line:one-variable-per-declaration
            const itemId = itemKeyArr[0],
              domainId = itemKeyArr[1];
            if (itemId.toString().length && domainId.toString().length) {
              if (!acc.hasOwnProperty(domainId)) {
                acc[domainId] = [];
              }
              acc[domainId].push({
                term: {
                  ItemId: itemId
                }
              });
            }
            return acc;
          }, {})
          .reduce((acc, innerShould, domainId) => {
            // DomainID and one of ItemID
            const innerMust = {
              term: {
                DomainId: domainId
              }
            };
            acc.push({
              bool: {
                must: innerMust,
                should: innerShould,
                minimum_should_match: 1
              }
            });
            return acc;
          }, []).value();
        if (checkedRowsModel.selectAllState) {
          esBody.query.bool.must_not = rowConditions;
        } else {
          if (!esBody.query.bool.must) {
            esBody.query.bool.must = [];
          }
          esBody.query.bool.must.push({
            bool: {
              should: rowConditions,
              minimum_should_match: 1
            }
          });
        }
        break;
      default:
        console.error("Invalid Selection State reached", checkedRowsModel.selectionState);
        break;
    }
    console.log("Search Body", esBody);
    return esBody;
  }

  getFilteredESBody(checkedRowsModel: any, skipTimeout?: boolean): Promise<any> {
    return new Promise((resolve) => {
      if (skipTimeout) {
        setTimeout(() => {
          resolve(this.filteredESGenerateFn(checkedRowsModel));
        }, 0);
      } else {
        if (this.timeoutPromise) {
          clearTimeout(this.timeoutPromise);
        }

        this.timeoutPromise = setTimeout(() => {
          resolve(this.filteredESGenerateFn(checkedRowsModel));
        }, this.ES_QUERY_DELAY);
      }
    });
  }

}
