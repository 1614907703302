<div class="card-main-wrapper mb-4">
  <div>

    <div class="card card-custom">
      <div class="card-header" style="padding: 0 15px 0 0.25rem;">
        <div class="card-title">
        </div>
      </div>

      <div class="card-body">
        <div class="ml-5 p-2">
          <form [formGroup]="fg" *ngIf="fg" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-md-3" *ngIf="isGroupPresent('salesPercentileBucket')">
                <h3 class="fg-title">Sales Percentile LTM</h3>
                <div formArrayName="salesPercentileBucket">
                  <div *ngFor="let checkbox of getFormArray('salesPercentileBucket').controls; let i = index">
                    <p *ngIf="lp['salesPercentileBucket'][i].value=='*'">
                    </p>
                    <label>
                      <input type="checkbox" [formControlName]="i"
                             (change)="onCheckboxChange($event, 'salesPercentileBucket', i)">
                      {{ lp['salesPercentileBucket'][i].label }}
                    </label>
                    <p *ngIf="lp['salesPercentileBucket'][i].value=='*'">
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-md-2" *ngIf="isGroupPresent('timeline')">
                <h3 class="fg-title">Timeline</h3>
                <div>
                  <label *ngFor="let opt of lp['timeline']" class="d-block">
                    <input type="radio" [formControlName]="'timeline'" [value]="opt.value">
                    {{ opt.label }}
                  </label>
                </div>
              </div>

              <div class="col-md-2" *ngIf="isGroupPresent('firmCategory')">
                <h3 class="fg-title">Firm Category</h3>
                <div formArrayName="firmCategory">
                  <div *ngFor="let checkbox of getFormArray('firmCategory').controls; let i = index">
                    <p *ngIf="lp['firmCategory'][i].value=='*'">
                    </p>
                    <label>
                      <input type="checkbox" [formControlName]="i"
                             (change)="onCheckboxChange($event, 'firmCategory', i)">
                      {{ lp['firmCategory'][i].label }}
                    </label>
                    <p *ngIf="lp['firmCategory'][i].value=='*'">
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div>
                  <button class="btn btn-primary btn-chart-modal-ctrl" type="submit">Submit</button>
                </div>
                <div class="mt-4">
                  <button type="button" class="btn btn-outline-secondary btn-chart-modal-ctrl" style="padding: 0"
                          (click)="isShowMoreCollapsed=!isShowMoreCollapsed"
                          [attr.aria-expanded]="!isShowMoreCollapsed"
                          aria-controls="collapseIframe">
                    <span *ngIf="!isShowMoreCollapsed">Show Less</span>
                    <span *ngIf="isShowMoreCollapsed">Show More</span>
                  </button>
                </div>
              </div>
            </div>

            <div class="row mt-3" #collapse="ngbCollapse" [(ngbCollapse)]="isShowMoreCollapsed">
              <div class="col-md-6" *ngIf="isGroupPresent('firmType')">
                <h3 class="fg-title">Account Type</h3>
                <div formArrayName="firmType">
                  <div class="row">
                    <div class="col-md-6">
                      <div *ngFor="let checkbox of getFormArray('firmType').controls.slice(0, 8); let i = index">
                        <p *ngIf="lp['firmType'][i].value=='*'">
                        </p>
                        <label>
                          <input type="checkbox" [formControlName]="i"
                                 (change)="onCheckboxChange($event, 'firmType', i)">
                          {{ lp['firmType'][i].label }}
                        </label>
                        <p *ngIf="lp['firmType'][i].value=='*'">
                        </p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div *ngFor="let checkbox of getFormArray('firmType').controls.slice(8); let i = index">
                        <label>
                          <input type="checkbox" [formControlName]="i+8"
                                 (change)="onCheckboxChange($event, 'firmType', i+8)">
                          {{ lp['firmType'][i + 8].label }}
                        </label>
                      </div>
                    </div>

                  </div>

                </div>
              </div>

              <div class="col-md-2" *ngIf="isGroupPresent('period')">
                <h3 class="fg-title">Period</h3>
                <div formArrayName="period">
                  <div *ngFor="let checkbox of getFormArray('period').controls; let i = index">
                    <p *ngIf="lp['period'][i].value=='*'">
                    </p>
                    <label>
                      <input type="checkbox" [formControlName]="i"
                             (change)="onCheckboxChange($event, 'period', i)">
                      {{ lp['period'][i].label }}
                    </label>
                    <p *ngIf="lp['period'][i].value=='*'">
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-md-2" *ngIf="isGroupPresent('productGroup')">
                <h3 class="fg-title">Product Group</h3>
                <div formArrayName="productGroup">
                  <div *ngFor="let checkbox of getFormArray('productGroup').controls; let i = index">
                    <p *ngIf="lp['productGroup'][i].value=='*'">
                    </p>
                    <label>
                      <input type="checkbox" [formControlName]="i"
                             (change)="onCheckboxChange($event, 'productGroup', i)">
                      {{ lp['productGroup'][i].label }}
                    </label>
                    <p *ngIf="lp['productGroup'][i].value=='*'">
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-md-2" *ngIf="isGroupPresent('portal')">
                <h3 class="fg-title">Portal</h3>
                <div formArrayName="portal">
                  <div *ngFor="let checkbox of getFormArray('portal').controls; let i = index">
                    <p *ngIf="lp['portal'][i].value=='*'">
                    </p>
                    <label>
                      <input type="checkbox" [formControlName]="i"
                             (change)="onCheckboxChange($event, 'portal', i)">
                      {{ lp['portal'][i].label }}
                    </label>
                    <p *ngIf="lp['portal'][i].value=='*'">
                    </p>
                  </div>
                </div>
              </div>
            </div>


          </form>

        </div>
      </div>
    </div>
  </div>
</div>
