import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AppDataFetchService} from "../../services/app-data-fetch.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {HttpClient} from "@angular/common/http";
import * as _ from 'lodash';
import * as Highcharts from 'highcharts';
import {faChevronCircleDown, faChevronCircleUp} from "@fortawesome/free-solid-svg-icons";

import {KpiChartAndDataComponentCfg} from "../kpi_charts_and_data/kpi-chart-and-data-component-cfg";
import {AppsettingsService} from "../../services/appsettings.service";
import {CustomServerDataSource} from "../custom-server-data-source";


@Component({
  selector: 'app-kpi-firm-account-profile',
  templateUrl: './kpi-firm-account-profile.component.html',
  styleUrls: ['./kpi-firm-account-profile.component.less']
})
export class KpiFirmAccountProfileComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;

  faChevronCircleUp = faChevronCircleUp;
  faChevronCircleDown = faChevronCircleDown;

  firmName;
  pageOpt = {
    dataKey: 'result',
    pagerPageKey: 'currentPageNumber',
    pagerLimitKey: 'totalItemsCountPerPage',
    totalKey: 'totalItemsCount'
  };

  public formAccProfileDisplayedData;
  public spendByProductChartObj;
  public spendByProductVerticalChartObj;
  public spendByAllProductLtmObj;

  public aggDataObj: CustomServerDataSource;
  public productDataObj: CustomServerDataSource;
  public salesDetailsDataObj: CustomServerDataSource;
  public profileActivityDataObj: CustomServerDataSource;

  isFormAccProfileCollapsed = false;
  isAggDataCollapsed = true;
  isProductDataCollapsed = true;
  isSalesDetailsCollapsed = true;
  isActivityCollapsed = true;

  constructor(private  route: ActivatedRoute,
              private appDataFetchSvc: AppDataFetchService,
              private modalService: NgbModal,
              private http: HttpClient,
              public kpiChartDataComponentCfg: KpiChartAndDataComponentCfg,
              public appSettings: AppsettingsService) {
  }

  ngOnInit(): void {
    this.firmName = this.route.snapshot.paramMap.get('firm');
    this.fetchFirmAccountProfile().then((firmAccProfileData) => {
      this.refreshFirmAccountProfile(firmAccProfileData);
      this.refreshGrid();
    });
  }

  fetchFirmAccountProfile(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.appDataFetchSvc.fetchFirmAccountProfile({
        firm: this.firmName
      }).subscribe(
        (data: any) => {
          resolve(data);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  refreshFirmAccountProfile(firmAccProfileData): void {
    this.formAccProfileDisplayedData = firmAccProfileData.displayedData;
    this.spendByProductChartObj = this.kpiChartDataComponentCfg.spendByProductChartObj(firmAccProfileData.spendByProduct);
    this.spendByProductVerticalChartObj =
      this.kpiChartDataComponentCfg.spendByProductChartObj(firmAccProfileData.spendbyProductVertical, true);
    this.spendByAllProductLtmObj = this.kpiChartDataComponentCfg.ltmSpendAllChart(firmAccProfileData.ltmSpendAllProducts);

  }

  refreshGrid(): void {
    this.aggDataObj = new CustomServerDataSource(this.http,
      {
        firm: this.firmName
      },
      _.extend({endPoint: this.appDataFetchSvc.fetchFirmAccountProfileAggData()}, this.pageOpt)
    );

    this.productDataObj = new CustomServerDataSource(this.http,
      {
        firm: this.firmName
      },
      _.extend({endPoint: this.appDataFetchSvc.fetchFirmAccountProfileProductDatagrid()}, this.pageOpt)
    );


    this.salesDetailsDataObj = new CustomServerDataSource(this.http,
      {
        firm: this.firmName
      },
      _.extend({endPoint: this.appDataFetchSvc.fetchFirmAccountProfileSalesDetails()}, this.pageOpt)
    );

    this.profileActivityDataObj = new CustomServerDataSource(this.http,
      {
        firm: this.firmName
      },
      _.extend({endPoint: this.appDataFetchSvc.fetchFirmAccountProfileActivity()}, this.pageOpt)
    );
  }

  togglePane(ref: any): void {
    if (ref === 'fromaccountprofile') {
      this.isFormAccProfileCollapsed = !this.isFormAccProfileCollapsed;
    } else if (ref === 'aggdata') {
      this.isAggDataCollapsed = !this.isAggDataCollapsed;
    } else if (ref === 'productdata') {
      this.isProductDataCollapsed = !this.isProductDataCollapsed;
    } else if (ref === 'salesdetails') {
      this.isSalesDetailsCollapsed = !this.isSalesDetailsCollapsed;
    } else if (ref === 'activity') {
      this.isActivityCollapsed = !this.isActivityCollapsed;
    }
  }
}
