<div class="row">
  <div class="col-md-12">
    <div class="panel panel-default">
      <div class="panel-heading"><b>Add/Arrange Data Columns</b></div>
      <div class="panel-body">
        <div class="row">
          <div class="col-md-6">
            <p align="center" class="dashboard-subheader"><b>Available Data</b></p>

            <div class="form-group">
              <input type="text" [(ngModel)]="columnSearchText"
                     class="form-control" placeholder="Search available columns">
            </div>
            <div class="list-group dashboard-col-available" [dndDropzone]
                 (dndDrop)="onDropped($event, columns.availableCols)"
                 dndEffectAllowed="move">
              <a class="list-group-item screener-list-group-item border bg-light" dndPlaceholderRef></a>
              <a class="list-group-item screener-list-group-item dndLeft"
                 *ngFor="let col of filterAvailableColumns(columns.availableCols); let i = index"
                 dndEffectAllowed="move"
                 [dndDraggable]="col"
                 (dndMoved)="onDnbDragCb(col,columns.availableCols);"
                 [ngbTooltip]="col.col_tp"
                 [disableTooltip]="fieldDefinitionsToolTipEnabled">
                <div>
                  {{col.col_name}}
                </div>
              </a>
            </div>
          </div>
          <div class="col-md-6">
            <p align="center" class="dashboard-subheader"><b>Displayed Data</b></p>

            <div [dndDropzone] class="list-group dashboard-col-selected"
                 (dndDrop)="onDropped($event, columns.selectedCols)"
                 dndEffectAllowed="move">
              <a class="list-group-item screener-list-group-item border bg-light" dndPlaceholderRef></a>
              <a class="list-group-item screener-list-group-item dndRight"
                 *ngFor="let col of columns.selectedCols; let i = index"
                 dndEffectAllowed="move"
                 [dndDraggable]="col"
                 (dndMoved)="onDnbDragCb(col, columns.selectedCols);"
                 [dndDisableIf]="columns.selectedCols.length <= 1"
                 [ngbTooltip]="col.col_tp"
                 [disableTooltip]="fieldDefinitionsToolTipEnabled">
                <div>
                  <div class="row">
                    <div class="col-md-10">
                      {{col.col_name}}
                    </div>
                    <div class="col-md-2">
                      <span class="float-right">

                        <fa-icon [icon]="faSort" class="fa-2x mr-2" ></fa-icon>
                        <fa-icon [icon]="faTrash" class=" fa-2x cursor-pointer"
                                 (click)="removeColFromSelectedCols(i); $event.stopPropagation();"></fa-icon>
                      </span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
