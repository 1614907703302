import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {ModelUtilsService} from "../services/model-utils.service";
import {AppDataFetchService} from "../../../services/app-data-fetch.service";
import {EventEmitter, Output} from '@angular/core';
import {ScreenerCellFormatterPipe, ScreenerCellTypePipe} from "../services/screener-cell-formatter-pipe.pipe";

@Component({
  selector: 'app-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.less']
})
export class QuickSearchComponent implements OnInit {
  @Input() screenerDm: any;
  @Input() fieldDefinitionsToolTipEnabled: any;
  @Output() updateQuickSearchSelection = new EventEmitter<any>();
  faTrash = faTrash;

  filterLoading = false;

  activeFilterID: any = null;
  activeFilterDM: any = {};
  availableFilters: any[];
  appliedFilters: any[] = [];
  appliedFiltersDM: any = {};
  public staticFilterOptions = {};

  constructor(private modelUtils: ModelUtilsService,
              public  appDataFetchService: AppDataFetchService,
              private screenerCellTypePipe: ScreenerCellTypePipe,
              private screenerCellFormatterPipe: ScreenerCellFormatterPipe) {
  }

  ngOnInit(): void {
    this.initFiltersDm().then(r => {
    });
  }

  async initFiltersDm(): Promise<void> {
    this.availableFilters = this.screenerDm.availableFilters;
    this.appliedFilters = this.screenerDm.defaultFilters;
    this.staticFilterOptions = this.screenerDm.staticFilterOptions;
    this.appliedFiltersDM = await this.generateAppliedFiltersDM(this.appliedFilters, null);
    // Inited
    this.activeFilterID = this.appliedFilters[0].col_id;
    this.watchActiveFilterId(this.activeFilterID);
    return;
  }

  watchActiveFilterId(newValue): void {
    this.updateActiveFilterDM(newValue);
  }

  itemNotInSelectedList(availableItemList: any[], selectedItemList: any[], key: string): any[] {
    return availableItemList.filter(avlItem =>
      selectedItemList.findIndex(selItem => selItem[key] === avlItem[key]) === -1
    );
  }

  addFilterToAppliedFilters(o): void {
    this.generateFilterModelFromFilter(o, null).then((currFilterDM) => {
      if (!this.appliedFiltersDM.hasOwnProperty(o.col_id)) {
        this.appliedFiltersDM[o.col_id] = currFilterDM;
      }

      if (!_.some(this.appliedFilters, (currFilter: any) => {
        return o.col_id === currFilter.col_id;
      })) {
        this.appliedFilters.push(o);
      }
      this.updateForFilterChange(null);
    });
  }

  setActiveFilterID(currentFilterID): void {
    if (currentFilterID === this.activeFilterID) {
      return;
    }
    this.activeFilterID = currentFilterID;
    this.watchActiveFilterId(this.activeFilterID);
  }

  removeFilterFromAppliedFilters(filterIdx: any): void {
    if (this.appliedFilters.length <= 1) {
      console.warn("Trying to remove last filter");
      return;
    }

    let nextActiveFilterID = "";
    const filterIDToDelete = this.appliedFilters[filterIdx].col_id;

    if (this.appliedFilters[filterIdx].col_id !== this.activeFilterID) {
      this.appliedFilters.splice(filterIdx, 1);
      delete this.appliedFiltersDM[filterIDToDelete];
      this.updateForFilterChange(null);
      return;
    }

    nextActiveFilterID = (filterIdx === 0) ?
      this.appliedFilters[filterIdx + 1].col_id :
      this.appliedFilters[filterIdx - 1].col_id;

    this.appliedFilters.splice(filterIdx, 1);
    delete this.appliedFiltersDM[filterIDToDelete];
    this.activeFilterID = nextActiveFilterID;
    this.watchActiveFilterId(this.activeFilterID);
    this.updateForFilterChange(null);

  }

  updateFilterCondition(activeDM: any, condition: any): void {
    activeDM.currentCondition = condition;

    if (activeDM.type === 'string') {
      if (this.activeFilterDM.filterStr) {
        this.applyStringFilter(this.activeFilterDM.filterStr);
      }
    } else if (activeDM.type === 'numericBounds') {
      if (condition === 'BETWEEN') {
        this.applyNumericBoundFilter(undefined, activeDM.filterBounds.lb, activeDM.filterBounds.ub);
      } else {
        this.applyNumericBoundFilter(activeDM.filterVal, undefined, undefined);
      }
    }
  }

  applyStringFilter(filterString: string | null, filterDM?: any): void {
    const currentFilterDM = filterDM ? filterDM : this.activeFilterDM;
    if (filterString !== null && filterString.trim() !== '') {
    }
    if (this.staticFilterOptions[currentFilterDM.col_id] && filterString === 'ALL') {
      filterString = '';
    }
    currentFilterDM.filterStr = filterString;
    this.updateForFilterChange(this.activeFilterDM);
  }

  applyNumericBoundFilter(singleVal: any, lb: any, ub: any): void {
    if (singleVal === undefined) {
      this.activeFilterDM.filterSingleBound = undefined;
      this.activeFilterDM.filterBounds.lb = lb;
      this.activeFilterDM.filterBounds.ub = ub;
    } else {
      this.activeFilterDM.filterSingleBound = singleVal;
      this.activeFilterDM.filterBounds = {
        lb,
        ub
      };
    }
    this.updateForFilterChange(this.activeFilterDM);
  }

  applyCustomDateFilter(dateStr: string | null, startDateStr: string | null, endDateStr: string | null, filterDM?: any): void {
    const currentDM = filterDM ? filterDM : this.activeFilterDM;
    if (dateStr !== null) {
      currentDM.singleDate = dateStr;
      currentDM.bounds = undefined;
      this.updateForFilterChange(this.activeFilterDM);
    } else {
      currentDM.singleDate = '';
      currentDM.bounds = {
        lb: startDateStr,
        ub: endDateStr
      };
      this.updateForFilterChange(this.activeFilterDM);
    }
  }

  updateFilterConditionAndCalculateFilterDateRange(activeFilterDM: any, currCondition: any): void {
    const earlierCondition = activeFilterDM.currentCondition;
    activeFilterDM.currentCondition = currCondition;

    if (currCondition.auto_calc) {
      const bounds = this.modelUtils.getDateBounds(currCondition.filter_value);
      activeFilterDM.bounds = bounds;
      activeFilterDM.searchStr = `${bounds.lb} TO ${bounds.ub}`;
      this.applyCustomDateFilter(null, activeFilterDM.bounds.lb, activeFilterDM.bounds.ub);
    } else {
      if (!earlierCondition) {
        return;
      }
      if (earlierCondition.auto_calc || (earlierCondition.filter_value === 'BETWEEN')) {
        if (currCondition.filter_value !== 'BETWEEN') {
          if (activeFilterDM.bounds && activeFilterDM.bounds.lb) {
            activeFilterDM.searchStr = activeFilterDM.bounds.lb;
          } else {
            activeFilterDM.searchStr = '';
          }
          this.applyCustomDateFilter(activeFilterDM.searchStr, null, null);
        } else {
          if (!(activeFilterDM.bounds && (activeFilterDM.bounds.lb || activeFilterDM.bounds.ub))) {
            activeFilterDM.bounds = {
              lb: '',
              ub: ''
            };
            activeFilterDM.searchStr = '';
          }
          activeFilterDM.filterBounds = activeFilterDM.bounds;
          this.applyCustomDateFilter(null, activeFilterDM.bounds.lb, activeFilterDM.bounds.ub);
        }
      } else {
        if (currCondition.filter_value !== 'BETWEEN') {
          this.applyCustomDateFilter(activeFilterDM.searchStr, null, null);
        } else {
          activeFilterDM.bounds = {
            lb: activeFilterDM.searchStr,
            ub: ''
          };
          activeFilterDM.filterBounds = activeFilterDM.bounds;
          this.applyCustomDateFilter(null, activeFilterDM.bounds.lb, activeFilterDM.bounds.ub);
        }
      }
    }
  }


  updateActiveFilterDM(currFilterID: string): void {
    if (this.appliedFiltersDM && !this.appliedFiltersDM[currFilterID]) {
      console.error("Code shouldn't reach here");
    }
    this.activeFilterDM = this.appliedFiltersDM[currFilterID];
  }

  getNumericChart(histData: any): any {
    const chartCols = ['distribution', ...histData];
    return {
      columns: [chartCols],
      names: {
        distribution: '# of records',
      },
      types: {
        distribution: 'area-spline',
      },
      colors: {
        distribution: '#0db9F0',
      },
    };
  }

  updateChartData(currFilterID: string, globalMinVal: number, globalMaxVal: number,
                  currentFilterType, currentFilterFormatter, currentFilterFractionSize): void {
    const localMinVal = this.activeFilterDM.localMin;
    const localMaxVal = this.activeFilterDM.localMax;
    const minVal = this.getOrDefault(localMinVal, globalMinVal);
    const maxVal = this.getOrDefault(localMaxVal, globalMaxVal);

    this.appDataFetchService.getFilterParameters({
      type: 'numeric',
      filterId: currFilterID,
      histSize: 12,
      maxLimit: maxVal,
      minLimit: minVal
    }).subscribe((filterParams) => {
      const {histData} = filterParams;
      this.activeFilterDM.chartData = this.getNumericChart(histData);
      this.activeFilterDM.min = minVal;
      this.activeFilterDM.max = maxVal;
      this.activeFilterDM.dataFloor = minVal;
      this.activeFilterDM.dataCeil = maxVal;
      this.activeFilterDM.sliderOpts = this.getSliderOptions(minVal, maxVal, globalMinVal, globalMaxVal,
        currentFilterType, currentFilterFormatter, currentFilterFractionSize);
      this.activeFilterDM.histMin = 0;
      this.activeFilterDM.histMax = 12 - 1;
      this.activeFilterDM.includeNull = (
        Math.floor(globalMinVal) === Math.floor(this.activeFilterDM.min) &&
        Math.ceil(globalMaxVal) === Math.ceil(this.activeFilterDM.max)
      );
      this.updateForFilterChange(this.activeFilterDM);
    });
  }

  getSliderOptions(floor: number, ceil: number, globalMinVal: number, globalMaxVal: number,
                   currentFilterType, currentFilterFormatter, currentFilterFractionSize): any {
    return {
      floor,
      ceil,
      userChangeEnd: () => this.handleUserChangeEnd(globalMinVal, globalMaxVal),
      translate: (value) => {
        const translatedVal = this.screenerCellTypePipe.transform(value, {
          type: currentFilterType,
          fractionSize: currentFilterFractionSize
        });
        return this.screenerCellFormatterPipe.transform(translatedVal, {
          formatter: currentFilterFormatter,
          fractionSize: currentFilterFractionSize,
        });
      },
      precision: 1,
      step: 0.1,
      enforceStep: false
    };
  }

  handleUserChangeEnd(globalMinVal: number, globalMaxVal: number): void {
    const unit = (this.activeFilterDM.sliderOpts.ceil - this.activeFilterDM.sliderOpts.floor) / 12;
    this.activeFilterDM.histMin = Math.floor((this.activeFilterDM.min - this.activeFilterDM.sliderOpts.floor) / unit);
    this.activeFilterDM.histMax = Math.ceil((this.activeFilterDM.max - this.activeFilterDM.sliderOpts.floor) / unit) - 1;
    this.activeFilterDM.includeNull = (
      Math.floor(globalMinVal) === this.activeFilterDM.min &&
      Math.ceil(globalMaxVal) === this.activeFilterDM.max
    );
    this.updateForFilterChange(this.activeFilterDM);
  }

  getOrDefault(value: number, defaultValue: number): number {
    return (!_.isNil(value) && !_.isNaN(value)) ? value : defaultValue;
  }

  getFreshFilterModelFromFilter(filterObj): Promise<any> {
    const currFilterID = filterObj.col_id;
    const currentFilterType = filterObj.col_type;
    const currentFilterName = filterObj.col_name;
    const currentFilterFormatter = filterObj.formatter;
    const currentFilterFractionSize = filterObj.fraction_size;
    let currentFilterModel = null;
    return new Promise((resolve) => {
      if (this.appliedFiltersDM && this.appliedFiltersDM.hasOwnProperty(filterObj.col_id)) {
        // Retrieve from prior model instead of calculating
        currentFilterModel = this.appliedFiltersDM[filterObj.col_id];
        resolve(currentFilterModel);
        return;
      }
      if (currentFilterType === "numeric") {
        this.appDataFetchService.getFilterParameters({
          type: 'numeric',
          filterId: currFilterID,
          histSize: 12
        }).subscribe((filterParams) => {
          const histData = filterParams.histData;
          const chartData = this.getNumericChart(histData);
          const globalMinVal = filterParams.minVal;
          const globalMaxVal = filterParams.maxVal;

          const localMinVal = this.activeFilterDM.localMin;
          const localMaxVal = this.activeFilterDM.localMax;
          const minVal = this.getOrDefault(localMinVal, globalMinVal);
          const maxVal = this.getOrDefault(localMaxVal, globalMaxVal);

          currentFilterModel = {
            id: currFilterID,
            type: 'numeric', // Assuming it's always numeric
            name: currentFilterName,
            globalMin: globalMinVal,
            globalMax: globalMaxVal,
            localMin: localMinVal,
            localMax: localMaxVal,
            min: Math.floor(minVal),
            max: Math.ceil(maxVal),
            dataFloor: minVal,
            dataCeil: maxVal,
            histMin: 0,
            histMax: 12 - 1,
            updChartData: () => this.updateChartData(currFilterID, globalMinVal, globalMaxVal
              , currentFilterType, currentFilterFormatter, currentFilterFractionSize),
            sliderOpts: this.getSliderOptions(minVal, maxVal, globalMinVal, globalMaxVal,
              currentFilterType, currentFilterFormatter, currentFilterFractionSize),
            chartData
          };
          resolve(currentFilterModel);
        }, () => {
          resolve({});
        });
        //
      } else {
        if (currentFilterType === "string") {
          const filterConditions = this.modelUtils.getFilterConditions("string");
          const currentCondition = filterConditions[0];
          currentFilterModel = {
            col_id: currFilterID,
            id: currFilterID,
            type: currentFilterType,
            allConditions: filterConditions,
            currentCondition,
            searchStr: (this.staticFilterOptions[currFilterID] ? "ALL" : ""),
            filterStr: ""
          };
        } else if (currentFilterType === 'numericBounds') {
          const filterConditions = this.modelUtils.getFilterConditions('numericBounds');
          const currentCondition = null;
          currentFilterModel = {
            col_id: currFilterID,
            id: currFilterID,
            type: currentFilterType,
            allConditions: filterConditions,
            currentCondition,
            searchVal: null,
            searchBounds: {
              lb: null,
              ub: null
            },
            filterVal: null,
            filterBounds: {
              lb: null,
              ub: null
            }
          };
        } else if (currentFilterType === 'bool') {
          currentFilterModel = {
            col_id: currFilterID,
            id: currFilterID,
            type: currentFilterType,
            filterVal: null
          };
        } else if (currentFilterType === 'datestring') {
          const filterConditions = this.modelUtils.getFilterConditions('datestring');
          const currentCondition = null;
          currentFilterModel = {
            col_id: currFilterID,
            id: currFilterID,
            type: currentFilterType,
            allConditions: filterConditions,
            currentCondition,
            searchVal: null,
            searchBounds: {
              lb: null,
              ub: null
            },
            filterVal: null,
            filterBounds: {
              lb: null,
              ub: null
            }
          };
        }
        resolve(currentFilterModel);
      }
    });

  }

  getUpdatedFilterModelFromFilter(currentFilter: any, filtersDM: any): Promise<any> {
    // retrived save case
    const currFilterID = currentFilter.col_id;
    const currentFilterType = currentFilter.col_type;
    const currentFilterName = currentFilter.col_name;
    const currentFilterFormatter = currentFilter.formatter;
    const currentFilterFractionSize = currentFilter.fraction_size;
    return new Promise((resolve, reject) => {
      if (currentFilterType === "numeric") {
        this.appDataFetchService.getFilterParameters({
          type: 'numeric',
          filterId: currFilterID,
          histSize: 12
        }).subscribe((filterParams) => {
          const globalMinVal = filterParams.minVal;
          const globalMaxVal = filterParams.maxVal;
          const histData = filterParams.histData;
          const isTouched = (filtersDM[currFilterID].isTouched && filtersDM[currFilterID].isTouched === true);
          const includeNull = filtersDM[currFilterID].includeNull && filtersDM[currFilterID].includeNull === true;
          const screenMin = filtersDM[currFilterID].min > globalMinVal
            ? filtersDM[currFilterID].min
            : globalMinVal;
          const screenmax = filtersDM[currFilterID].max < globalMaxVal
            ? filtersDM[currFilterID].max
            : globalMaxVal;
          const localMinVal = filtersDM[currFilterID].localMin != null ? parseFloat(filtersDM[currFilterID].localMin) : null;
          const localMaxVal = filtersDM[currFilterID].localMax != null ? parseFloat(filtersDM[currFilterID].localMax) : null;
          const minVal = (!_.isNil(localMinVal) && !_.isNaN(localMinVal)) ? localMinVal : globalMinVal;
          const maxVal = (!_.isNil(localMaxVal) && !_.isNaN(localMaxVal)) ? localMaxVal : globalMaxVal;
          const chartData = this.getNumericChart(histData);

          const bucketUnit = ((maxVal - minVal) / 12);

          const currentFilterModel = {
            id: currFilterID,
            type: currentFilterType,
            name: currentFilterName,
            globalMin: globalMinVal,
            globalMax: globalMaxVal,
            localMin: localMinVal,
            localMax: localMaxVal,
            min: Math.floor(screenMin),
            max: Math.ceil(screenmax),
            dataFloor: minVal,
            dataCeil: maxVal,
            histMin: Math.floor((screenMin - minVal) / bucketUnit),
            histMax: (Math.ceil((screenmax - minVal) / bucketUnit) - 1),
            isTouched,
            includeNull,
            updChartData: () => this.updateChartData(currFilterID, globalMinVal, globalMaxVal
              , currentFilterType, currentFilterFormatter, currentFilterFractionSize),
            sliderOpts: this.getSliderOptions(minVal, maxVal, globalMinVal, globalMaxVal,
              currentFilterType, currentFilterFormatter, currentFilterFractionSize),
            chartData
          };
          resolve(currentFilterModel);
        }, () => {
          resolve({});
        });
      } else {
        if (["string", "numericBounds", "bool", "datestring"].indexOf(currentFilterType) > -1) {
          resolve(filtersDM[currFilterID]);
        } else {
          resolve(filtersDM[currFilterID]);
        }
      }
    });
  }

  generateFilterModelFromFilter(currentFilter: any, filtersDM: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.filterLoading = true;
      if (!filtersDM) {
        // Generating fresh data model
        this.getFreshFilterModelFromFilter(currentFilter).then((fm) => {
          this.filterLoading = false;
          resolve(fm);
        });
      } else {
        // Recalculating data model
        this.getUpdatedFilterModelFromFilter(currentFilter, filtersDM).then((fm) => {
          this.filterLoading = false;
          resolve(fm);
        });
      }
    });
  }

  updateForFilterChange(updatedFilterDM): void {
    if (updatedFilterDM) {
      updatedFilterDM.isTouched = true;
    }
    this.updateTableDMForSearch();
  }

  updateTableDMForSearch(): void {
    const m = {
      activeFilterID: this.activeFilterID,
      activeFilterDM: this.activeFilterDM,
      availableFilters: this.availableFilters,
      appliedFilters: this.appliedFilters,
      appliedFiltersDM: this.appliedFiltersDM,
      filterLoading: this.filterLoading
    };
    this.updateQuickSearchSelection.emit(m);
  }

  async generateAppliedFiltersDM(appliedFilters: any[], filtersDM: any): Promise<any> {
    const appliedFiltersDM: any = {};

    for (const filter of appliedFilters) {
      try {
        appliedFiltersDM[filter.col_id] = await this.generateFilterModelFromFilter(filter, filtersDM);
      } catch (err) {
        console.error('Filter caused error while generating model:', err, filter, filtersDM);
        throw err;
      }
    }

    await new Promise((resolve) => setTimeout(resolve, 500)); // Simulating $timeout
    return appliedFiltersDM;
  }

  setFilterStateChanged(): void {

  }

  async applySavedScreenFilter(saved): Promise<void> {
    return new Promise<void>(async (resolve) => {
      const newFilters = saved;
      if (newFilters && newFilters.appliedFilters && newFilters.appliedFiltersDM) {
        this.appliedFiltersDM = await this.generateAppliedFiltersDM(newFilters.appliedFilters, newFilters.appliedFiltersDM);
        this.appliedFilters = newFilters.appliedFilters;
        this.activeFilterID = this.appliedFilters[0].col_id;
        this.activeFilterDM = this.appliedFiltersDM[this.activeFilterID];
        this.updateTableDMForSearch();
        resolve();
      } else {
        resolve();
      }
    });
  }
}
