import {FormGroup} from "@angular/forms";
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FormFormatterService {
  constructor() {
  }
   MustMatch = (controlName: string, matchingControlName: string) => (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({mustMatch: true});
    } else {
      matchingControl.setErrors(null);
    }
  }

  convertToSqlDate(dt: any): string {
    if (dt && dt.year && dt.month && dt.day) {
      return dt.year + '-' + dt.month + '-' + dt.day;
    }
    return null;
  }

  translateToErrorMessage(field: string, errors: any): string {
    if (errors.required) {
      return 'Field ' + field + ' is required. ';
    } else if (errors.email) {
      return 'Field ' + field + ' value is invalid. ';
    } else if (errors.pattern) {
      return 'Field ' + field + ' value is invalid. ';
    }
    return 'Field ' + field + ' value is invalid. ';
  }

  format(fg: FormGroup): { formatted: any; errorMsgHtml: string } {
    const formErrors = [];
    let errorMsgHtml: string = null;
    for (const k in fg.controls) {
      if (fg.controls[k].status === 'INVALID') {
        const eo = {
          field: k,
          errors: fg.controls[k].errors,
          msg: this.translateToErrorMessage(k, fg.controls[k].errors)
        };
        formErrors.push(eo);
      }
    }
    if (formErrors.length) {
      for (const k in formErrors) {
        if (formErrors[k]) {
          errorMsgHtml = `${formErrors[k].msg}`;
          return {errorMsgHtml, formatted: null};
        }
      }
    }

    const formatted: any = {};
    Object.keys(fg.controls).forEach(key => {
      if (key === 'startDate' || key === 'endDate') {
        formatted[key] = this.convertToSqlDate(fg.controls[key].value);
      } else {
        formatted[key] = fg.controls[key].value;
      }
    });
    return {errorMsgHtml, formatted};
  }
}
