import {Injectable} from '@angular/core';
import * as moment from "moment";
import * as _ from 'lodash';

// tslint:disable:triple-equals
@Injectable({
  providedIn: 'root'
})
export class ModelUtilsService {

  constructor() {
  }

  currencyFormatter(value, sym, fractionSize): any {
    return new Intl.NumberFormat('en-US', {minimumFractionDigits: fractionSize}).format(
      value
    );
  }

  getFilterConditions(rowType: string): any[] {
    if (rowType === 'string') {
      return [
        {
          filter_value: 'CONTAINS',
          filter_display: 'Contains'
        },
        {
          filter_value: 'NOT_CONTAINS',
          filter_display: 'Does not contain'
        }
      ];
    } else if (rowType === 'numericBounds') {
      return [
        {
          filter_value: 'BETWEEN',
          filter_display: 'Between'
        },
        {
          filter_value: 'EQ',
          filter_display: 'Equals'
        },
        {
          filter_value: 'GT',
          filter_display: 'Greater than'
        },
        {
          filter_value: 'LT',
          filter_display: 'Less than'
        },
        {
          filter_value: 'GTE',
          filter_display: 'Greater or equal'
        },
        {
          filter_value: 'LTE',
          filter_display: 'Less or equal'
        }
      ];
    } else if (rowType === 'datestring') {
      return [
        {
          auto_calc: true,
          filter_value: 'LAST30',
          filter_display: 'Last 30 days'
        },
        {
          auto_calc: true,
          filter_value: 'MONTH-12',
          filter_display: 'Last 12 months'
        },
        {
          auto_calc: true,
          filter_value: 'MONTH-24',
          filter_display: 'Last 24 months'
        },
        {
          filter_value: 'GT',
          filter_display: 'Greater than'
        },
        {
          filter_value: 'LT',
          filter_display: 'Less than'
        },
        {
          filter_value: 'EQ',
          filter_display: 'Equals'
        },
        {
          filter_value: 'BETWEEN',
          filter_display: 'Between'
        },
        {
          filter_value: 'GTE',
          filter_display: 'Greater or equal'
        },
        {
          filter_value: 'LTE',
          filter_display: 'Less or equal'
        }
      ];
    }

    return [];
  }

  getDateBounds(searchCondition): any {
    const dateFormat = 'MM/DD/YYYY';
    const bounds = {
      lb: "",
      ub: moment().format(dateFormat)
    };
    if (searchCondition === 'LAST7') {

      bounds.lb = moment().subtract(7, 'days').format(dateFormat);
    } else if (searchCondition === 'LAST30') {
      bounds.lb = moment().subtract(30, 'days').format(dateFormat);
    } else if (searchCondition === 'MONTH0') {

      bounds.lb = moment().startOf("month").format(dateFormat);
    } else if (searchCondition === 'MONTH-1') {
      bounds.ub = moment().subtract(1, "months").endOf("month").format(dateFormat);
      bounds.lb = moment().subtract(1, "months").startOf("month").format(dateFormat);
    } else if (searchCondition === 'YEAR-1') {
      bounds.ub = moment().subtract(1, "years").endOf("year").format(dateFormat);
      bounds.lb = moment().subtract(1, "years").startOf("year").format(dateFormat);
    } else if (searchCondition === 'MONTH-12') {
      bounds.lb = moment().subtract(12, "months").startOf("month").format(dateFormat);
    } else if (searchCondition === 'MONTH-24') {
      bounds.lb = moment().subtract(24, "months").startOf("month").format(dateFormat);
    }
    return bounds;
  }

  getColDefType(colType): any {
    if (colType == 'numeric') {
      return 'number';
    } else {
      return colType;
    }
  }

  searchAvailableForCol(col): any {
    if (col.col_type == 'string') {
      return true;
    }
    return col.enable_searching;
  }

  getAdvancedSearchModel(): any {
    return {
      quickSearchText: '',
      availableCols: [],
      selectedCols: [],
      appliedSearchesDM: [],
      activeSearchDM: {},
      activeSearchColIndex: -1,
      searchCriteria: 'AND',
      searchConfig: {},
      typeaheadSelectedCol: {},
      datePicker: {
        opened: false,
        dt: '',
        opts: {
          dateDisabled: false,
          formatYear: 'yy',
          maxDate: new Date(2020, 5, 22),
          minDate: new Date(),
          startingDay: 1
        }
      },
      getFlatCols: (colGroups: any[]) => {
        return _.chain(colGroups)
          .reduce((result, currGroup) => {
            result = result.concat(_.map(currGroup.cols, (currCol) => currCol));
            return result;
          }, [])
          .sortBy(['col_name'])
          .value();
      }
    };
  }

  getCurrentSTColDefs(parentScopeCols): any {
    return _.map(parentScopeCols, col => {
      const colDef = {
        field: col.col_id,
        displayName: col.col_name,
        type: this.getColDefType(col.col_type),
        enableFiltering: this.searchAvailableForCol(col),
        quickColSearchStr: "",
        minWidth: 200,
        cellTooltip: true,
        enableSorting: (col.hasOwnProperty('sortable') ? col.sortable : false),
        colAlign: col.hasOwnProperty('col_align') ? col.col_align : 'left',
        fractionSize: col.fraction_size,
        formatter: col.formatter,
        colTp: col.col_tp
      };
      if (["fairnessOpinionLink", "opinionLetterLink", "opinionSummaryLink",
        "pressReleaseLink", "otherReportLink"].indexOf(col.col_id) > -1) {
        colDef.type = "sec-link";
      }
      if (col.col_id == "target") {
        colDef.type = "target-link";
      }
      return colDef;
    });
  }

  getValuationIDsForCurrentDM(stDM): any {
    return _.chain(stDM)
      .map('valuationId')
      .uniq()
      .value();
  }

  getSTColDefForCol(col: any, params?: any): any {
    const tableBasedFiltering = this.searchAvailableForCol(col) && (col.col_type === 'string' || col.table_filtering);
    const staticFiltering = col.has_static_filter_opt ? col.has_static_filter_opt : false;

    const getColAlign = (colType: string, colAlign: string | undefined): string => {
      if (colAlign !== undefined && colAlign.length) {
        return colAlign;
      }
      switch (colType) {
        case 'numericBounds':
        case 'numeric':
          return 'right';
        case 'datestring':
          return 'center';
        default:
          return 'left';
      }
    };

    const colDef = {
      field: col.col_id,
      displayName: col.col_name,
      type: this.getColDefType(col.col_type),
      tableBasedFiltering,
      staticFiltering,
      quickColSearchStr: (staticFiltering ? 'ALL' : ''),
      minWidth: 200,
      cellTooltip: true,
      enableSorting: (col.hasOwnProperty('sortable') ? col.sortable : false),
      colAlign: getColAlign(col.col_type, col.col_align),
      fractionSize: col.fraction_size,
      formatter: col.formatter,
      prepadding: col.prepadding,
      padLength: col.pad_length,
      quickSearchType: undefined
    };
    if (col.col_type === 'string' || col.col_type === 'tree') {
      if (params && params.quickColFiltersModel && params.quickColFiltersModel.hasOwnProperty(col.col_id)) {
        if (typeof (params.quickColFiltersModel[col.col_id]) === 'string') {
          colDef.quickColSearchStr = params.quickColFiltersModel[col.col_id];
        } else {
          colDef.quickColSearchStr = params.quickColFiltersModel[col.col_id].data;
        }
      }
    }

    if (col.quick_search_type) {
      colDef.quickSearchType = col.quick_search_type;
    }
    return colDef;
  }

  updateSTColDefsForSorting(params, cb): any {
    const sortOrder = params.sortOrder;
    const sortModel = params.sortModel;
    const stColDefs = params.stColDefs;
    _.each(stColDefs, col => {
      col.sort = null;
    });
    if (!sortOrder || !sortModel) {
      if (cb) {
        return cb(stColDefs);
      } else {
        return stColDefs;
      }
    }
    if (sortOrder && sortOrder.length) {
      _.each(sortOrder, (col, idx) => {
        let sortDirection;
        let sortPriority;
        if (sortModel.hasOwnProperty(col.col_id) && (sortModel[col.col_id].sort_order != 'UNSORTED')) {
          sortDirection = sortModel[col.col_id].sort_order.toLowerCase();
          sortPriority = idx;
        }
        const currColIdx = _.findIndex(stColDefs, ['field', col.col_id]);
        if (currColIdx != -1) {
          stColDefs[currColIdx].sort = sortDirection;
        }
      });
    }

    if (cb) {
      return cb(stColDefs);
    } else {
      return stColDefs;
    }
  }

}
