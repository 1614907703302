import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class SuperusersDataFetchService {

  constructor(private http: HttpClient) {
  }

  baseUrl = '/api/superusers';

  // Admin Only User Management
  listUsers(): string {
    return this.baseUrl + '/users';
  }

  fetchUserAccessDetails(userBeingEdited: number, userSub: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/user?userbeingedited='
      + userBeingEdited + '&sub=' + userSub);
  }

  updateUserAccessDetails(userBeingEdited: number, postData: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/update-user-details?userbeingedited='
      + userBeingEdited, postData);
  }

  deleteUser(userBeingEdited: number, postData: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/delete-user?userbeingedited='
      + userBeingEdited, postData);
  }
}
