import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AppDataFetchService} from "../../services/app-data-fetch.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {HttpClient} from "@angular/common/http";
import * as _ from 'lodash';
import * as Highcharts from 'highcharts';
import {faChevronCircleDown, faChevronCircleUp} from "@fortawesome/free-solid-svg-icons";

import {KpiChartAndDataComponentCfg} from "../kpi_charts_and_data/kpi-chart-and-data-component-cfg";
import {AppsettingsService} from "../../services/appsettings.service";
import {CustomServerDataSource} from "../custom-server-data-source";


@Component({
  selector: 'app-kpi-product-details',
  templateUrl: './kpi-product-details.component.html',
  styleUrls: ['./kpi-product-details.component.less']
})
export class KpiProductDetailsComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;

  faChevronCircleUp = faChevronCircleUp;
  faChevronCircleDown = faChevronCircleDown;
  lpProductsType: any;

  productTypeIdSelected;
  pageOpt = {
    dataKey: 'result',
    pagerPageKey: 'currentPageNumber',
    pagerLimitKey: 'totalItemsCountPerPage',
    totalKey: 'totalItemsCount'
  };

  public productDetailsDisplayedData;
  public spendBySalesPercentileChartObj;
  public ltmSalesChartObj;

  public prodDetailsHistoricalData;
  public prodDetailsAccountData;
  public prodDetailsSaleDetailsData;
  public prodDetailsSalesPercentileData;
  public prodDetailsAccountTypeData;
  public prodDetailsAccountCategoryData;

  isProductDetailsProfileCollapsed = false;
  isProdDetailsHistoricalDataCollapsed = true;
  isProdDetailsAccountDataCollapsed = true;
  isProdDetailsSaleDetailsDataCollapsed = true;
  isProdDetailsSalesPercentileDataCollapsed = true;
  isProdDetailsAccountTypeDataCollapsed = true;
  isProdDetailsAccountCategoryDataCollapsed = true;

  constructor(private  route: ActivatedRoute,
              private appDataFetchSvc: AppDataFetchService,
              private modalService: NgbModal,
              private http: HttpClient,
              public kpiChartDataComponentCfg: KpiChartAndDataComponentCfg,
              public appSettings: AppsettingsService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.productTypeIdSelected = this.route.snapshot.paramMap.get('productTypeIdSelected');
    if (this.productTypeIdSelected) {
      this.productTypeIdSelected = this.productTypeIdSelected.toUpperCase();
    }
    this.appDataFetchSvc.lpProduct().subscribe(data => {
      this.lpProductsType = data;
    });
    this.fetchAndRefreshProductDetailsProfile();

  }

  updateProductTypeId(): void {
    this.router.navigate([`/kpi_product_details/${this.productTypeIdSelected}`]).then(() => {
      this.fetchAndRefreshProductDetailsProfile();
      window.location.reload();
    });
  }

  fetchAndRefreshProductDetailsProfile(): void {

      this.fetchProductDetailsProfile().then((prodProfileData) => {
        this.refreshProductDetailsProfile(prodProfileData);
        this.refreshGrid();
      });

  }

  fetchProductDetailsProfile(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.appDataFetchSvc.fetchProductDetailsProfile({
        productId: this.productTypeIdSelected
      }).subscribe(
        (data: any) => {
          resolve(data);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  refreshGrid(): void {
    this.prodDetailsHistoricalData = this.createCustomServerDataSource(this.appDataFetchSvc.fetchProductDetailsHistoricalData());
    this.prodDetailsAccountData = this.createCustomServerDataSource(this.appDataFetchSvc.fetchProductDetailsAccountData());
    this.prodDetailsSaleDetailsData = this.createCustomServerDataSource(this.appDataFetchSvc.fetchProductDetailsSaleDetailsData());
    this.prodDetailsSalesPercentileData = this.createCustomServerDataSource(this.appDataFetchSvc.fetchProductDetailsSalesPercentileData());
    this.prodDetailsAccountTypeData = this.createCustomServerDataSource(this.appDataFetchSvc.fetchProductDetailsAccountTypeData());
    this.prodDetailsAccountCategoryData = this.createCustomServerDataSource(this.appDataFetchSvc.fetchProductDetailsAccountCategoryData());
  }

  private createCustomServerDataSource(endpoint: string): CustomServerDataSource {
    return new CustomServerDataSource(this.http,
      {
        productId: this.productTypeIdSelected
      },
      _.extend({endPoint: endpoint}, this.pageOpt)
    );
  }

  refreshProductDetailsProfile(prodDetailsProfileData): void {
    this.productDetailsDisplayedData = prodDetailsProfileData.displayedData;
    this.spendBySalesPercentileChartObj =
      this.kpiChartDataComponentCfg.spendBySalesPercentileChartObj(prodDetailsProfileData.spendBySalesPercentile);
    this.ltmSalesChartObj = this.kpiChartDataComponentCfg.ltmSales(prodDetailsProfileData.ltmSales);
  }
}
