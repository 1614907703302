import {Component, OnInit} from '@angular/core';
import {AppDataFetchService} from "../../services/app-data-fetch.service";

import {faArrowUp, faArrowDown, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {ActivatedRoute, Router} from "@angular/router";
import {AppsettingsService} from "../../services/appsettings.service";
import * as moment from 'moment';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.less']
})
export class MainComponent implements OnInit {
  public kpiIdLabel = this.appSettings.settings.kpi;
  public tilesData: any;
  public asOfVal: any;
  public faArrowUp = faArrowUp;
  public faArrowDown = faArrowDown;
  public faArrowRight = faArrowRight;
  lpFirmCat = [{
    label: "All",
    val: "NONE",
  },
    {
      label: "Growth",
      val: "GROWTH",
    },
    {
      label: "Maintenance",
      val: "MAINTENANCE"
    }
  ];
  lpPercentile = [
    {
      label: "All",
      val: "NONE",
    },
    {
      label: "75-100",
      val: "75-100",
    },
    {
      label: "50-75",
      val: "50-75",
    },
    {
      label: "25-50",
      val: "25-50",
    },
    {
      label: "0-25",
      val: "0-25",
    },
  ];

  lpFirmCatSelected = this.lpFirmCat[0].val;
  lpPercentileSelected = this.lpPercentile[0].val;


  constructor(private appDataFetchService: AppDataFetchService,
              private  activatedRoute: ActivatedRoute,
              private router: Router,
              public appSettings: AppsettingsService) {

  }


  ngOnInit(): void {
    let defaultFirmCar;
    let defaultPctile;
    this.activatedRoute.queryParams.subscribe(params => {
      defaultFirmCar = params.firmCat;
      defaultPctile = params.pctile;
      if (defaultFirmCar && defaultPctile) {
        this.lpFirmCatSelected = defaultFirmCar;
        this.lpPercentileSelected = defaultPctile;
      }
      this.tiles();
    });

  }

  private tiles(): void {
    this.appDataFetchService.asOf(null).subscribe((asOfResult) => {
      this.asOfVal = moment.utc(asOfResult.asOf).format("MM-DD-yyyy");
    });
    this.appDataFetchService.tiles({
      firmCategory: this.lpFirmCatSelected,
      firmSalesPercentileBucket: this.lpPercentileSelected
    }).subscribe(
      (data: any) => {
        this.tilesData = data;
      }, error => {
      });
  }

  public refreshTiles(): void {
    this.tiles();
  }

  openKpiChartsAndData(kpiName: string): void {
    this.router.navigate([`/kpi_charts_and_data/${kpiName}`],
      {queryParams: {firmCat: this.lpFirmCatSelected, pctile: this.lpPercentileSelected}});
  }
}
