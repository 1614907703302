import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {AppDataFetchService} from "../../../../services/app-data-fetch.service";
import * as moment from "moment";
import * as _ from 'lodash';

@Component({
  selector: 'app-dlg-recent',
  templateUrl: './dlg-recent.component.html',
  styleUrls: ['./dlg-recent.component.less']
})
export class DlgRecentComponent implements OnInit {
  savedScreens: any;
  activeScreenIndex: number;

  constructor(private  appDataFetchService: AppDataFetchService,
              public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.loadRecentScreen();
  }

  loadRecentScreen(): void {
    this.appDataFetchService.listGrids().subscribe((result) => {
      this.savedScreens = _.map(result, (o) => {
        if (o.createdDatetime) {
          o.createdDatetime = moment.utc(o.createdDatetime).format("MM-DD-yyyy HH:mm");
        }
        if (o.updatedDatetime) {
          o.updatedDatetime = moment.utc(o.updatedDatetime).format("MM-DD-yyyy HH:mm");
        }
        return o;
      });
    });
  }

  setActiveScreenIndex(i: number): void {
    this.activeScreenIndex = i;
  }

  applySelectedScreen(o: any): void {
    this.activeModal.close(o);
  }

  deleteSelectedScreen(o: any): void {
    this.appDataFetchService.deleteScreen(o.gridId).subscribe(() => {
      this.loadRecentScreen();
    });
  }
}
