import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import * as lodash from 'lodash';

@Injectable()
export class AppDataFetchService {

  constructor(private http: HttpClient) {
  }

  baseUrl = '/api';

  asOf(params: any): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/as-of');
  }

  tiles(params: any): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/main-tiles?firmCategory=' +
      params.firmCategory + '&firmSalesPercentileBucket=' + params.firmSalesPercentileBucket);
  }

  fetchKPIChartsSettingsLp(postData: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/kpi-chart-settings-lp', postData);
  }

  // Avg Acc
  fetchKPIAvgAccValChart(postData: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/kpi-chart-avg-acc-val', postData);
  }

  fetchKPIAvgAccValDataObjTable(): any {
    return this.baseUrl + '/kpi-dataobj-avg-acc-val';
  }

  fetchKPIAvgAccValDataAccObjTable(): any {
    return this.baseUrl + '/kpi-accobj-avg-acc-val';
  }

  // Avg Acc
  fetchKPIRenewalRateChart(postData: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/kpi-chart-renewal-rate', postData);
  }

  fetchKPIRenewalRateDataObjTable(): any {
    return this.baseUrl + '/kpi-dataobj-renewal-rate';
  }

  fetchKPIRenewalRateAccObjTable(): any {
    return this.baseUrl + '/kpi-accobj-renewal-rate';
  }

  // Firm Account Profile
  fetchFirmAccountProfile(params: any): any {
    return this.http.post<any>(this.baseUrl + '/kpi-form-account-profile', params);
  }

  fetchFirmAccountProfileAggData(): any {
    return this.baseUrl + '/kpi-form-account-profile-aggdata';
  }

  fetchFirmAccountProfileProductDatagrid(): any {
    return this.baseUrl + '/kpi-form-account-profile-productdatagrid';
  }

  fetchFirmAccountProfileProductModal(params: any): any {
    return this.http.post<any>(this.baseUrl + '/kpi-form-account-profile-productmodal', params);
  }

  fetchFirmAccountProfileSalesDetails(): any {
    return this.baseUrl + '/kpi-form-account-profile-salesdetails';
  }

  fetchFirmAccountProfileActivity(): any {
    return this.baseUrl + '/kpi-form-account-profile-activity';
  }

  // product details
  lpProduct(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/lp-products-type');
  }

  fetchProductDetailsProfile(params: any): any {
    return this.http.post<any>(this.baseUrl + '/kpi-product-details-profile', params);
  }

  fetchProductDetailsHistoricalData(): any {
    return this.baseUrl + '/kpi-product-details-historical-data';
  }

  fetchProductDetailsAccountData(): any {
    return this.baseUrl + '/kpi-product-details-account-data';
  }

  fetchProductDetailsSaleDetailsData(): any {
    return this.baseUrl + '/kpi-product-details-sale-details-data';
  }

  fetchProductDetailsSalesPercentileData(): any {
    return this.baseUrl + '/kpi-product-details-sales-percentile-data';
  }

  fetchProductDetailsAccountTypeData(): any {
    return this.baseUrl + '/kpi-product-details-account-type-data';
  }

  fetchProductDetailsAccountCategoryData(): any {
    return this.baseUrl + '/kpi-product-details-account-category-data';
  }


  // Screening
  esFieldAgg(body: any, urlParam): Promise<any> {
    return this.http.post<any>(this.baseUrl + '/es-field-agg?remark=' + urlParam.remark, body).toPromise();
  }

  getFilterParameters(body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/filter-params', body);
  }

  getMasterData(elasticSearchModel: any): Promise<any> {
    const searchBody = elasticSearchModel && Object.keys(elasticSearchModel).length ? elasticSearchModel : {};
    return new Promise((resolve, reject) => {
      this.http.post<any>(this.baseUrl + '/master-data', {searchBody}).toPromise().then((response) => {
        const respObj = response;
        if (respObj.totalRowsCount) {
          respObj.currentRows = lodash.map(respObj.currentRows, esRow => esRow._source);
        }
        resolve(respObj);
      }, (err) => {
        reject(err.data);
      });
    });
  }

  generateExcel(params: any): Observable<any> {
    return this.http.post(this.baseUrl + '/print/excel',
      params, {responseType: 'arraybuffer' as const});
  }

  getColsPrintOrder(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/print/cols-print-order');
  }

  listGrids(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/list_grids');
  }

  getScreenDetails(gridId: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/fetch_grid/' + gridId);
  }

  saveScreen(params): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/save_grid', params);
  }

  deleteScreen(gridId: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/delete_grid/' + gridId, {});
  }

  // account review
  lpAccountName(q: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/lp-account-name?q=' + q);
  }

  accountReviewTableData(params): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/account_review_table', params);
  }

  generateAccountReviewExcel(params: any): Observable<any> {
    return this.http.post(this.baseUrl + '/print/excel_account_review',
      params, {responseType: 'arraybuffer' as const});
  }


}
