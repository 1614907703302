<div class="row">
  <div class="col-md-12">
    <div class="panel panel-default">
      <div class="panel-heading mt-3 mb-3"><b>Specify search criteria</b></div>
      <div class="panel-body">
        <!-- -->
        <div class="row">
          <div class="col-md-4 dashboard-filter-col">
            <input type="text" [(ngModel)]="advancedSearch.searchSelectedColName" placeholder="Search available columns"
                   class="form-control">
            <hr/>
            <ul class="list-group" style="height: 220px;overflow-y: auto;">
              <li class="list-group-item" *ngFor="let col of filterAvailableCols()"
                  [ngbTooltip]="col.col_tp"
                  [disableTooltip]="fieldDefinitionsToolTipEnabled"
                  (click)="addSearchCriteriaForCol(col)">
                {{ col.col_name }}
              </li>
            </ul>
          </div>
          <div class="col-md-8  dashboard-filter-col">
            <form name="sortOrder" class="form-inline">
              <label class="mr-2">
                <input type="radio" class="mr-2" [(ngModel)]="advancedSearch.searchCriteria" value="AND"
                       [ngModelOptions]="{ standalone: true }" (change)="applyCurrentSearch()">
                All Conditions
              </label>
              <label>
                <input type="radio" class="mr-2" [(ngModel)]="advancedSearch.searchCriteria" value="OR"
                       [ngModelOptions]="{ standalone: true }" (change)="applyCurrentSearch()">
                Any Conditions
              </label>
            </form>
            <hr/>
            <ul class="nav nav-pills nav-stacked">
              <div class="list-group dashboard-list-group" style="width: 100%">
                <div class="list-group-item screener-list-group-item"
                     *ngFor="let col of advancedSearch.selectedCols; let $index = index"
                     [ngClass]="{'active': $index === advancedSearch.activeSearchColIndex}"
                     (click)="updateActiveSearchColID($index)">
                  <!--                  <span>&nbsp;{{advancedSearch.appliedSearchesDM[$index].search_type}}</span>-->
                  <div class="row">
                    <div class="col-md-8">
                      <span class="align-top">{{ col.col_name }}</span>
                    </div>
                    <div class="col-md-4">
                      <fa-icon [icon]="faTrash" class="cursor-pointer float-right"
                               (click)="removeColFromSelectedSearchCols($index); $event.stopPropagation();">
                      </fa-icon>
                    </div>
                  </div>

                  <div class="row">
                    <ng-container *ngIf="advancedSearch.appliedSearchesDM[$index].search_type === 1">
                      <div class="col-md-12">
                        <form class="form-inline">
                          <div class="btn-group" ngbDropdown [autoClose]="true">
                            <button class="btn btn-default btn-sm dropdown-toggle btn-light" type="button"
                                    ngbDropdownToggle>

                              {{ advancedSearch.appliedSearchesDM[$index] && advancedSearch.appliedSearchesDM[$index].currentCondition && advancedSearch.appliedSearchesDM[$index].currentCondition.display_val ? advancedSearch.appliedSearchesDM[$index].currentCondition.display_val : 'Select Condition' }}
                            </button>

                            <ul class="dropdown-menu" ngbDropdownMenu role="menu">

                              <li ngbDropdownItem
                                  *ngFor="let condition of advancedSearch.appliedSearchesDM[$index].allConditions"
                                  role="menuitem">
                                <a
                                  (click)="updateCurrentSearchCondition($index, condition)">{{ condition.display_val }}</a>
                              </li>
                            </ul>
                          </div>
                          <span>
                            <input *ngIf="!staticFilterOptions[advancedSearch.appliedSearchesDM[$index].col_id]"
                                   type="text"
                                   [(ngModel)]="advancedSearch.appliedSearchesDM[$index].searchStr"
                                   [ngModelOptions]="{standalone: true}"
                                   class="form-control"
                                   placeholder="Enter some text"
                                   (ngModelChange)="applyCustomStringSearch($index, advancedSearch.appliedSearchesDM[$index].searchStr); "
                                   [disabled]="advancedSearch.appliedSearchesDM[$index].currentCondition.value === null"/>

                            <select *ngIf="staticFilterOptions[advancedSearch.appliedSearchesDM[$index].col_id]"
                                    [(ngModel)]="advancedSearch.appliedSearchesDM[$index].searchStr"
                                    [ngModelOptions]="{standalone: true}"
                                    class="form-control"
                                    (ngModelChange)="applyCustomStringSearch($index, advancedSearch.appliedSearchesDM[$index].searchStr); "
                                    [disabled]="advancedSearch.appliedSearchesDM[$index].currentCondition.value === null">
                              <option value="ALL">All</option>
                              <option
                                *ngFor="let opt of staticFilterOptions[advancedSearch.appliedSearchesDM[$index].col_id].buckets"
                                [value]="opt.key">
                                {{ opt.key }}
                              </option>
                            </select>
                          </span>
                        </form>
                      </div>
                    </ng-container>
                    <ng-container
                      *ngIf="advancedSearch.appliedSearchesDM[$index].search_type === 2 || advancedSearch.appliedSearchesDM[$index].search_type=== 3">
                      <div class="col-md-12">
                        <form class="form-inline">
                          <div class="btn-group" ngbDropdown>
                            <button class="btn btn-default btn-sm dropdown-toggle btn-light" type="button"
                                    ngbDropdownToggle>
                              {{ advancedSearch.appliedSearchesDM[$index] && advancedSearch.appliedSearchesDM[$index].currentCondition && advancedSearch.appliedSearchesDM[$index].currentCondition.display_val ? advancedSearch.appliedSearchesDM[$index].currentCondition.display_val : 'Select Condition' }}
                            </button>
                            <ul class="dropdown-menu" ngbDropdownMenu role="menu">
                              <li ngbDropdownItem
                                  *ngFor="let condition of advancedSearch.appliedSearchesDM[$index].allConditions"
                                  role="menuitem">
                                <a
                                  (click)="updateCurrentSearchCondition($index, condition)">{{ condition.display_val }}</a>
                              </li>
                            </ul>
                          </div>
                          <div *ngIf="advancedSearch.appliedSearchesDM[$index] &&
                          advancedSearch.appliedSearchesDM[$index].currentCondition &&
                          advancedSearch.appliedSearchesDM[$index].currentCondition.value === 'BETWEEN'">
                            <form class="form-horizontal">
                              <div class="form-group">
                                <div class="col-md-5">
                                  <input type="text" [(ngModel)]="advancedSearch.appliedSearchesDM[$index].min"
                                         class="form-control"
                                         (ngModelChange)="applyCustomNumericSearch($index, null, advancedSearch.appliedSearchesDM[$index].min, advancedSearch.appliedSearchesDM[$index].max)"
                                         placeholder="Min Value"
                                         [disabled]="advancedSearch.appliedSearchesDM[$index].currentCondition &&
                                           advancedSearch.appliedSearchesDM[$index].currentCondition.value === null"
                                         [ngModelOptions]="{ standalone: true }">
                                </div>
                                <label class="col-sm-2 control-label">and</label>
                                <div class="col-md-5">
                                  <input type="text" [(ngModel)]="advancedSearch.appliedSearchesDM[$index].max"
                                         class="form-control"
                                         (ngModelChange)="applyCustomNumericSearch($index, null, advancedSearch.appliedSearchesDM[$index].min, advancedSearch.appliedSearchesDM[$index].max)"
                                         placeholder="Max Value"
                                         [disabled]="advancedSearch.appliedSearchesDM[$index].currentCondition.value === null"
                                         [ngModelOptions]="{ standalone: true }">
                                </div>
                              </div>
                            </form>
                          </div>
                          <div *ngIf="advancedSearch.appliedSearchesDM[$index] &&
                          advancedSearch.appliedSearchesDM[$index].currentCondition &&
                          advancedSearch.appliedSearchesDM[$index].currentCondition.value !== 'BETWEEN'">
                            <input type="text" [(ngModel)]="advancedSearch.appliedSearchesDM[$index].searchStr"
                                   class="form-control"
                                   (ngModelChange)="applyCustomNumericSearch($index, advancedSearch.appliedSearchesDM[$index].searchStr, null, null)"
                                   placeholder="Enter value"
                                   [disabled]="advancedSearch.appliedSearchesDM[$index].currentCondition && advancedSearch.appliedSearchesDM[$index].currentCondition.value === null"
                                   [ngModelOptions]="{ standalone: true }">
                          </div>
                        </form>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="advancedSearch && advancedSearch.appliedSearchesDM[$index] &&
                    advancedSearch.appliedSearchesDM[$index].search_type === 4">
                      <div class="col-md-12">
                        <div class="form-inline">
                          <div class="btn-group" ngbDropdown="true">
                            <button class="btn btn-default btn-sm dropdown-toggle btn-light" type="button"
                                    ngbDropdownToggle>
                              {{advancedSearch.appliedSearchesDM[$index] &&
                            advancedSearch.appliedSearchesDM[$index].currentCondition &&
                            advancedSearch.appliedSearchesDM[$index].currentCondition.display_val ? advancedSearch.appliedSearchesDM[$index].currentCondition.display_val : 'Select Condition' }}
                            </button>
                            <ul class="dropdown-menu" ngbDropdownMenu role="menu">
                              <li ngbDropdownItem
                                  *ngFor="let condition of advancedSearch.appliedSearchesDM[$index].allConditions"
                                  role="menuitem">
                                <a (click)="updateSearchConditionAndCalculateSearchDateRange($index, condition)">
                                  {{ condition.display_val }}
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div
                            *ngIf="advancedSearch.appliedSearchesDM[$index].currentCondition && advancedSearch.appliedSearchesDM[$index].currentCondition.value === 'BETWEEN'">
                            <form class="form-horizontal">
                              <div class="form-group">
                                <div class="col-md-5">
                                  <input type="text" [(ngModel)]="advancedSearch.appliedSearchesDM[$index].min"
                                         (ngModelChange)="applyCustomDateSearch($index, null, advancedSearch.appliedSearchesDM[$index].min, advancedSearch.appliedSearchesDM[$index].max)"
                                         class="form-control" placeholder="Min Date(YYYY-MM-DD)"
                                         [disabled]="advancedSearch.appliedSearchesDM[$index].currentCondition.value === null"
                                         [ngModelOptions]="{ standalone: true }">
                                </div>
                                <label class="col-sm-2 control-label">and</label>
                                <div class="col-md-5">
                                  <input type="text" [(ngModel)]="advancedSearch.appliedSearchesDM[$index].max"
                                         (ngModelChange)="applyCustomDateSearch($index, null, advancedSearch.appliedSearchesDM[$index].min, advancedSearch.appliedSearchesDM[$index].max)"
                                         class="form-control" placeholder="Max Date(YYYY-MM-DD)"
                                         [disabled]="advancedSearch.appliedSearchesDM[$index].currentCondition.value === null"
                                         [ngModelOptions]="{ standalone: true }">
                                </div>
                              </div>
                            </form>
                          </div>
                          <div
                            *ngIf="advancedSearch.appliedSearchesDM[$index].currentCondition && advancedSearch.appliedSearchesDM[$index].currentCondition.value !== 'BETWEEN'">
                            <div *ngIf="advancedSearch.appliedSearchesDM[$index].currentCondition.auto_calc">
                              <input type="text" [(ngModel)]="advancedSearch.appliedSearchesDM[$index].searchStr"
                                     class="form-control" disabled [ngModelOptions]="{ standalone: true }">
                            </div>
                            <div *ngIf="!advancedSearch.appliedSearchesDM[$index].currentCondition.auto_calc">
                              <input type="text" [(ngModel)]="advancedSearch.appliedSearchesDM[$index].searchStr"
                                     (ngModelChange)="applyCustomDateSearch($index, advancedSearch.appliedSearchesDM[$index].searchStr, null, null)"
                                     class="form-control" placeholder="Date(YYYY-MM-DD)"
                                     [disabled]="advancedSearch.appliedSearchesDM[$index].currentCondition.value === null"
                                     [ngModelOptions]="{ standalone: true }">
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="advancedSearch.appliedSearchesDM[$index].search_type === 5">
                      <div class="col-md-12">
                        <form class="form-inline">
                          <input type="radio" [(ngModel)]="advancedSearch.appliedSearchesDM[$index].boolValue"
                                 [value]="true"
                                 (ngModelChange)="updateAndApplyBoolSearch($index, advancedSearch.appliedSearchesDM[$index].boolValue)"
                                 [ngModelOptions]="{ standalone: true }"> Yes
                          <input type="radio" [(ngModel)]="advancedSearch.appliedSearchesDM[$index].boolValue"
                                 [value]="false"
                                 (ngModelChange)="updateAndApplyBoolSearch($index, advancedSearch.appliedSearchesDM[$index].boolValue)"
                                 [ngModelOptions]="{ standalone: true }"> No
                        </form>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="advancedSearch.appliedSearchesDM[$index].search_type === 7">
                      <div class="col-md-12">
                        <form class="form-inline">
                          <input type="radio" [(ngModel)]="advancedSearch.appliedSearchesDM[$index].boolValue"
                                 [value]="false"
                                 (ngModelChange)="updateAndApplyBoolSearch($index, advancedSearch.appliedSearchesDM[$index].boolValue)"
                                 [ngModelOptions]="{ standalone: true }"> Is Null
                          <input type="radio" [(ngModel)]="advancedSearch.appliedSearchesDM[$index].boolValue"
                                 [value]="true"
                                 (ngModelChange)="updateAndApplyBoolSearch($index, advancedSearch.appliedSearchesDM[$index].boolValue)"
                                 [ngModelOptions]="{ standalone: true }"> Is Not Null
                        </form>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="advancedSearch.appliedSearchesDM[$index].search_type === 8 ">
                      <div class="col-md-12">
                        <form class="form-inline">
                          <div class="btn-group" ngbDropdown dropdown-append-to-body="true">
                            <button class="btn btn-default btn-sm dropdown-toggle btn-light" type="button"
                                    ngbDropdownToggle>
                              {{ advancedSearch.appliedSearchesDM[$index] && advancedSearch.appliedSearchesDM[$index].currentCondition && advancedSearch.appliedSearchesDM[$index].currentCondition.display_val ? advancedSearch.appliedSearchesDM[$index].currentCondition.display_val : 'Select Condition' }}
                            </button>
                            <ul class="dropdown-menu" ngbDropdownMenu role="menu">
                              <li ngbDropdownItem
                                  *ngFor="let condition of advancedSearch.appliedSearchesDM[$index].allConditions"
                                  role="menuitem">
                                <a
                                  (click)="updateCurrentSearchCondition($index, condition)">{{ condition.display_val }}</a>
                              </li>
                            </ul>
                          </div>
                          <input type="text" [(ngModel)]="advancedSearch.appliedSearchesDM[$index].searchStr"
                                 placeholder="Enter some text"
                                 uib-typeahead="opt.nameFormat for opt in advancedSearch.appliedSearchesDM[$index].dropDownOpts | filter:$viewValue | limitTo:10"
                                 class="form-control"
                                 (ngModelChange)="checkIfTypeAheadIsBlank(advancedSearch.appliedSearchesDM[$index].searchStr, $index)"
                                 typeahead-on-select="applyDropDownSearchOnNested($item, $index)"
                                 typeahead-append-to-body="true"
                                 [disabled]="advancedSearch.appliedSearchesDM[$index].currentCondition && advancedSearch.appliedSearchesDM[$index].currentCondition.value === null"
                                 [ngModelOptions]="{ standalone: true }">
                        </form>
                      </div>
                    </ng-container>
                  </div>

                </div>
              </div>

            </ul>


          </div>
        </div>
        <!-- -->

      </div>
    </div>
  </div>
</div>
