import {Injectable} from "@angular/core";
import {DatePipe} from '@angular/common';
import * as Highcharts from 'highcharts';
import * as _ from 'lodash';
import exporting from 'highcharts/modules/exporting';

exporting(Highcharts);
const HC_REGRESSION = require('highcharts-regression');
HC_REGRESSION(Highcharts);

import {AppsettingsService} from "../../services/appsettings.service";
import {
  StAlinkViewComponent,
  StDatepickerRenderComponent,
  StDatepickerViewComponent
} from "../building_block/st-buttonview/button-view.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {KpiFormAccountProductDetailsModalComponent} from "../kpi_firm_account_profile/kpi-form-account-product-details-modal/kpi-form-account-product-details-modal.component";
import {Router} from "@angular/router";
import * as moment from "moment";

@Injectable()
export class KpiChartAndDataComponentCfg {

  constructor(private datePipe: DatePipe,
              public appSettings: AppsettingsService,
              private modalService: NgbModal,
              private router: Router) {
  }

  tz = 'America/New_York';
  kpiIdLabel = this.appSettings.settings.kpi;

  private currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
  });

  public COLOR_PALETTES = {
    DOT: "#024466",
    GRAY: "#A09A8D",
    BLUE: "#337AB7",
    BLUE2: "#0DB9F0",
    ORANGE: "#FFA200"
  };
  public PERCENTILE_SERIES_COLORS = {
    ALL: {
      color: this.COLOR_PALETTES.DOT,
      dashStyle: 'shortdot',
      zIndex: 1
    },
    '0-25': {
      color: this.COLOR_PALETTES.GRAY,
      dashStyle: 'Solid',
      zIndex: 100,
    },
    '25-50': {
      color: this.COLOR_PALETTES.BLUE,
      dashStyle: 'Solid',
      zIndex: 95
    },
    '50-75': {
      color: this.COLOR_PALETTES.BLUE2, dashStyle: 'Solid',
      zIndex: 90
    },
    '75-100': {
      color: this.COLOR_PALETTES.ORANGE, dashStyle: 'Solid',
      zIndex: 85
    },
    value: {color: this.COLOR_PALETTES.BLUE},
    volume: {color: this.COLOR_PALETTES.ORANGE},
  };
  public PRODUCT_SERIES_COLORS = {
    DEAL: {color: '#000000'},
    CP: {color: '#00B0F0'},
    BVPR: {color: '#3B3838'},
    TPRO: {color: '#767171'},
    FMV: {color: '#A6A6A6'},
    OPL: {color: '#BFBFBF'},
    TSUB: {color: '#D9D9D9'},
    BC: {color: '#E7E6E6'},
    EOU: {color: '#FFFFFF'},
    BIZM: {color: '#0070AE'},
    Other: {color: 'purple'}
  };

  public PRODUCT_VERTICAL_SERIES_COLORS = {
    TRNG: {color: '#000000'},
    BVRSCH: {color: '#00B0F0'},
    TC: {color: '#3B3838'},
    DATA: {color: '#767171'},
    Other: {color: 'purple'}
  };

  public type1ChartModalSavedDefaultStates: any = {
    firmType: [
      "NONE"
    ],
    salesPercentileBucket: [
      "NONE"
    ],
    firmCategory: [
      "NONE"
    ],
    productGroup: [],
    portal: [
      0
    ],
    period: [
      "LTM"
    ],
    timeline: [
      12
    ]
  };

  // Avg Acc
  public avgAccValDataObjTableCfg = this.getDataObjTableCfg();
  public avgAccValAccountObjTableCfg = this.getAccountObjTableCfg();

  // Active Acc
  public activeAccValDataObjTableCfg = this.getDataObjTableCfg();
  public activeAccValAccountObjTableCfg = this.getAccountObjTableCfg();

  // total acc
  public totalAccValDataObjTableCfg = this.getDataObjTableCfg();
  public totalAccValAccountObjTableCfg = this.getAccountObjTableCfg();

  // avg monthly login
  public avgMonthlyLoginDataObjTableCfg = this.getDataObjTableCfg({noFormatterToValCol: true});
  public avgMonthlyLoginAccountObjTableCfg = this.getAccountObjTableCfg();

  // acc renewal
  public accRenewalRateDataObjTableCfg = this.getRenewalDataObjTableCgf();

  public accRenewalRateAccountObjTableCfg = this.getRenewalAccountObjTableCfg();

  // acc renewal
  public accRenewalRateVolDataObjTableCfg = this.getRenewalDataObjTableCgf();

  public accRenewalRateVolAccountObjTableCfg = this.getRenewalAccountObjTableCfg();

  // Conv rate val
  public convRateValDataObjTableCfg = this.getRenewalDataObjTableCgf();

  public convRateValAccountObjTableCfg = this.getRenewalAccountObjTableCfg();

  // Conv Rate Vol
  public convRateVolDataObjTableCfg = this.getRenewalDataObjTableCgf();

  public convRateVolAccountObjTableCfg = this.getRenewalAccountObjTableCfg();

  // Firm Profile
  public firmProfileAggDataCfg = {
    actions: {add: false, edit: false, delete: false},
    rowClassFunction: this.rowClassFunction,
    pager: {
      perPage: 50,
    },
    columns: {
      AsOf: {
        title: 'As Of',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return moment.utc(data).format("MM/DD/yyyy");
        },
        filterFunction: (cell?: any, search?: string) => {
          // cell? is the value of the cell, in this case is a timeStamp
          if (search) {
            return moment.utc(cell).format("MM/DD/yyyy") === search;
          }
          return false;
        }
      },
      Attr: {
        title: 'Product'
      },
      Rank: {
        title: 'Rank',
        type: 'html',
        valuePrepareFunction: (data) => {
          return this.columnRightAlight(data);
        }
      },
      Users: {
        title: 'Users',
        type: 'html',
        valuePrepareFunction: (data) => {
          return this.columnRightAlight(data);
        }
      },
      Licenses: {
        title: 'Licenses',
        type: 'html',
        valuePrepareFunction: (data) => {
          return this.columnRightAlight(data);
        }
      },
      RenewalRate: {
        title: 'Renewal Rate',
        type: 'html',
        valuePrepareFunction: (data) => {
          return this.columnRightAlight(this.numFormatter(data, 2, null, '%'));
        }
      },
      LTMSpend: {
        title: 'LTM Spend',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.currencyFormatter.format(data));
        }
      },
      LTMNewSpend: {
        title: 'LTM New Spend',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.currencyFormatter.format(data));
        },
      },
      LTMCancelled: {
        title: 'LTM Cancelled',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.currencyFormatter.format(data));
        },
      },
      MDASpend: {
        title: 'DATASpend',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.currencyFormatter.format(data));
        },
      },
      DATAPct: {
        title: 'DATA %',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, '%'));
        },
      },
      BVRSCHSpend: {
        title: 'BVRSCH Spend',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.currencyFormatter.format(data));
        },
      },
      BVRNCHPct: {
        title: 'BVRSCH %',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, '%'));
        },
      },
      TRNGSpend: {
        title: 'TRNG Spend',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.currencyFormatter.format(data));
        },
      },
      TRNGPct: {
        title: 'TRNG %',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, '%'));
        },
      }
    }

  };

  public firmProfileProductDataCfg = {
    actions: {add: false, edit: false, delete: false},
    rowClassFunction: this.rowClassFunction,
    pager: {
      perPage: 50,
    },
    columns: {
      Product: {
        title: 'Product',
        type: 'custom',
        renderComponent: StAlinkViewComponent,
        onComponentInitFunction: (instance) => {
          instance.params = {
            textAlign: 'center'
          };
          instance.alinkClicked.subscribe(row => {
            this.openProductDetailsModal(row);
          });
        }
      },
      Attribute: {
        title: 'Attribute',
        type: 'html',
        valuePrepareFunction: (data) => {
          return this.columnCenterAlight(data);
        }
      },
      RankAccount: {
        title: 'Rank Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          return this.columnCenterAlight(data);
        }
      },
      RankAllAccounts: {
        title: 'Rank All Accounts',
        type: 'html',
        valuePrepareFunction: (data) => {
          return this.columnCenterAlight(data);
        }
      },
      Users: {
        title: 'Users',
        type: 'html',
        valuePrepareFunction: (data) => {
          return this.columnCenterAlight(data);
        }
      },
      ProductCount: {
        title: 'Product Count',
        type: 'html',
        valuePrepareFunction: (data) => {
          return this.columnCenterAlight(data);
        }
      },
      RenewalRate: {
        title: 'Renewal Rate',
        type: 'html',
        valuePrepareFunction: (data) => {
          return this.columnRightAlight(data);
        }
      },
      LTMSpend: {
        title: 'LTM Spend',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.currencyFormatter.format(data));
        }
      },
      LTMNewSpend: {
        title: 'LTM New Spend',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.currencyFormatter.format(data));
        },
      }
    }
  };

  public firmProfileSalesDetailsDataCfg = {
    actions: {add: false, edit: false, delete: false},
    rowClassFunction: this.rowClassFunction,
    pager: {
      perPage: 10,
    },
    columns: {
      Subs: {
        title: 'Subs #'
      },
      OrderNum: {
        title: 'Order #'
      },
      Line: {
        title: 'Line #',
        type: 'html',
        valuePrepareFunction: (data) => {
          return this.columnRightAlight(data);
        }
      },
      Product: {
        title: 'Product'
      },
      ProductType: {
        title: 'Product Type'
      },
      ProdSM: {
        title: 'Prod S/M'
      },
      NewOrRenewal: {
        title: 'New/ Renewal'
      },
      OrderDate: {
        title: 'Order Date',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return moment.utc(data).format("MM/DD/yyyy");
        },
        filterFunction: (cell?: any, search?: string) => {
          // cell? is the value of the cell, in this case is a timeStamp
          if (search) {
            return moment.utc(cell).format("MM/DD/yyyy") === search;
          }
          return false;
        }
      },
      "1stIssue": {
        title: '1st Issue',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return moment.utc(data).format("MM/DD/yyyy");
        },
        filterFunction: (cell?: any, search?: string) => {
          // cell? is the value of the cell, in this case is a timeStamp
          if (search) {
            return moment.utc(cell).format("MM/DD/yyyy") === search;
          }
          return false;
        }
      },
      ExpireIssue: {
        title: 'Expire Issue',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return moment.utc(data).format("MM/DD/yyyy");
        },
        filterFunction: (cell?: any, search?: string) => {
          // cell? is the value of the cell, in this case is a timeStamp
          if (search) {
            return moment.utc(cell).format("MM/DD/yyyy") === search;
          }
          return false;
        }
      },
      TRXDate: {
        title: 'TRX Date',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return moment.utc(data).format("MM/DD/yyyy");
        },
        filterFunction: (cell?: any, search?: string) => {
          // cell? is the value of the cell, in this case is a timeStamp
          if (search) {
            return moment.utc(cell).format("MM/DD/yyyy") === search;
          }
          return false;
        }
      },
      OpenItemType: {
        title: 'Open Item',
        type: 'html',
        valuePrepareFunction: (data) => {
          return this.columnRightAlight(data);
        }
      },
      Copies: {
        title: 'Copies',
        type: 'html',
        valuePrepareFunction: (data) => {
          return this.columnRightAlight(data);
        }
      },
      Amount: {
        title: 'Amount',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.currencyFormatter.format(data));
        },
      },
      SalesPortal: {
        title: 'Sales Portal'
      }
    }
  };

  public firmProfileProfileActivityCfg = {
    actions: {add: false, edit: false, delete: false},
    rowClassFunction: this.rowClassFunction,
    pager: {
      perPage: 50,
    },
    columns: {
      Value: {
        title: 'Value',
        type: 'html',
        valuePrepareFunction: (data) => {
          return this.columnCenterAlight(data);
        }
      },
      Year: {
        title: 'Year',
        type: 'html',
        valuePrepareFunction: (data) => {
          return this.columnCenterAlight(data);
        }
      },
      Month: {
        title: 'Month',
        type: 'html',
        valuePrepareFunction: (data) => {
          return this.columnCenterAlight(data);
        }
      },
      Type: {
        title: 'Type',
        type: 'html',
        valuePrepareFunction: (data) => {
          return this.columnRightAlight(data);
        }
      }
    }
  };


  public prodDetailsHistoricalData = {
    actions: {add: false, edit: false, delete: false},
    rowClassFunction: this.rowClassFunction,
    pager: {
      perPage: 10,
    },
    columns: {
      asOf: {
        title: 'As Of',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return moment.utc(data).format("MM/DD/yyyy");
        }
      },
      totalProductSalesRankTotalSales: {
        title: 'Rank Sales LTM',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnCenterAlight(data);
        }
      },
      sales: {
        title: 'Sales LTM',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.currencyFormatter.format(data));
        }
      },
      totalSalesPct: {
        title: 'Total Sales %',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        },
      },
      newSales: {
        title: 'New Sales LTM',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.currencyFormatter.format(data));
        }
      },
      newSalesPct: {
        title: 'New Sales LTM/Product %',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        }
      },
      clientCount: {
        title: 'Accounts',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        },
      },
      clientPct: {
        title: 'All Accounts %',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        }
      },
      newClient: {
        title: 'New Accounts',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      newClientPct: {
        title: 'New Accounts %',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        }
      },
      subscribersCount: {
        title: 'Users',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      subscribersAvg: {
        title: 'Avg Users/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      subscribersMax: {
        title: 'Max Users/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      subscribersPct: {
        title: 'All Users %',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        }
      },
      newSubscribers: {
        title: 'New Users',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      renewalRate: {
        title: 'Renewal Rate',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        }
      },
      salesAvg: {
        title: 'Avg Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      salesMin: {
        title: 'Min Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      sales25Percentile: {
        title: '25th Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      salesMedian: {
        title: '50th Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      sales75Percentile: {
        title: '75th Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      salesMax: {
        title: 'Max Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      newSalesAvg: {
        title: 'Avg New Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
    },
  };
  public prodDetailsAccountData = {
    actions: {add: false, edit: false, delete: false},
    rowClassFunction: this.rowClassFunction,
    pager: {
      perPage: 10,
    },
    columns: {
      firmName: {
        title: 'Name',
        type: 'custom',
        renderComponent: StAlinkViewComponent,
        onComponentInitFunction: (instance) => {
          instance.params = {};
          instance.alinkClicked.subscribe(row => {
            this.openKpiFormAccountProfile(row.firmName);
          });
        }
      },
      salesPercentileBucket: {
        title: 'Sales Percentile',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnCenterAlight(data);
        }
      },
      Spent: {
        title: 'Spent',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.currencyFormatter.format(data));
        }
      },
      newProductSales: {
        title: 'New Spent',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.currencyFormatter.format(data));
        }
      },
      newSalesPct: {
        title: 'New Spent %',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        }
      },
      newFirm: {
        title: 'New Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnCenterAlight(data);
        }
      },
      licenses: {
        title: 'Users',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      newLicenses: {
        title: 'New Users',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      newLicensesPct: {
        title: 'New Users %',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        }
      },
      renewLicenses: {
        title: 'Renewed',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      renewalRate: {
        title: 'Renewal Rate',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        }
      },
      productSalesRank: {
        title: 'Product Spent Rank',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      firmProductSalesRank: {
        title: 'Firm Prod Spent Rank',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      isPortal: {
        title: 'Portal',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnCenterAlight(data);
        }
      }
    }
  };

  public prodDetailsSaleDetailsData = {
    actions: {add: false, edit: false, delete: false},
    rowClassFunction: this.rowClassFunction,
    pager: {
      perPage: 10,
    },
    columns: {
      Account: {
        title: 'Account',
        type: 'custom',
        renderComponent: StAlinkViewComponent,
        onComponentInitFunction: (instance) => {
          instance.params = {};
          instance.alinkClicked.subscribe(row => {
            this.openKpiFormAccountProfile(row.Account);
          });
        }
      },
      subs: {
        title: 'Subs #',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      orderNo: {
        title: 'Order #',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      line: {
        title: 'Line #',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      product: {
        title: 'Product',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnCenterAlight(data);
        }
      },
      productType: {
        title: 'Product Type',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnCenterAlight(data);
        }
      },
      prdSm: {
        title: 'Prd S/M',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnCenterAlight(data);
        }
      },
      newRenewal: {
        title: 'New/Renewal',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnCenterAlight(data);
        }
      },
      orderData: {
        title: 'Order Date',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return moment.utc(data).format("MM/DD/yyyy");
        }
      },
      firstIssue: {
        title: '1st Issue',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return moment.utc(data).format("MM/DD/yyyy");
        }
      },
      expireIssue: {
        title: 'Expire Issue',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return moment.utc(data).format("MM/DD/yyyy");
        }
      },
      trxData: {
        title: 'TRX Date',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return moment.utc(data).format("MM/DD/yyyy");
        }
      },
      openItem: {
        title: 'Open Item',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnCenterAlight(data);
        }
      },
      copies: {
        title: 'Copies',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      amount: {
        title: 'Amount',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.currencyFormatter.format(data));
        }
      },
      salesPortal: {
        title: 'Sales Portal',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnCenterAlight(data);
        }
      }
    }
  };


  public prodDetailsSalesPercentileData = {
    actions: {add: false, edit: false, delete: false},
    rowClassFunction: this.rowClassFunction,
    pager: {
      perPage: 10,
    },
    columns: {
      asOf: {
        title: 'As Of',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return moment.utc(data).format("MM/DD/yyyy");
        }
      },
      salesPercentileBucket: {
        title: 'Sales Percentile',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnCenterAlight(data);
        }
      },
      totalProductSalesRankTotalSales: {
        title: 'Rank Sales LTM',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnCenterAlight(data);
        }
      },
      sales: {
        title: 'Sales LTM',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.currencyFormatter.format(data));
        }
      },
      totalSalesPct: {
        title: 'Total Sales %',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        }
      },
      newSales: {
        title: 'New Sales LTM',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.currencyFormatter.format(data));
        }
      },
      newSalesPct: {
        title: 'New Sales LTM/Product %',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        }
      },
      clientCount: {
        title: 'Accounts',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      clientPct: {
        title: 'All Accounts %',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        }
      },
      newClient: {
        title: 'New Accounts',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      newClientPct: {
        title: 'New Accounts %',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        }
      },
      subscribersCount: {
        title: 'Users',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      subscribersAvg: {
        title: 'Avg Users/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      subscribersMax: {
        title: 'Max Users/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      subscribersPct: {
        title: 'All Users %',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        }
      },
      newSubscribers: {
        title: 'New Users',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      renewalRate: {
        title: 'Renewal Rate',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnCenterAlight(data);
        }
      },
      salesAvg: {
        title: 'Avg Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      salesMin: {
        title: 'Min Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      sales25Percentile: {
        title: '25th Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      salesMedian: {
        title: '50th Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      sales75Percentile: {
        title: '75th Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      salesMax: {
        title: 'Max Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      newSalesAvg: {
        title: 'Avg New Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      }
    }
  };


  public prodDetailsAccountTypeData = {
    actions: {add: false, edit: false, delete: false},
    rowClassFunction: this.rowClassFunction,
    pager: {
      perPage: 25,
    },
    columns: {
      asOf: {
        title: 'As Of',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return moment.utc(data).format("MM/DD/yyyy");
        }
      },
      firmType: {
        title: 'Account Type',

        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return data;
        }
      },
      totalProductSalesRankTotalSales: {
        title: 'Rank Sales LTM',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      sales: {
        title: 'Sales LTM',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.currencyFormatter.format(data));
        }
      },
      totalSalesPct: {
        title: 'Total Sales %',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        }
      },
      newSales: {
        title: 'New Sales LTM',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.currencyFormatter.format(data));
        }
      },
      newSalesPct: {
        title: 'New Sales LTM/Product %',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        }
      },
      clientCount: {
        title: 'Accounts',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      clientPct: {
        title: 'All Accounts %',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        }
      },
      newClient: {
        title: 'New Accounts',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      newClientPct: {
        title: 'New Accounts %',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        }
      },
      subscribersCount: {
        title: 'Users',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      subscribersAvg: {
        title: 'Avg Users/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      subscribersMax: {
        title: 'Max Users/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      subscribersPct: {
        title: 'All Users %',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        }
      },
      newSubscribers: {
        title: 'New Users',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      renewalRate: {
        title: 'Renewal Rate',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        }
      },
      salesAvg: {
        title: 'Avg Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      salesMin: {
        title: 'Min Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      sales25Percentile: {
        title: '25th Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      salesMedian: {
        title: '50th Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      sales75Percentile: {
        title: '75th Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      salesMax: {
        title: 'Max Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      newSalesAvg: {
        title: 'Avg New Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      }
    }
  };

  public prodDetailsAccountCategoryData = {
    actions: {add: false, edit: false, delete: false},
    rowClassFunction: this.rowClassFunction,
    pager: {
      perPage: 25,
    },
    columns: {

      asOf: {
        title: 'As Of',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return moment.utc(data).format("MM/DD/yyyy");
        }
      },
      firmCategory: {
        title: 'Firm Category',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnCenterAlight(data);
        }
      },
      totalProductSalesRankTotalSales: {
        title: 'Rank Sales LTM',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnCenterAlight(data);
        }
      },
      sales: {
        title: 'Sales LTM',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.currencyFormatter.format(data));
        }
      },
      totalSalesPct: {
        title: 'Total Sales %',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        }
      },
      newSales: {
        title: 'New Sales LTM',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.currencyFormatter.format(data));
        }
      },
      newSalesPct: {
        title: 'New Sales LTM/Product %',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        }
      },
      clientCount: {
        title: 'Accounts',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      clientPct: {
        title: 'All Accounts %',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        }
      },
      newClient: {
        title: 'New Accounts',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      newClientPct: {
        title: 'New Accounts %',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        }
      },
      subscribersCount: {
        title: 'Users',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      subscribersAvg: {
        title: 'Avg Users/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      subscribersMax: {
        title: 'Max Users/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      subscribersPct: {
        title: 'All Users %',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        }
      },
      newSubscribers: {
        title: 'New Users',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      renewalRate: {
        title: 'Renewal Rate',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 1, null, '%'));
        }
      },
      salesAvg: {
        title: 'Avg Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      salesMin: {
        title: 'Min Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      sales25Percentile: {
        title: '25th Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      salesMedian: {
        title: '50th Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      sales75Percentile: {
        title: '75th Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      salesMax: {
        title: 'Max Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      },
      newSalesAvg: {
        title: 'Avg New Sale/Account',
        type: 'html',
        valuePrepareFunction: (data) => {
          if (!data) {
            return '';
          }
          return this.columnRightAlight(this.numFormatter(data, 0, null, ''));
        }
      }
    }
  };

  private numFormatter = (data, fractionSize, prefix, suffix) => {
    const f = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: fractionSize || 0
    });

    data = f.format(data);
    if (prefix) {
      data = prefix + data;
    }
    if (suffix) {
      data = data + suffix;
    }
    return data;
  }

  public chart1 = (modalInstances, chartData, currentKpiName): any => {
    const seriesData = [];
    _.forEach(chartData, (cArr, percentileI) => {
      const sr: any = {
        zIndex: this.PERCENTILE_SERIES_COLORS[percentileI].zIndex,
        name: percentileI,
        marker: {
          symbol: 'circle'
        },
        dashStyle: this.PERCENTILE_SERIES_COLORS[percentileI].dashStyle,
        findNearestPointBy: 'xy',
        data: _.reverse(_.map(cArr, (vi) => {
          return {
            x: new Date(vi.asOf).getTime(),
            y: vi.value
          };
        }))
      };
      sr.color = this.PERCENTILE_SERIES_COLORS[percentileI].color;
      seriesData.push(sr);
    });

    const cfg: any = {
      credits: {
        enabled: false
      },
      exporting: this.chartAnalyserBtn(modalInstances),
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        borderColor: '#f2f2f2',
        borderWidth: 1
      },
      chart: {
        type: 'line'
      },
      title: {
        text: null
      },
      plotOptions: {
        series: {
          symbol: 'circle',
          marker: {
            enabled: false
          }
        }
      },
      tooltip: {
        formatter(): string { // @ts-ignore
          return 'Series "' + this.series.name +
            '" Point ' + Highcharts.dateFormat('%m/%e/%Y', this.x) +
            ' Value $' + Highcharts.numberFormat(this.y, 0, '.', ',');
        }
      },
      xAxis: {
        type: "datetime",
        tickInterval: 30 * 24 * 3600 * 1000,
        labels: {
          rotation: -65,
          formatter(): string {
            return Highcharts.dateFormat('%m/%e/%Y', this.value);
          }
        }
      },
      yAxis: {
        title: false,
        labels: {
          formatter(): string { // @ts-ignore
            return '$' + Highcharts.numberFormat(this.value, 0, '.', ',');
          }
        }
      },
      series: seriesData
    };
    // Special case: Total Account Value - LTM is area chart
    if (currentKpiName === this.kpiIdLabel.TOTAL_ACC.id) {
      cfg.chart.type = 'areaspline';
    }
    // Special case: Average Monthly Login - LTM,Active Account - LTM yaxis formatter and tool tip
    if ([this.kpiIdLabel.AVG_MONTHLY_LOGIN.id, this.kpiIdLabel.ACT_ACC.id].indexOf(currentKpiName) > -1) {
      cfg.yAxis = {
        title: false,
        labels: {
          formatter(): string { // @ts-ignore
            return Highcharts.numberFormat(this.value, 0, '.', ',');
          }
        }
      };
      cfg.tooltip = {
        formatter(): string { // @ts-ignore
          return 'Series "' + this.series.name +
            '" Point ' + Highcharts.dateFormat('%m/%e/%Y', this.x) +
            ' Value ' + Highcharts.numberFormat(this.y, 0, '.', ',');
        }
      };
    }
    return cfg;
  }

  public chart2 = (modalInstances, chartData, currentKpiName): any => {
    const seriesData = [];
    _.forEach(chartData, (cArr, asType) => {
      seriesData.push({
        name: asType === 'volume' ? 'Volume' : 'S Value',
        color: this.PERCENTILE_SERIES_COLORS[asType].color,
        marker: {
          symbol: 'circle'
        },
        dashStyle: this.PERCENTILE_SERIES_COLORS[asType].dashStyle,
        data: _.reverse(_.map(cArr, (vi) => {
          return {
            x: new Date(vi.asOf).getTime(),
            y: vi.accountRenewalRate
          };
        }))
      });
    });
    const cfg: any = {
      credits: {
        enabled: false
      },
      exporting: this.chartAnalyserBtn(modalInstances),
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        borderColor: '#f2f2f2',
        borderWidth: 1
      },
      chart: {
        type: 'line'
      },
      title: {
        text: null
      },
      plotOptions: {
        series: {
          symbol: 'circle',
          marker: {
            enabled: false
          }
        }
      },
      tooltip: {
        formatter(): string { // @ts-ignore
          return 'Series "' + this.series.name +
            '" Point ' + Highcharts.dateFormat('%m/%e/%Y', this.x) +
            ' Value ' + Highcharts.numberFormat(this.y, 0, '.', ',');
        }
      },
      xAxis: {
        type: "datetime",
        tickInterval: 30 * 24 * 3600 * 1000,
        labels: {
          rotation: -65,
          formatter(): string {
            return Highcharts.dateFormat('%m/%e/%Y', this.value);
          }
        }
      },
      yAxis: {
        title: false,
        labels: {
          formatter(): string { // @ts-ignore
            return Highcharts.numberFormat(this.value, 0, '.', ',');
          }
        }
      },
      series: seriesData
    };
    return cfg;
  }

  public chartAnalyserBtn = (modalInstances): any => {
    return {
      buttons: {
        contextButton: {
          enabled: false
        },
        chartAnalyser: {
          align: 'right',
          symbol: 'url(../public/assets/images/analyzer.png)',
          y: 345,
          symbolSize: 25,
          symbolX: 25,
          symbolY: 25,
          onclick: () => {
            modalInstances.openKpiChartsSettingModal();
          }
        }
      }
    };
  }

  public spendByProductChartObj = (chartData, isSpendByProductVertical?): any => {
    const seriesData = [];
    if (isSpendByProductVertical) {
      _.forEach(Object.keys(chartData), (k) => {
        // Discard 0 pie
        if (chartData[k]) {
          seriesData.push({
            name: k,
            y: chartData[k],
            color: this.PRODUCT_VERTICAL_SERIES_COLORS[k] ?
              this.PRODUCT_VERTICAL_SERIES_COLORS[k].color : this.PRODUCT_VERTICAL_SERIES_COLORS.Other.color
          });
        }
      });
    } else {
      _.forEach(chartData, (v) => {
        // Discard 0 pie
        if (v.sumPctSaleByFirm) {
          seriesData.push({
            name: v.productName,
            y: v.sumPctSaleByFirm,
            color: this.PRODUCT_SERIES_COLORS[v.productName] ?
              this.PRODUCT_SERIES_COLORS[v.productName].color : this.PRODUCT_SERIES_COLORS.Other.color
          });
        }
      });
    }
    const cfg: any = {
      credits: {
        enabled: false
      },
      chart: {
        type: 'pie'
      },
      exporting: {
        enabled: false
      },
      title: {
        text: isSpendByProductVertical ? "Spend by Product Vertical" : "Spend by Product",
        style: {
          fontSize: '11px'
        }
      },
      tooltip: {
        pointFormat: '<b>{point.percentage:.0f}%</b>'
      },
      series: [{
        innerSize: '50%',
        data: seriesData
      }]
    };
    return cfg;
  }
  public ltmSpendAllChart = (chartData): any => {
    const seriesData = _.reverse(_.map(chartData, (vi) => {
      return {
        x: new Date(vi.asOfDate).getTime(),
        y: vi.totalSales
      };
    }));
    const cfg: any = {
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        borderColor: '#f2f2f2',
        borderWidth: 1
      },
      chart: {
        type: 'line'
      },
      title: {
        text: "LTM Spend - All Products",
        style: {
          fontSize: '11px'
        }
      },
      plotOptions: {
        series: {
          symbol: 'circle',
          marker: {
            enabled: false
          }
        }
      },
      tooltip: {
        formatter(): string { // @ts-ignore
          return 'Series "' + this.series.name +
            '" Point ' + Highcharts.dateFormat('%m/%e/%Y', this.x) +
            ' Value $' + Highcharts.numberFormat(this.y, 0, '.', ',');
        }
      },
      xAxis: {
        type: "datetime",
        tickInterval: 30 * 24 * 3600 * 1000,
        labels: {
          rotation: -65,
          formatter(): string {
            return Highcharts.dateFormat('%m/%e/%Y', this.value);
          }
        }
      },
      yAxis: {
        title: false,
        labels: {
          formatter(): string { // @ts-ignore
            return '$' + Highcharts.numberFormat(this.value, 0, '.', ',');
          }
        }
      },
      series: [{
        regression: true,
        regressionSettings: {
          type: 'polynomial',
          color: this.COLOR_PALETTES.BLUE,
          dashStyle: 'shortdot',
          name: "Trend",
        },
        name: 'LTM Spent',
        color: '#000000',
        marker: {
          symbol: 'circle'
        },
        data: seriesData
      }]
    };
    return cfg;
  }

  // Product Details
  public spendBySalesPercentileChartObj = (chartData): any => {
    const seriesData = [];
    _.forEach(chartData, (v) => {
      // Discard 0 pie
      if (v.productSalesPct) {
        seriesData.push({
          name: v.salesPercentileBucket,
          y: v.productSalesPct,
          color: this.PERCENTILE_SERIES_COLORS[v.salesPercentileBucket] ?
            this.PERCENTILE_SERIES_COLORS[v.salesPercentileBucket].color : this.PERCENTILE_SERIES_COLORS.ALL.color
        });
      }
    });
    const cfg: any = {
      credits: {
        enabled: false
      },
      chart: {
        type: 'pie'
      },
      exporting: {
        enabled: false
      },
      title: {
        text: "Spend by Sales Percentile",
        style: {
          fontSize: '11px'
        }
      },
      tooltip: {
        pointFormat: '<b>{point.percentage:.0f}%</b>'
      },
      series: [{
        innerSize: '50%',
        data: seriesData
      }]
    };
    return cfg;
  }
  public ltmSales = (chartData): any => {
    const seriesData = _.reverse(_.map(chartData, (vi) => {
      return {
        x: new Date(vi.asof).getTime(),
        y: vi.sales
      };
    }));
    const cfg: any = {
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        borderColor: '#f2f2f2',
        borderWidth: 1
      },
      chart: {
        type: 'line'
      },
      title: {
        text: "LTM Sales",
        style: {
          fontSize: '11px'
        }
      },
      plotOptions: {
        series: {
          symbol: 'circle',
          marker: {
            enabled: false
          }
        }
      },
      tooltip: {
        formatter(): string { // @ts-ignore
          return 'Series "' + this.series.name +
            '" Point ' + Highcharts.dateFormat('%m/%e/%Y', this.x) +
            ' Value $' + Highcharts.numberFormat(this.y, 0, '.', ',');
        }
      },
      xAxis: {
        type: "datetime",
        tickInterval: 30 * 24 * 3600 * 1000,
        labels: {
          rotation: -65,
          formatter(): string {
            return Highcharts.dateFormat('%m/%e/%Y', this.value);
          }
        }
      },
      yAxis: {
        title: false,
        labels: {
          formatter(): string { // @ts-ignore
            return '$' + Highcharts.numberFormat(this.value, 0, '.', ',');
          }
        }
      },
      series: [{
        regression: true,
        regressionSettings: {
          type: 'polynomial',
          color: this.COLOR_PALETTES.BLUE,
          dashStyle: 'shortdot',
          name: "Trend",
        },
        name: 'LTM Spent',
        color: '#000000',
        marker: {
          symbol: 'circle'
        },
        data: seriesData
      }]
    };
    return cfg;
  }

  public rowClassFunction(row): any {
    if (row.index % 2) {
      return 'odd';
    } else {
      return 'even';
    }
  }

  public getDataObjTableCfg(o?): any {
    const cfg = {
      actions: {add: false, edit: false, delete: false},
      rowClassFunction: this.rowClassFunction,
      pager: {
        perPage: 50,
      },
      columns: {
        firmType: {
          title: 'Firm Type'
        },
        salesPercentileBucket: {
          title: 'Sales Percentile',
          type: 'html',
          valuePrepareFunction: (data) => {
            if (!data) {
              return '';
            }
            return this.columnRightAlight(data);
          },
        },
        value: {
          title: 'Average Account Value',
          type: 'html',
          valuePrepareFunction: (data) => {
            if (!data) {
              return '';
            }
            if (o && o.noFormatterToValCol) {
              return this.columnRightAlight(data);
            } else {
              return this.columnRightAlight(this.currencyFormatter.format(data));
            }
          },
        },
        asOf: {
          title: 'As Of',
          valuePrepareFunction: (data) => {
            if (!data) {
              return '';
            }
            return moment.utc(data).format("MM/DD/yyyy");
          },
          filterFunction: (cell?: any, search?: string) => {
            // cell? is the value of the cell, in this case is a timeStamp
            if (search) {
              return moment.utc(cell).format("MM/DD/yyyy") === search;
            }
            return false;
          }
        }
      }
    };
    return cfg;
  }

  public getAccountObjTableCfg(o?): any {
    const cfg = {
      actions: {add: false, edit: false, delete: false},
      rowClassFunction: this.rowClassFunction,
      pager: {
        perPage: 50,
      },
      columns: {
        firmName: {
          title: 'Name',
          type: 'custom',
          renderComponent: StAlinkViewComponent,
          onComponentInitFunction: (instance) => {
            instance.params = {};
            instance.alinkClicked.subscribe(row => {
              this.openKpiFormAccountProfile(row.firmName);
            });
          }
        },
        totalSales: {
          title: 'Sales',
          type: 'html',
          valuePrepareFunction: (data) => {
            if (!data) {
              return '';
            }
            return this.columnRightAlight(this.currencyFormatter.format(data));
          },
        },
        rank: {
          title: 'Rank',
          type: 'html',
          valuePrepareFunction: (data) => {
            return this.columnCenterAlight(data);
          }
        },
        salesPercentileBucket: {
          title: 'Sales Percentile',
          type: 'html',
          valuePrepareFunction: (data) => {
            return this.columnCenterAlight(data);
          }
        },
        firmType: {
          title: 'Type',
          type: 'html',
          valuePrepareFunction: (data) => {
            return this.columnCenterAlight(data);
          }

        },
        category: {
          title: 'Category',
          type: 'html',
          valuePrepareFunction: (data) => {
            return this.columnCenterAlight(data);
          }
        },
        portal: {
          title: 'Portal',
          type: 'html',
          valuePrepareFunction: (data) => {
            return this.columnCenterAlight(data);
          }
        },
        new: {
          title: 'New',
          type: 'html',
          valuePrepareFunction: (data) => {
            return this.columnCenterAlight(data);
          }
        },
        activity: {
          title: 'Activity',
          type: 'html',
          valuePrepareFunction: (data) => {
            return this.columnCenterAlight(data);
          }
        },
        country: {
          title: 'Country',
          type: 'html',
          valuePrepareFunction: (data) => {
            return this.columnCenterAlight(data);
          }
        },
        asOf: {
          title: 'As Of',
          type: 'html',
          valuePrepareFunction: (data) => {
            if (!data) {
              return '';
            }
            return this.columnCenterAlight(moment.utc(data).format("MM/DD/yyyy"));
          },
          filterFunction: (cell?: any, search?: string) => {
            // cell? is the value of the cell, in this case is a timeStamp
            if (search) {
              return moment.utc(cell).format("MM/DD/yyyy") === search;
            }
            return false;
          }
        }
      }
    };
    return cfg;
  }

  public getRenewalDataObjTableCgf(o?): any {
    const cfg = {
      actions: {add: false, edit: false, delete: false},
      rowClassFunction: this.rowClassFunction,
      pager: {
        perPage: 24,
      },
      columns: {
        firmType: {
          title: 'Firm Type'
        },
        type: {
          title: 'Type',
        },
        salesPercentileBucket: {
          title: 'Sales Percentile',
          type: 'html',
          valuePrepareFunction: (data) => {
            if (!data) {
              return '';
            }
            return this.columnRightAlight(data);
          },
        },
        accountRenewalRate: {
          title: 'Renewal Rate Value',
          type: 'html',
          valuePrepareFunction: (data) => {
            if (!data) {
              return '';
            }
            return this.columnRightAlight(data);
          },
        },
        asOf: {
          title: 'As Of',
          valuePrepareFunction: (data) => {
            if (!data) {
              return '';
            }
            return moment.utc(data).format("MM/DD/yyyy");
          },
          filterFunction: (cell?: any, search?: string) => {
            // cell? is the value of the cell, in this case is a timeStamp
            if (search) {
              return moment.utc(cell).format("MM/DD/yyyy") === search;
            }
            return false;
          }
        }
      }
    };
    return cfg;
  }

  public getRenewalAccountObjTableCfg(o?): any {
    const cfg = {
      actions: {add: false, edit: false, delete: false},
      rowClassFunction: this.rowClassFunction,
      pager: {
        perPage: 50,
      },
      columns: {
        firmName: {
          title: 'Name',
          type: 'custom',
          renderComponent: StAlinkViewComponent,
          onComponentInitFunction: (instance) => {
            instance.params = {};
            instance.alinkClicked.subscribe(row => {
              this.openKpiFormAccountProfile(row.firmName);
            });
          }
        },
        totalSales: {
          title: 'Sales',
          type: 'html',
          valuePrepareFunction: (data) => {
            if (!data) {
              return '';
            }
            return this.columnRightAlight(this.currencyFormatter.format(data));
          },
        },
        rank: {
          title: 'Rank',
          type: 'html',
          valuePrepareFunction: (data) => {
            return this.columnCenterAlight(data);
          }
        },
        salesPercentileBucket: {
          title: 'Sales Percentile',
          type: 'html',
          valuePrepareFunction: (data) => {
            return this.columnCenterAlight(data);
          }
        },
        firmType: {
          title: 'Type',
          type: 'html',
          valuePrepareFunction: (data) => {
            return this.columnCenterAlight(data);
          }
        },
        category: {
          title: 'Category',
          type: 'html',
          valuePrepareFunction: (data) => {
            return this.columnCenterAlight(data);
          }
        },
        portal: {
          title: 'Portal',
          type: 'html',
          valuePrepareFunction: (data) => {
            return this.columnCenterAlight(data);
          }
        },
        new: {
          title: 'New',
          type: 'html',
          valuePrepareFunction: (data) => {
            return this.columnCenterAlight(data);
          }
        },
        activity: {
          title: 'Activity',
          type: 'html',
          valuePrepareFunction: (data) => {
            return this.columnCenterAlight(data);
          }
        },
        country: {
          title: 'Country',
          type: 'html',
          valuePrepareFunction: (data) => {
            return this.columnCenterAlight(data);
          }
        },
        asOf: {
          title: 'As Of',
          type: 'html',
          valuePrepareFunction: (data) => {
            if (!data) {
              return '';
            }
            return this.columnCenterAlight(moment.utc(data).format("MM/DD/yyyy"));
          },
          filterFunction: (cell?: any, search?: string) => {
            // cell? is the value of the cell, in this case is a timeStamp
            if (search) {
              return moment.utc(cell).format("MM/DD/yyyy") === search;
            }
            return false;
          }
        }
      }
    };
    return cfg;
  }

  public columnRightAlight(val): any {
    if (val == null) {
      return val;
    }
    return `<div class="text-right">${val}</div>`;
  }

  public columnCenterAlight(val): any {
    if (val == null) {
      return val;
    }
    return `<div class="text-center">${val}</div>`;
  }

  private openProductDetailsModal(row): void {
    const modalRef = this.modalService.open(KpiFormAccountProductDetailsModalComponent, {size: 'lg'});
    modalRef.componentInstance.productId = row.Product;
  }

  private openKpiFormAccountProfile(firmName): void {
    this.router.navigate([]).then(result => {
      window.open(`/app/kpi_firm_account_profile/${firmName}`, '_blank');
    });
  }
}
