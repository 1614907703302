<div class="container py-5" *ngIf="savedScreens!=null">
  <div class="row">
    <div class="col-md-10 mx-auto">
      <div>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Saved Screens</h4>
          <div class="float-right">
            <button type="button" class="btn btn-warning" (click)="activeModal.dismiss('Cross click')">Cancel
            </button>
            &nbsp;&nbsp;
          </div>
        </div>

        <div class="modal-body">

          <div *ngIf="savedScreens.length===0">
            <div class="mt">Looks like you do not have any saved screens yet. Follow these steps to save your current
              screen.
            </div>
            <ul class="mt">
              <li>Select "Save" -> "Save As" option to open up saving dialog.</li>
              <li>Enter name of screen.</li>
              <li>Click "Save Screen" to save your current screen.</li>
            </ul>
          </div>
          <div *ngIf="savedScreens.length">
            <div class="row">
              <div class="col-md-6">
                <div class="list-group screen-lists" style="max-height: 500px;overflow-y: auto;">
                  <div class="list-group-item"
                       *ngFor="let screen of savedScreens; let i = index"
                       [class.active]="activeScreenIndex === i"
                       (click)="setActiveScreenIndex(i)">
                    {{ screen.title }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div *ngIf="activeScreenIndex === undefined">
                  Select a screen
                </div>
                <div *ngIf="activeScreenIndex !== undefined">
                  <div class="row">
                    <div class="col-md-12">
                      <b>Screen Details</b><br>
                      Name: {{ savedScreens[activeScreenIndex]?.title }}
                    </div>
                    <div class="col-md-12" *ngIf="savedScreens[activeScreenIndex]?.notes">
                      Notes: {{ savedScreens[activeScreenIndex]?.notes }}
                    </div>
                    <div class="col-md-12">
                      Created: {{ savedScreens[activeScreenIndex]?.createdDatetime }}
                    </div>
                    <div class="col-md-12">
                      Last Updated: {{ savedScreens[activeScreenIndex]?.updatedDatetime }}
                    </div>
                  </div>

                  <div class="">
                    <div>
                      <button type="button" class="btn btn-primary"
                              (click)="applySelectedScreen(savedScreens[activeScreenIndex])">Apply
                      </button>
                      &nbsp;&nbsp;
                      <button type="button" class="btn btn-danger"
                              (click)="deleteSelectedScreen(savedScreens[activeScreenIndex])">Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-warning" (click)="activeModal.dismiss()">Cancel</button>
      </div>
    </div>
  </div>
</div>
