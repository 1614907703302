import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule, APP_INITIALIZER} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DragulaModule} from 'ng2-dragula';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {EditableModule} from '@ngneat/edit-in-place';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {ColorPickerModule} from 'ngx-color-picker';
import {NgxFileDropModule} from 'ngx-file-drop';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {CommonModule, DatePipe} from '@angular/common';
import {NgSelectModule} from '@ng-select/ng-select';
import {DpDatePickerModule} from 'ng2-date-picker';
import {NgbDropdownConfig, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {HighchartsChartModule} from 'highcharts-angular';
import {NgxMaskModule, IConfig} from 'ngx-mask-cvas';
import { DecimalPipe } from '@angular/common';
import { DndModule } from 'ngx-drag-drop';
import {ToastrModule} from "ngx-toastr";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";


import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AppsettingsService} from './services/appsettings.service';
import {ErrorInterceptor} from './services/auth/error.interceptor';
import {RequestInterceptor} from './services/auth/request.interceptor';

import {SsHostDirective} from './directives/ss-host.directive';
import {NgVarDirective} from './directives/ng-var.directive';
import {ScollProgressDirective} from './directives/scoll-progress.directive';

import {UserAccessManagementComponent} from './components/superusers/user-access-management/user-access-management.component';
import {EditUserAccessComponent} from './components/superusers/user-access-management/edit-user-access/edit-user-access.component';
import {EditableComponent} from './components/building_block/editable/editable.component';
import {ViewModeDirective} from './components/building_block/editable/view-mode.directive';
import {EditableOnEnterDirective} from './components/building_block/editable/editable-on-enter.directive';
import {EditModeDirective} from './components/building_block/editable/edit-mode.directive';
import {CheckboxComponent} from './components/building_block/checkbox/checkbox.component';
import {DlgConfirmDeleteComponent} from './components/building_block/modal-dialogs/dlg-confirm-delete';
import {
  ButtonViewComponent,
  StDatepickerRenderComponent,
  StDatepickerViewComponent,
  StAlinkViewComponent
} from './components/building_block/st-buttonview/button-view.component';

import {AppDataFetchService} from "./services/app-data-fetch.service";
import {SuperusersDataFetchService} from "./components/superusers/superusers-data-fetch.service";
import {MainComponent} from './components/main/main.component';
import {KpiChartsSettingModalComponent} from './components/kpi_charts_setting_modal/kpi-charts-setting-modal.component';
import {KpiChartAndDataComponentCfg} from "./components/kpi_charts_and_data/kpi-chart-and-data-component-cfg";
import {KpiChartsAndTableBaseComponent} from "./components/kpi_charts_and_data/kpi-charts-and-table-base.component";
import {KpiFirmAccountProfileComponent} from './components/kpi_firm_account_profile/kpi-firm-account-profile.component';
import {KpiFormAccountProductDetailsModalComponent} from './components/kpi_firm_account_profile/kpi-form-account-product-details-modal/kpi-form-account-product-details-modal.component';
import {KpiAccountScreeningComponent} from './components/kpi_account_screening/kpi-account-screening.component';
import {DsDateComponent} from './components/building_block/ds-date/ds-date.component';
import {QuickSearchComponent} from './components/kpi_account_screening/quick-search/quick-search.component';
import {SearchComponent} from './components/kpi_account_screening/search/search.component';
import {KpiProductDetailsComponent} from './components/kpi_product_details/kpi-product-details.component';
import {DistChartComponent} from './components/building_block/dist-chart/dist-chart.component';
import { MasterDataTableComponent } from './components/kpi_account_screening/master-data-table/master-data-table.component';
import { ScreenerCellTypePipe, ScreenerCellFormatterPipe } from './components/kpi_account_screening/services/screener-cell-formatter-pipe.pipe';
import { DisplayComponent } from './components/kpi_account_screening/display/display.component';
import { SortComponent } from './components/kpi_account_screening/sort/sort.component';
import { PublishingComponent } from './components/kpi_account_screening/publishing/publishing.component';
import { DlgRecentComponent } from './components/kpi_account_screening/tool-bar-dialogs/dlg-recent/dlg-recent.component';
import { DlgSaveComponent } from './components/kpi_account_screening/tool-bar-dialogs/dlg-save/dlg-save.component';
import { KpiAccountReviewComponent } from './components/kpi_account_review/kpi-account-review.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

export function initConfig(appConfigService: AppsettingsService): any {
  return () => appConfigService.load();
}

@NgModule({
  declarations: [
    AppComponent,
    EditableComponent,
    ViewModeDirective,
    EditModeDirective,
    CheckboxComponent,
    EditableOnEnterDirective,
    SsHostDirective,
    DlgConfirmDeleteComponent,
    ButtonViewComponent,
    StAlinkViewComponent,
    StDatepickerViewComponent,
    StDatepickerRenderComponent,
    NgVarDirective,
    ScollProgressDirective,
    UserAccessManagementComponent,
    EditUserAccessComponent,
    MainComponent,
    KpiChartsAndTableBaseComponent,
    KpiChartsSettingModalComponent,
    KpiFirmAccountProfileComponent,
    KpiFormAccountProductDetailsModalComponent,
    KpiAccountScreeningComponent,
    DsDateComponent,
    QuickSearchComponent,
    SearchComponent,
    KpiProductDetailsComponent,
    DistChartComponent,
    MasterDataTableComponent,
    ScreenerCellTypePipe,
    ScreenerCellFormatterPipe,
    DisplayComponent,
    SortComponent,
    PublishingComponent,
    DlgRecentComponent,
    DlgSaveComponent,
    KpiAccountReviewComponent
  ],
  imports: [
    CommonModule,
    DragulaModule.forRoot(),
    DragDropModule,
    BrowserModule,
    HttpClientModule,
    NgScrollbarModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    DpDatePickerModule,
    EditableModule,
    NgxSliderModule,
    FontAwesomeModule,
    ColorPickerModule,
    Ng2SmartTableModule,
    NgxFileDropModule,
    HighchartsChartModule,
    NgxMaskModule.forRoot(),
    DndModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppsettingsService],
      multi: true
    },
    NgbDropdownConfig,
    AppDataFetchService,
    SuperusersDataFetchService,
    DatePipe,
    DecimalPipe,
    ScreenerCellFormatterPipe,
    ScreenerCellTypePipe,
    KpiChartAndDataComponentCfg],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(config: NgbDropdownConfig) {
    // customize default values of dropdowns used by this component tree
    config.placement = 'bottom-left';
    config.autoClose = true;
    config.container = 'body';
  }
}
