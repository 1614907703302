import {NgModule, Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {ModelUtilsService} from "./model-utils.service";

@Pipe({
  name: 'screenerCellType'
})
export class ScreenerCellTypePipe implements PipeTransform {
  constructor(private modelUtilsService: ModelUtilsService) {
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? '' : `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }

  transform(data: any, args?: any): any {
    const dataType = args?.type;
    if (dataType === 'date') {
      return this.formatDate(data);
    }
    return data;
  }
}

@Pipe({
  name: 'screenerCellFormatter'
})
export class ScreenerCellFormatterPipe implements PipeTransform {
  constructor(private modelUtilsService: ModelUtilsService) {
  }

  transform(data: any, args?: any): any {
    const formatter = args?.formatter;
    const fractionSize = args?.fractionSize;
    const dataType = args?.type;

    if (data !== null && data !== undefined && ['numeric', 'numericBounds', 'number'].indexOf(dataType) > -1) {
      switch (formatter) {
        case '$':
        case '-$':
          return this.formatCurrency(data, fractionSize, (formatter === '-$' || data < 0));
        case '%':
          return this.formatPercentage(data, fractionSize);
        case 'x':
          return this.formatMultiplier(data, fractionSize);
        default:
          return this.formatGeneralNumeric(data, fractionSize);
      }
    }
    return data;
  }

  private formatCurrency(value: number, fractionSize: number, negativeParentheses: boolean): string {
    const absoluteValue = Math.abs(value);
    const formattedValue = this.modelUtilsService.currencyFormatter(absoluteValue, '', fractionSize);
    if (negativeParentheses) {
      return `($${formattedValue})`;
    } else {
      return `$${formattedValue}`;
    }
  }

  private formatPercentage(value: number, fractionSize: number): string {
    return this.modelUtilsService.currencyFormatter(value, '', fractionSize) + '%';
  }

  private formatMultiplier(value: number, fractionSize: number): string {
    return this.modelUtilsService.currencyFormatter(value, '', fractionSize) + 'x';
  }

  private formatGeneralNumeric(value: number, fractionSize: number): string {
    return this.modelUtilsService.currencyFormatter(value, '', fractionSize);
  }
}
