<div class="mt-3">
  <div class="card card-custom mb-4" style="overflow-x: hidden;">
    <div class="card-header d-block" style="padding: 0 15px 0 0.25rem;">
      <div class="card-title" style="display: block">
        <div class="row">
          <div class="col-md-4">
            <div><p>Account Name</p></div>
            <div>
              <ng-select style="width: 100%" class="custom d-inline-block"
                         [items]="lpAccountNameItems"
                         bindLabel="accountName"
                         bindValue="accountName"
                         [(ngModel)]="selectedAccountName"
                         (search)="onSearchAccountName($event)"
                         (change)="onInputFinished( $event)"
                         loadingText="Searching"
                         appendTo="body">
                <ng-template ng-header-tmp>
                  <p></p>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  {{item.accountName}}
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="col-md-4">
            <div><p>Underlying sales data is as of KPIs as of date</p></div>
            <div>
              <div class="input-group">
                <input ngbDatepicker #d="ngbDatepicker"
                       class="form-control"
                       placeholder="M/D/YYYY"
                       name="endDate"
                       [(ngModel)]="selectedEndDate"
                       (dateSelect)="onInputFinished( $event)"
                       (change)="onInputFinished( $event)"
                       container="body"
                       tabindex="-1"/>
                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                  <fa-icon [icon]="faCalendar"></fa-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-4 d-print-none">
            <div>
              &nbsp;
            </div>
            <div class="text-right text-danger" style="font-size: 25px" *ngIf="tableData">
              <fa-icon [icon]="faPDF" (click)="printPDF()" class="cursor-pointer mr-4"></fa-icon>
              <fa-icon [icon]="faFileExcel" (click)="printExcel()" class="cursor-pointer text-success"></fa-icon>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body p-2" *ngIf="tableData">
      <div *ngFor="let section of tableSections" class="print-friendly">
        <h1 *ngIf="section.groupTitle">{{section.groupTitle}}</h1>
        <h3 *ngIf="section.groupSubTitle">{{section.groupSubTitle}}</h3>
        <p class="font-weight-bolder">{{ section.title }}</p>
        <p class="font-weight-bolder small" *ngIf="section.subTitle">{{section.subTitle}}</p>
        <table class="table table-bordered table-striped mb-5 ">
          <thead>
          <tr class="">
            <th *ngFor="let column of section.columns"
                style="background-color: #337ab7!important; color: white!important;">{{ column.label }}</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let item of tableData[section.sectionKey]; let i = index">
            <tr>
              <td *ngFor="let column of section.columns"
                  [style.text-align]="column.align"
              >
                 <span [innerHTML]="item[column.field] | screenerCellType:{ type: column.type }
                 | screenerCellFormatter:{ type: column.type, formatter: column.formatter, fractionSize: column.fractionSize }"
                       [class.text-danger]="item[column.field]<0 ">
                 </span>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>

        <!-- Check if section has children -->
        <div *ngIf="section.children && section.children.length > 0">
          <div *ngFor="let childSection of section.children" style="margin-left:12%;" class="print-friendly">
            <p class="font-weight-bolder">{{ childSection.title }}</p>
            <table class="table table-bordered table-striped mb-3 ">
              <thead>
              <tr class="">
                <th *ngFor="let column of childSection.columns"
                    style="background-color: #337ab7!important; color: white!important;">{{ column.label }}</th>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let childItem of tableData[childSection.sectionKey]; let i = index">
                <tr>
                  <td *ngFor="let column of childSection.columns"
                      [style.text-align]="column.align" class="">
                    <span [innerHTML]="childItem[column.field] | screenerCellType:{ type: column.type }
                      | screenerCellFormatter:{ type: column.type, formatter: column.formatter, fractionSize: column.fractionSize }"
                          [class.text-danger]="childItem[column.field]<0 ">
                    </span>
                  </td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
