import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import * as moment from "moment";
import {ModelUtilsService} from "../services/model-utils.service";
import {ElasticService} from "../services/elastic.service";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less']
})
export class SearchComponent implements OnInit {
  faTrash = faTrash;

  @Input() fieldDefinitionsToolTipEnabled: any;
  @Input() gridCfg: any;
  @Output() updateAdvSearchSelection = new EventEmitter<any>();

  public staticFilterOptions = {};
  advancedSearch;

  constructor(private modelUtils: ModelUtilsService) {
  }

  ngOnInit(): void {
    this.advancedSearch = this.modelUtils.getAdvancedSearchModel();
    this.advancedSearch.availableCols = this.gridCfg.advancedSearchCols;
    this.advancedSearch.searchConfig = this.gridCfg.advancedSearchConfig;
    this.staticFilterOptions = this.gridCfg.staticFilterOptions;
  }

  watchActiveSearchColIndex(oldValue, newValue): void {
    if (newValue === oldValue || (newValue === "")) {
      return;
    }
    this.updateSearchFiltersDM(newValue);
  }

  addSearchCriteriaForCol(col: any): void {
    this.advancedSearch.selectedCols.push(col);
    this.updateActiveSearchColID(this.advancedSearch.selectedCols.length - 1);
  }

  updateActiveSearchColID(idx: number): void {
    const oldVal = this.advancedSearch.activeSearchColIndex;
    this.advancedSearch.activeSearchColIndex = idx;
    this.watchActiveSearchColIndex(oldVal, idx);
  }

  removeColFromSelectedSearchCols(idx: number): void {
    if (idx === this.advancedSearch.activeSearchColIndex) {
      this.advancedSearch.activeSearchColIndex = -1;
    }

    this.advancedSearch.selectedCols.splice(idx, 1);
    this.advancedSearch.appliedSearchesDM.splice(idx, 1);

    // Call the function to apply the current search after removal
    this.applyCurrentSearch();
  }

  updateCurrentSearchCondition(idx, condition): void {
    this.advancedSearch.appliedSearchesDM[idx].currentCondition = condition;
    this.applyCurrentSearch();
  }

  applyCustomStringSearch(currIdx: number, searchStr: string): void {
    if (
      this.staticFilterOptions[this.advancedSearch.appliedSearchesDM[currIdx].col_id] &&
      searchStr === "ALL"
    ) {
      searchStr = '';
    }
    this.advancedSearch.appliedSearchesDM[currIdx].strExpr = searchStr;
    this.applyCurrentSearch();
  }

  applyCustomNumericSearch(currIdx: number, num: number | null, numLb: number, numUb: number): void {
    if (!this.advancedSearch[currIdx]) {
      this.advancedSearch[currIdx] = {};
    }
    if (num !== null) {
      this.advancedSearch.appliedSearchesDM[currIdx].singleBound = num;
      this.applyCurrentSearch();
    } else {
      this.advancedSearch.appliedSearchesDM[currIdx].bounds = {
        lb: numLb,
        ub: numUb
      };
      this.applyCurrentSearch();
    }
  }

  checkIfTypeAheadIsBlank(v: any, idx: number): void {
    if (v == null || !v.toString().trim().length) {
      this.advancedSearch[idx].selectedOpt = null;
      this.applyCurrentSearch();
    }
  }

  updateSearchConditionAndCalculateSearchDateRange(currDMIdx: number, condition: any): void {
    this.updateCurrentSearchCondition(currDMIdx, condition);
    const currCondition = this.advancedSearch.appliedSearchesDM[currDMIdx].currentCondition;
    if (currCondition.auto_calc) {
      const bounds = this.modelUtils.getDateBounds(currCondition.value);
      this.advancedSearch.appliedSearchesDM[currDMIdx].bounds = bounds;
      this.advancedSearch.appliedSearchesDM[currDMIdx].searchStr = `${bounds.lb} TO ${bounds.ub}`;
      this.applyCurrentSearch();
    } else {
      this.advancedSearch.appliedSearchesDM[currDMIdx].searchStr = '';
    }
  }

  applyCustomDateSearch(currIdx: number, dateStr: string | null, startDateStr: string | null, endDateStr: string | null): void {
    if (dateStr !== null) {
      this.advancedSearch.appliedSearchesDM[currIdx].singleDate = dateStr;
      this.advancedSearch.appliedSearchesDM[currIdx].bounds = undefined;
      this.applyCurrentSearch();
    } else {
      this.advancedSearch.appliedSearchesDM[currIdx].singleDate = '';
      this.advancedSearch.appliedSearchesDM[currIdx].bounds = {
        lb: startDateStr || '',
        ub: endDateStr || ''
      };
      this.applyCurrentSearch();
    }
  }

  updateAndApplyBoolSearch(currIdx: number, boolVal: boolean): void {
    this.advancedSearch.appliedSearchesDM[currIdx].boolValue = boolVal;
    this.applyCurrentSearch();
  }

  updateSearchFiltersDM(colIdx: number): void {
    if (colIdx >= this.advancedSearch.appliedSearchesDM.length) {
      const currActiveSearchCol = this.advancedSearch.selectedCols[colIdx];
      this.advancedSearch.appliedSearchesDM.push(this.getActiveSearchFilterDMForCol(currActiveSearchCol));
    }
  }

  getActiveSearchFilterDMForCol(activeCol: any): any {
    const searchDM = _.cloneDeep(activeCol);
    searchDM.allConditions = this.advancedSearch.searchConfig[searchDM.search_type.toString()].conditions;
    if (searchDM.search_type === 1) {
      searchDM.currentCondition = searchDM.allConditions[0];
    }
    searchDM.searchStr = (this.staticFilterOptions[searchDM.col_id]) ? "ALL" : "";
    return searchDM;
  }

  filterAvailableCols(): any[] {
    // Filter logic for availableCols based on searchSelectedColName
    if (this.advancedSearch.searchSelectedColName) {
      return this.advancedSearch.availableCols.filter(col =>
        col.col_name.toLowerCase().includes(this.advancedSearch.searchSelectedColName.toLowerCase())
      );
    } else {
      return this.advancedSearch.availableCols;
    }
  }

  applyCurrentSearch(): void {
    this.updateTableDMForSearch();
  }

  updateTableDMForSearch(): void {
    this.updateAdvSearchSelection.emit(this.advancedSearch);
  }

  async applySavedScreenAdvanceSearch(saved): Promise<void> {
    return new Promise<void>((resolve) => {
      if (saved && saved.searchModel) {
        this.advancedSearch.appliedSearchesDM = saved.searchModel;
        this.advancedSearch.searchCriteria = saved.operator;
        this.advancedSearch.selectedCols = saved.selectedCols;
        this.applyCurrentSearch();
        resolve();
      }
      else{
        resolve();
      }
    });
  }

}
