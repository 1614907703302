<nav id="app-nav" class="navbar navbar-expand-lg navbar-light bg-light main-page-tab" *ngIf="this.config.SESSIONS.AUTH_USER">
  <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li class="nav-item" >
        <a class="nav-link" [class.active]="isDashboardActive()" routerLink="/kpi_dashboard" queryParamsHandling="merge">KPI Dashboard</a>
      </li>
      <li class="nav-item" >
        <a class="nav-link" [class.active]="isProductActive()" routerLink="/kpi_product_details/null" queryParamsHandling="merge">Product</a>
      </li>
      <li class="nav-item" >
        <a class="nav-link" routerLinkActive="active" routerLink="/kpi_account_screening" queryParamsHandling="merge" target="_blank">Accounts
          Query</a>
      </li>
      <li class="nav-item" >
        <a class="nav-link" routerLinkActive="active" routerLink="/kpi_account_review" queryParamsHandling="merge" target="_blank">Account Review</a>
      </li>
    </ul>

  </div>
</nav>


<div class="container-fluid" id="app-container-fluid">
  <router-outlet>
  </router-outlet>
  <div *ngIf="loading" class="central-loader "></div>
</div>
<!--<app-tour></app-tour>-->
