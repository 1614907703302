<div class="container py-5">
  <div class="row">
    <div class="col-md-10 mx-auto">
      <form [formGroup]="fg" (ngSubmit)="onSubmitUpdateUserAccess()" novalidate>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">User Access</h4>
          <div class="float-right">
            <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cancel
            </button>
            &nbsp;&nbsp;
            <button type="submit" class="btn btn-outline-dark float-right">Save</button>
          </div>
        </div>
        <div class="modal-body">
          <div class="error-msg-container alert-danger" *ngIf="errorMsgHtml">{{errorMsgHtml}}</div>

          <div class="form-group row" *ngIf="fg.get('sub').value">
            <label for="sub" class="col-sm-2  col-form-label">Sub</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" id="sub" formControlName="sub" >
            </div>
          </div>

          <div class="form-group row">
            <label for="email" class="col-sm-2  col-form-label">Email</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" id="email" formControlName="email">
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Admin Level</label>
            <div class="col-sm-10">
              <ng-select appendTo="body" class="custom" [items]="lpAdminLevel"
                         appearance="outline"
                         [searchable]="false"
                         [clearable]="false"
                         bindLabel="title"
                         dropdownPosition="bottom"
                         bindValue="value"
                         labelForId="adminLevel"
                         placeholder=""
                         [virtualScroll]="true"
                         formControlName="adminLevel">
                <ng-template ng-option-tmp let-item="item">
                  <div><span> </span><span>{{item.title}}</span></div>
                </ng-template>
              </ng-select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label">Status</label>
            <div class="col-sm-10">
              <ng-select appendTo="body" class="custom" [items]="lpApiKeyStatusId"
                         appearance="outline"
                         [searchable]="false"
                         [clearable]="false"
                         bindLabel="title"
                         dropdownPosition="bottom"
                         bindValue="value"
                         labelForId="statusId"
                         placeholder=""
                         [virtualScroll]="true"
                         formControlName="statusId">
                <ng-template ng-option-tmp let-item="item">
                  <div><span> </span><span>{{item.title}}</span></div>
                </ng-template>
              </ng-select>
            </div>
          </div>

          <div class="form-group row" *ngIf="!currentUserSub">
            <label for="password" class="col-sm-2  col-form-label">Password</label>
            <div class="col-sm-10">
              <input type="password" class="form-control" id="password" formControlName="password">
            </div>
          </div>

          <div class="form-group row" *ngIf="!currentUserSub">
            <label for="confirmPassword" class="col-sm-2  col-form-label">Confirm Password</label>
            <div class="col-sm-10">
              <input type="password" class="form-control" id="confirmPassword" formControlName="confirmPassword">
            </div>
          </div>

          <div class="form-group row">
            <label for="comment" class="col-sm-2  col-form-label">Comment</label>
            <div class="col-sm-10">
              <textarea formControlName="comment" id="comment" style="min-width: 100%" class="form-control"
                        autosize></textarea>
            </div>
          </div>

        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss()">Cancel</button>
          <button type="submit" class="btn btn-outline-dark float-right">Save</button>
        </div>

      </form>
    </div>
  </div>
</div>
