<div class="row p-0">
  <div class="col-md-12">
    <div class="panel panel-default">
      <div class="panel-heading">
        <b>Export As</b>
      </div>
      <div class="panel-body mt-4">
        <div class="row">
          <div class="col-md-3">
            <p class="dashboard-subheader">Export Options</p>
            <form name="sortOrder">
              <label>
                <input type="radio" value="DISPLAYED-GRID" [(ngModel)]="publishOpts" [ngModelOptions]="{standalone: true}">
                Displayed Data Only
              </label><br/>
              <label>
                <input type="radio" value="ALL-GRID" [(ngModel)]="publishOpts" [ngModelOptions]="{standalone: true}">
                All Available Fields
              </label><br/>
            </form>
          </div>

          <div class="col-md-2" align="center">
            <p class="dashboard-subheader">XLS</p>
            <span class="btn btn-lg btn-default" (click)="onPublishExcel(publishOpts)">
               <fa-icon [icon]="faExcel" class=" fa-3x excel-icon text-success"></fa-icon>

            </span>
          </div>

          <div *ngIf="printInProgress" class="col-md-2 center">
            <p class="dashboard-subheader publishing-status">Exporting</p>
            <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
