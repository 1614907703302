<div class="card-main-wrapper">
  <div>
    <div class="card card-custom">
      <div class="card-header" style="padding: 0 15px 0 0.25rem; background-color: #337ab7;">
        <div class="card-toolbar">
          <button type="button" class="btn" id="app-tour-screen-8-collapse-btn"
                  (click)="isTopObjFramesCollapsed=!isTopObjFramesCollapsed"
                  [attr.aria-expanded]="!isTopObjFramesCollapsed"
                  aria-controls="collapseIframe">
            <fa-icon [icon]="faChevronCircleDown" class="fa-cheveron  text-white"
                     *ngIf="isTopObjFramesCollapsed;else tplChevUp"></fa-icon>
            <ng-template #tplChevUp>
              <fa-icon [icon]="faChevronCircleUp" class="fa-cheveron text-white"></fa-icon>
            </ng-template>
          </button>
          <div class="card-title text-white">Screen, Display, Export</div>
        </div>
        <div class="pull-right form-inline">
          <div class="mr-3 text-white font-weight-bold"><cite>{{ appliedSavedScreen.name }}</cite></div>
          <div class="form-inline mr-2 text-white font-weight-bold">
            Field Definitions
            <div class="btn-group ml-1" ngbDropdown [autoClose]="true">
              <button type="button" ngbDropdownToggle class="btn btn-light btn-xs">
                {{!fieldDefinitionsToolTipDisabled ? "ON" : "OFF"}}
              </button>
              <ul ngbDropdownMenu>
                <li ngbDropdownItem role="menuitem">
                  <a (click)="fieldDefinitionsToolTipDisabled=false;">ON</a>
                </li>
                <li ngbDropdownItem role="menuitem">
                  <a (click)="fieldDefinitionsToolTipDisabled=true;">OFF</a>
                </li>
              </ul>
            </div>
          </div>

          <div>
            <button type="button" class="btn btn-light btn-xs mr-1"
                    (click)="$event.stopPropagation(); openMenuDialog('recent-screens')">
              Recent
              <i class="fa fa-bar-chart fa-fw" aria-hidden="true"></i>
            </button>
          </div>

          <div class="btn-group mr-1" ngbDropdown [autoClose]="true">
            <button type="button" ngbDropdownToggle class="btn btn-light btn-xs"
                    (click)="$event.stopPropagation()">
              Save
              <i class="fa fa-floppy-o fa-fw" aria-hidden="true"></i>
            </button>
            <ul ngbDropdownMenu aria-labelledby="save-screen-dropdown">
              <li ngbDropdownItem role="menuitem" [disabled]="!appliedSavedScreen.gridId"
                  [ngClass]="{ 'disabled': (appliedSavedScreen.gridId == undefined) }">
                <a (click)="$event.stopPropagation(); openMenuDialog('save-screen')">Save</a></li>
              <li ngbDropdownItem role="menuitem">
                <a (click)="$event.stopPropagation(); openMenuDialog('save-as-screen')">Save As</a>
              </li>
            </ul>
          </div>

          <div>
            <button type="button" class="btn btn-light btn-xs mr-1"
                    (click)="$event.stopPropagation(); openMenuDialog('reset-filters')">
              Reset
              <i class="fa fa-refresh fa-fw" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>


      <div class="card-body p-0" #collapse="ngbCollapse" [(ngbCollapse)]="isTopObjFramesCollapsed" *ngIf="gridCfg">
        <div class="tabbar_wrapper pt-2">
          <div ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs"
               (navChange)="topObjFrameTabChanged($event)" [destroyOnHide]="false">

            <div [ngbNavItem]="topObjectFramesTabCfg.QUICKSEARCH.POS">
              <a ngbNavLink>
                {{topObjectFramesTabCfg.QUICKSEARCH.LABEL}}
              </a>
              <ng-template ngbNavContent>
                <app-quick-search [screenerDm]="gridCfg"
                                  [fieldDefinitionsToolTipEnabled]="fieldDefinitionsToolTipDisabled"
                                  (updateQuickSearchSelection)="updateQuickSearchSelection($event)"></app-quick-search>
              </ng-template>
            </div>

            <div [ngbNavItem]="topObjectFramesTabCfg.SEARCH.POS">
              <a ngbNavLink>
                {{topObjectFramesTabCfg.SEARCH.LABEL}}
              </a>
              <ng-template ngbNavContent>
                <app-search [gridCfg]="gridCfg"
                            [fieldDefinitionsToolTipEnabled]="fieldDefinitionsToolTipDisabled"
                            (updateAdvSearchSelection)="updateAdvSearchSelection($event)"></app-search>
              </ng-template>
            </div>

            <div [ngbNavItem]="topObjectFramesTabCfg.DISPLAY.POS">
              <a ngbNavLink>
                {{topObjectFramesTabCfg.DISPLAY.LABEL}}
              </a>
              <ng-template ngbNavContent>
                <app-display [gridCfg]="gridCfg"
                             [fieldDefinitionsToolTipEnabled]="fieldDefinitionsToolTipDisabled"
                             (removeColumnFromSortTab)="removeColumnFromSortTab($event)"
                             (updateDisplayColsSelection)="updateDisplayColsSelection($event)"></app-display>
              </ng-template>
            </div>

            <div [ngbNavItem]="topObjectFramesTabCfg.SORT.POS">
              <a ngbNavLink>
                {{topObjectFramesTabCfg.SORT.LABEL}}
              </a>
              <ng-template ngbNavContent>
                <app-sort [gridCfg]="gridCfg"
                          [fieldDefinitionsToolTipEnabled]="fieldDefinitionsToolTipDisabled"
                          (updateSortColsSelection)="updateSortColsSelection($event)"></app-sort>
              </ng-template>
            </div>

            <div [ngbNavItem]="topObjectFramesTabCfg.DOWNLOAD.POS">
              <a ngbNavLink>
                {{topObjectFramesTabCfg.DOWNLOAD.LABEL}}
              </a>
              <ng-template ngbNavContent>
                <app-publishing [gridCfg]="gridCfg" (saveAsExcel)="saveAsExcel($event)"></app-publishing>
              </ng-template>
            </div>

          </div>

          <div [ngbNavOutlet]="nav" style="min-height: 270px;">
          </div>

        </div>
      </div>


    </div>
  </div>
  <div class="card card-custom mt-3" *ngIf="gridCfg">
    <app-master-data-table [gridCfg]="gridCfg"
                           [fieldDefinitionsToolTipEnabled]="fieldDefinitionsToolTipDisabled"
                           (updateFiltersDMForTableQuickSort)="updateFiltersDMForTableQuickSort($event)"></app-master-data-table>
  </div>
</div>
