<div class="row">
  <div class="col-md-12">
    <div class="panel panel-default">
      <div class="panel-heading"><b>Sort Columns</b></div>
      <div class="panel-body">
        <div class="row">
          <div class="col-xs-3 col-md-4">
            <p align="center" class="dashboard-subheader"><b>Sortable Data</b></p>
          </div>
          <div class="col-xs-5 col-md-4">
            <p align="center" class="dashboard-subheader"><b>Sorting Selection</b></p>
          </div>
          <div class="col-xs-4 col-md-4">
            <div *ngIf="sortCols.activeSortFilterID">
              <p align="center" class="dashboard-subheader"><b>Column Sorting Options</b></p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="list-group dashboard-sort-col-available"
                 [dndDropzone]
                 (dndDrop)="onDropped($event, sortCols.availableCols)"
                 dndEffectAllowed="move">
              <a class="list-group-item screener-list-group-item border bg-light" dndPlaceholderRef></a>
              <a class="list-group-item screener-list-group-item"
                 *ngFor="let col of itemNotInSelectedList(sortCols.availableCols,sortCols.selectedCols,'col_id')"
                 dndEffectAllowed="move"
                 [dndDraggable]="col"
                 (dndMoved)="onDnbDragCb(col,sortCols.availableCols,'copy');"
                 [ngbTooltip]="col.col_tp"
                 [disableTooltip]="fieldDefinitionsToolTipEnabled">
                <div>
                  {{col.col_name}}
                </div>
              </a>
            </div>
          </div>
          <div class="col-md-5">
            <div class="list-group dashboard-sort-col-selected"
                 [dndDropzone] class="list-group dashboard-col-selected"
                 (dndDrop)="onDropped($event, sortCols.selectedCols)"
                 dndEffectAllowed="move">
              <a class="list-group-item screener-list-group-item border bg-light" dndPlaceholderRef></a>
              <a *ngFor="let col of sortCols.selectedCols;let i = index"
                 [ngClass]="['list-group-item', 'screener-list-group-item', (col['col_id'] == sortCols.activeSortFilterID ? 'active' : '')]"
                 (click)="updateActiveSortFilterID(col.col_id)"
                 [dndDraggable]="col"
                 dndEffectAllowed="move"
                 (dndMoved)="onDnbDragCb(col, sortCols.selectedCols,'move');"
                 [dndDisableIf]="sortCols.selectedCols.length <= 1"
                 [ngbTooltip]="col.col_tp"
                 [disableTooltip]="fieldDefinitionsToolTipEnabled">
                <div class="row">
                  <div class="col-md-9">
                    {{col.col_name}}
                  </div>
                  <div class="col-md-3 "
                       *ngIf="sortCols.appliedSortFiltersDM && sortCols.appliedSortFiltersDM[col.col_id]">
                    <span class="float-right">
                      <fa-icon [icon]="faSortAsc"  *ngIf="sortCols.appliedSortFiltersDM[col.col_id]['sort_order'] == 'ASC'"
                         class="fa-2x" aria-hidden="true"></fa-icon>
                      <fa-icon [icon]="faSortAscDesc"  *ngIf="sortCols.appliedSortFiltersDM[col.col_id]['sort_order'] == 'DESC'"
                         class="fa-2x" aria-hidden="true"></fa-icon>
                      <span class="btn btn-md btn-default"
                            (click)="removeSortColFromSelectedCols(i); $event.stopPropagation();">
                         <fa-icon [icon]="faTrash" class="fa-2x"></fa-icon>
                      </span>
                    </span>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="col-md-4">
            <div *ngIf="sortCols && sortCols.activeSortFilterID">
              <form name="sortOrder">
                <label>
                  <input type="radio"
                         [(ngModel)]="sortCols.radioModel.sort_order"
                         value="ASC" [ngModelOptions]="{standalone: true}">
                  ASC
                </label><br/>
                <label>
                  <input type="radio"
                         [(ngModel)]="sortCols.radioModel.sort_order"
                         value="DESC" [ngModelOptions]="{standalone: true}">
                  DESC
                </label><br/>
                <label>
                  <input type="radio"
                         [(ngModel)]="sortCols.radioModel.sort_order"
                         value="UNSORTED" [ngModelOptions]="{standalone: true}">
                  UNSORTED
                </label>
                <hr/>
                <button type="button" class="btn btn-danger btn-lg"
                        [disabled]="sortCols.radioModel.sort_order == sortCols.activeSortFilterDM.sort_order"
                        (click)="applyCurrentSortingCol(sortCols.radioModel)">
                  Apply
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
