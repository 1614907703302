import {Component, Input, OnInit} from '@angular/core';
import {AppsettingsService} from "../../../../services/appsettings.service";
import {AppDataFetchService} from "../../../../services/app-data-fetch.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-dlg-save',
  templateUrl: './dlg-save.component.html',
  styleUrls: ['./dlg-save.component.less']
})
export class DlgSaveComponent implements OnInit {
  @Input() reSave: boolean;
  @Input() screenerData: any;
  @Input() originalScreenerInfo: {
    title: any;
    gridId: string;
  };
  fg!: FormGroup;

  constructor(private fb: FormBuilder,
              private  appDataFetchService: AppDataFetchService,
              public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    console.log(this.originalScreenerInfo,this.reSave);
    if (this.reSave) {
      this.save();
    } else {
      this.fg = this.fb.group({
        title: new FormControl('', [Validators.required]),
        notes: new FormControl('', [Validators.required]),
      });
    }
  }

  save(): void {
    this.appDataFetchService.saveScreen({
      title: this.originalScreenerInfo.title,
      gridId: this.originalScreenerInfo.gridId,
      data: JSON.stringify(this.screenerData)
    }).subscribe((response) => {
      this.activeModal.close(response);
    }, (error) => {
      this.activeModal.close(error);
    });
  }

  saveAs(): void {
    this.appDataFetchService.saveScreen({
      title: this.fg.get('title').value,
      notes: this.fg.get('notes').value,
      data: JSON.stringify(this.screenerData)
    }).subscribe((response) => {
      this.activeModal.close(response);
    }, (error) => {
      this.activeModal.close(error);
    });
  }
}
