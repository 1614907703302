import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DatePipe} from '@angular/common';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {faEdit, faTrash, faCalendar, faFilePdf, faWrench, faChartArea} from '@fortawesome/free-solid-svg-icons';

import {DefaultEditor, ViewCell} from 'ng2-smart-table';

@Component({
  selector: 'app-st-alink-view',
  template: `
    <div [ngClass]="{'text-center': params && params.textAlign && params.textAlign === 'center'}">
      <a class="alert-link"
         style="font-weight: inherit;" (click)="onAlinkClick()">{{value}}</a>
    </div>
  `
})
export class StAlinkViewComponent implements ViewCell, OnInit {
  @Input() value: string | number;
  @Input() rowData: any;
  @Output() alinkClicked: EventEmitter<any> = new EventEmitter();
  params: any;

  ngOnInit(): void {
  }

  onAlinkClick(): void {
    this.alinkClicked.emit(this.rowData);
  }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'button-view',
  template: `
    <div class="st-custom-button-view d-flex">
      <button *ngIf="buttonUi.showEdit===true" class="btn {{buttonUi.editBtnClass}}" (click)="onEditClick()">
        <fa-icon [icon]="faEdit"></fa-icon>
      </button>&nbsp;
      <button *ngIf="buttonUi.showDelete===true" class="btn {{buttonUi.deleteBtnClass}}" (click)="onDeleteClick()">
        <fa-icon [icon]="faTrash"></fa-icon>
      </button>
      <button *ngIf="buttonUi.showUsageAnalytics===true" class="btn {{buttonUi.showUsageAnalytics}}"
              (click)="onUsageAnalyticsClick()">
        <fa-icon [icon]="faChart"></fa-icon>
      </button>
      <button *ngIf="buttonUi.showCustomiseAPI===true" class="btn {{buttonUi.showCustomiseAPI}}"
              (click)="onCustomiseAPIClick()">
        <fa-icon [icon]="faKey"></fa-icon>
      </button>
    </div>
  `,
})

export class ButtonViewComponent implements ViewCell, OnInit {
  faEdit = faEdit;
  faTrash = faTrash;
  faFilePdf = faFilePdf;
  faKey = faWrench;
  faChart = faChartArea;
  buttonUi: any;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() customise: EventEmitter<any> = new EventEmitter();
  @Output() usageAnalytics: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
  }

  onEditClick(): void {
    this.save.emit(this.rowData);
  }

  onDeleteClick(): void {
    this.delete.emit(this.rowData);
  }

  onCustomiseAPIClick(): void {
    this.customise.emit(this.rowData);
  }

  onUsageAnalyticsClick(): void {
    this.usageAnalytics.emit(this.rowData);
  }
}

// tslint:disable-next-line:max-line-length
// https://stackblitz.com/edit/ng-date-picker-smart-table-tjvgbe?file=src%2Fapp%2Fsmart-table-datepicker%2Fsmart-table-datepicker.component.ts

@Component({
  selector: 'app-st-datepicker-view',
  template: `
    <div class="input-group">
      <input
        class="form-control"
        name="dp"
        ngbDatepicker #d="ngbDatepicker"
        [(ngModel)]="inputModel"
        (ngModelChange)="onChange($event)"
        tabindex="-1">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
          <fa-icon [icon]="faCalendar"></fa-icon>
        </button>
      </div>
    </div>
  `,
})

export class StDatepickerViewComponent extends DefaultEditor implements OnInit {
  faCalendar = faCalendar;

  @Input() placeholder = 'Choose a Date/Time';
  @Input() min: Date; // Defaults to now(rounded down to the nearest 15 minute mark)
  @Input() max: Date; // Defaults to 1 month after the min

  stringValue;
  inputModel: NgbDateStruct;

  constructor(private formatter: NgbDateParserFormatter) {
    super();
  }

  ngOnInit(): void {
    if (this.cell.newValue) {
      this.inputModel = this.formatter.parse(this.cell.newValue);
    }


    if (!this.inputModel) {
      console.warn('!this.inputModel', this.inputModel);
      this.cell.newValue = this.inputModel;
    }
  }

  onChange(event): void {
    if (this.inputModel) {
      this.cell.newValue = this.formatter.format(this.inputModel);
    } else {
      this.cell.newValue = '';
    }
  }
}

@Component({
  template: `{{value }}`,
})

export class StDatepickerRenderComponent implements ViewCell, OnInit {
  @Input() value: string;
  @Input() rowData: any;

  constructor(private datePipe: DatePipe) {
  }

  ngOnInit(): void {
  }
}

// Others
