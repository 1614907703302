import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ElasticService} from "../services/elastic.service";
import {AppDataFetchService} from "../../../services/app-data-fetch.service";
import {ModelUtilsService} from "../services/model-utils.service";
import {
  faCaretDown, faAngleDoubleLeft, faAngleLeft, faAngleRight, faAngleDoubleRight,
  faSort, faSortAlphaUp, faSortAlphaDown
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-master-data-table',
  templateUrl: './master-data-table.component.html',
  styleUrls: ['./master-data-table.component.less'],
})
// tslint:disable:triple-equals

export class MasterDataTableComponent implements OnInit {
  @Input() gridCfg: any;
  @Input() fieldDefinitionsToolTipEnabled: any;
  @Output() updateTableDMForSearch: any = new EventEmitter<any>();
  @Output() updateFiltersDMForTableQuickSort: any = new EventEmitter<any>();

  faCaretDown = faCaretDown;
  faAngleDoubleLeft = faAngleDoubleLeft;
  faAngleLeft = faAngleLeft;
  faAngleRight = faAngleRight;
  faAngleDoubleRt = faAngleDoubleRight;
  faSort = faSort;
  faSortAsc = faSortAlphaUp;
  faSortDesc = faSortAlphaDown;

  tableDMLoaded = false;
  nestedDMLoaded = false;
  tooltipContent = "View Analysis,<br/>Assumptions, Comps<br/>and more";

  stColDefs: any;
  nestedGridColumnDefs: any;
  tablePagination: any;
  stDM: any;
  nestedDataArr: any;
  dynamicColsHeadersMapping: any;
  nestedGridDM: any;
  quickColFiltersModel: any = {};
  nameValueMap: any;
  scopesService = {
    parent: {
      esSearchRunning: false
    }, emit: (params) => {
      console.log('emit', params);
    }
  };
  errorHandlerService: { defaultAction: any };
  private displayedPage: number;
  private stayOnPage: boolean;

  constructor(private  elasticSvc: ElasticService, private  appDataFetchSvc: AppDataFetchService,
              private modelUtilsSvc: ModelUtilsService) {
  }

  ngOnInit(): void {
    this.initTblDM();
  }

  updateTableContent(esContent: any, masterContentCB?: any): void {
    this.scopesService.emit('es-search-started');
    this.scopesService.parent.esSearchRunning = true;
    if (esContent == null) {
      esContent = {};
    }
    this.elasticSvc.getESBody(esContent).then((esBody) => {
      this.appDataFetchSvc.getMasterData({esBody}).then((masterData) => {
        this.stDM = masterData.currentRows;
        this.tablePagination.refreshPagination(masterData.totalRowsCount);

        const valuationIDs = this.modelUtilsSvc.getValuationIDsForCurrentDM(this.stDM);
        if (masterContentCB) {
          masterContentCB(null, () => {
            this.scopesService.parent.esSearchRunning = false;
            this.scopesService.emit('es-search-finished');
            return valuationIDs;
          });
        } else {
          this.scopesService.parent.esSearchRunning = false;
          this.scopesService.emit('es-search-finished');
          return valuationIDs;
        }
      }, (errMasterData) => masterContentCB(errMasterData));
    }, (esBodyErr) => {
      console.error('esBodyErr', esBodyErr);
      if (esBodyErr != "canceled") {
        this.scopesService.parent.esSearchRunning = false;
        this.scopesService.emit('es-search-finished');
        this.errorHandlerService.defaultAction(esBodyErr);
      }
    });
  }

  updateTableColsForUpdate(params: any): void {
    const updatedStColsDef = this.modelUtilsSvc.getCurrentSTColDefs(params.selectedCols);

    this.modelUtilsSvc.updateSTColDefsForSorting({
      stColDefs: updatedStColsDef,
      sortOrder: params.sortOrder,
      sortModel: params.sortModel
    }, (stColDefs: any) => {
      this.stColDefs = stColDefs;
      this.onTableColsUpdateComplete();
    });
  }

  updateTableColsForSorting(params: any): void {
    const {sortOrder, sortModel} = params;

    this.modelUtilsSvc.updateSTColDefsForSorting({
      stColDefs: this.stColDefs,
      sortOrder,
      sortModel
    }, (stColDefs: any) => {
      this.stColDefs = stColDefs;
      this.onTableColsUpdateComplete();
    });
  }

  quickSortSTTable(stColDef: any): void {
    if (!stColDef.enableSorting) {
      return;
    }

    let nextSort = stColDef.sort;
    if (!nextSort) {
      nextSort = 'asc';
    } else if (nextSort === 'asc') {
      nextSort = 'desc';
    } else {
      nextSort = null;
    }

    if (!stColDef || !nextSort) {
      this.modelUtilsSvc.updateSTColDefsForSorting({
        stColDefs: this.stColDefs
      }, (updatedSTColDefs: any) => {
        this.stColDefs = updatedSTColDefs;
        this.updateFiltersDMForTableQuickSort.emit(); // Remove any sorting
      });
      return;
    }

    const sortOrder = [{
      col_id: stColDef.field
    }];

    const sortModel: any = {};
    sortModel[stColDef.field] = {
      sort_col_id: stColDef.field,
      sort_order: nextSort.toUpperCase()
    };

    this.modelUtilsSvc.updateSTColDefsForSorting({
      stColDefs: this.stColDefs,
      sortOrder,
      sortModel
    }, (updatedSTColDefs: any) => {
      this.stColDefs = updatedSTColDefs;
      this.updateFiltersDMForTableQuickSort.emit({
        sortOrder,
        sortModel
      });
    });
  }

  updateTableForQuickColSearch(colDef: any): void {
    if (!colDef.quickColSearchStr || !colDef.quickColSearchStr.trim().length) {
      this.quickColFiltersModel[colDef.field] = null;
      delete this.quickColFiltersModel[colDef.field];
    } else {
      if (colDef.staticFiltering && colDef.quickColSearchStr === "ALL") {
        this.quickColFiltersModel[colDef.field] = null;
        delete this.quickColFiltersModel[colDef.field];
      } else if (colDef.type === "tree") {
        if (isNaN(colDef.quickColSearchStr)) {
          this.quickColFiltersModel[colDef.field] = null;
          delete this.quickColFiltersModel[colDef.field];
          return;
        }
        // Number goes for term search
        this.quickColFiltersModel[colDef.field] = {
          data: parseInt(colDef.quickColSearchStr, 10),
          type: "term"
        };
      } else if (colDef.quickSearchType === "term") {
        // Custom Term searches
        this.quickColFiltersModel[colDef.field] = {
          data: colDef.quickColSearchStr.toString(),
          type: "term"
        };
      } else {
        // String goes for wildcard
        this.quickColFiltersModel[colDef.field] = {
          data: colDef.quickColSearchStr.toString(),
          type: "wildcard"
        };
      }
    }
    this.updateTableContent({
      quickColFiltersModel: this.quickColFiltersModel
    });
  }

  updateTableDMDelegate(params: any): void {
    // TAKE CARE OF SIZE AND FROM in params;
    this.updateTableContent(params);
  }

  // PRINTING FUNCTION : START
  generateTargetReport(valuationId): any {
  }

  private initTblDM(): void {
    this.stColDefs = this.modelUtilsSvc.getCurrentSTColDefs(this.gridCfg.defaultCols);
    this.tablePagination = {
      numPages: 1,
      currentPage: 1,
      itemsByPage: 10,
      totalItemCount: 0,
      selectPage: (pageNum: number) => this.selectPage(pageNum),
      updateItemsPerPage: (pageSize: number) => this.updateItemsPerPage(pageSize),
      refreshPagination: (totalItemCount?: number) => this.refreshPagination(totalItemCount),
    };

    this.stDM = [];
    this.displayedPage = 10;

    this.updateTableContent(null, (err: any, finalCB: any) => {
      if (err) {
        this.errorHandlerService.defaultAction(err);
      } else {
        this.tableDMLoaded = true;
        if (finalCB) {
          return finalCB();
        }
      }
    });
  }

  private selectPage(pageNum: any): void {
    const pageSize = this.tablePagination.itemsByPage;
    if (isNaN(pageNum) || pageNum > this.tablePagination.numPages) {
      return;
    }

    pageNum = parseInt(pageNum, 10);
    this.updateTableContent({
      size: pageSize,
      from: pageSize * (pageNum - 1)
    }, (err: any, cb: any) => {
      if (err) {
        this.errorHandlerService.defaultAction(err);
      } else {
        this.tablePagination.currentPage = pageNum;
        if (cb) {
          cb();
        }
      }
    });
  }

  private updateItemsPerPage(pageSize: number): void {
    this.updateTableContent({
      size: pageSize,
      from: 0
    }, (err: any, cb: any) => {
      if (err) {
        this.errorHandlerService.defaultAction(err);
      } else {
        this.tablePagination.itemsByPage = pageSize;
        this.tablePagination.refreshPagination();
        if (cb) {
          cb();
        }
      }
    });
  }

  private refreshPagination(totalItemCount?: number): void {
    if (totalItemCount !== undefined) {
      this.tablePagination.totalItemCount = totalItemCount;
    }
    this.tablePagination.numPages = Math.ceil(this.tablePagination.totalItemCount / this.tablePagination.itemsByPage);
    if (this.stayOnPage) {
      this.stayOnPage = false;
    } else {
      this.tablePagination.currentPage = 1;
    }
  }

  isNaN(params): any {
    return isNaN(params);
  }

  onTableColsUpdateComplete(): any {
    console.log("WHAT TO DO: onTableColsUpdateComplete");
    this.updateTableDMForSearch.emit();
  }

}
