<div class="card card-custom mb-4" style="background-color: #f2f2f2">
  <ng-container>
    <div class="text-center" style="margin-top: 15px">
    <span class="font-weight-bolder" style="font-size: large;">
      {{productDetails.product}}
    </span>
      <span class="float-right pr-2">
      <button type="button" class="btn btn-sm btn-primary mt-2" (click)="activeModal.close()">Close</button>
    </span>
    </div>
    <div class="card-body p-2">
      <div>
        <div class="text-center" style="border-bottom: solid 1px gray;">
          <b>Sales</b>
        </div>
        <table class="table table-borderless text-center">
          <tr class="font-weight-bold">
            <td>Rank Total</td>
            <td>% Total</td>
            <td>LTM</td>
            <td>New</td>
            <td>Renewal Rate</td>
          </tr>
          <tr style="background-color: white">
            <td>{{productDetails.RankTotalSales}}</td>
            <td>{{productDetails.PctofTotalSales | number:'1.0-0'}}%</td>
            <td>{{productDetails.Sales | currency : 'USD' : 'symbol' : '1.0-0'}}</td>
            <td>{{productDetails.PctNewSales | number:'1.0-0'}}%</td>
            <td>{{productDetails.RenewalRate | number:'1.0-0'}}%</td>
          </tr>
        </table>

        <table class="table table-borderless text-center">
          <tr class="font-weight-bold">
            <td>Average</td>
            <td>Min</td>
            <td>25th</td>
            <td>50th</td>
            <td>75th</td>
            <td>Max</td>
          </tr>
          <tr style="background-color: white">
            <td>{{productDetails.AverageSales | currency : 'USD' : 'symbol' : '1.0-0'}}</td>
            <td>{{productDetails.MinSales  | currency : 'USD' : 'symbol' : '1.0-0'}}</td>
            <td>{{productDetails['25thSales']  | currency : 'USD' : 'symbol' : '1.0-0'}}</td>
            <td>{{productDetails['50thSales']  | currency : 'USD' : 'symbol' : '1.0-0'}}</td>
            <td>{{productDetails['75thSales']  | currency : 'USD' : 'symbol' : '1.0-0'}}</td>
            <td>{{productDetails.MaxSales  | currency : 'USD' : 'symbol' : '1.0-0'}}</td>
          </tr>
        </table>
      </div>

      <div>
        <div class="text-center" style="border-bottom: solid 1px gray;">
          <b>Demographics</b>
        </div>
        <table class="table table-borderless text-center">
          <tr>
            <td>Accounts</td>
            <td>New</td>
            <td>Users</td>
            <td>New</td>
            <td>Licenses/Products</td>
            <td>New</td>
          </tr>
          <tr style="background-color: white">
            <td>{{productDetails.Accounts  | number:'1.0-0'}}</td>
            <td>{{productDetails.NewAccounts  | number:'1.0-0'}}%</td>
            <td>{{productDetails.Users  | number:'1.0-0'}}</td>
            <td>{{productDetails.NewUsers | number:'1.0-0'}}%</td>
            <td>{{productDetails.LicensesProducts  | number:'1.0-0'}}</td>
            <td>{{productDetails.NewLicensesProducts  | number:'1.0-0'}}%</td>
          </tr>
        </table>
      </div>

      <div class="float-right">
        <a type="button" class="btn btn-sm btn-primary mt-2"
           routerLink="/kpi_product_details/{{productDetails.product}}"
           (click)="activeModal.close()" target="_blank">
          Product Details
        </a>
      </div>
    </div>
  </ng-container>
</div>
