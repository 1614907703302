<div class="mt-3">
  <div class="card card-custom mb-4" style="overflow-x: hidden;" *ngIf="productDetailsDisplayedData">
    <div class="card-header" style="padding: 0 15px 0 0.25rem;">
      <div class="card-title" style="display: block">
        <button type="button" class="btn" style="padding: 0"
                (click)="isProductDetailsProfileCollapsed=!isProductDetailsProfileCollapsed"
                [attr.aria-expanded]="!isProductDetailsProfileCollapsed"
                aria-controls="collapseIframe">
          <fa-icon [icon]="faChevronCircleDown" class="fa-cheveron fa-gray"
                   *ngIf="isProductDetailsProfileCollapsed;else tplChevUp"></fa-icon>
          <ng-template #tplChevUp>
            <fa-icon [icon]="faChevronCircleUp" class="fa-cheveron fa-gray"></fa-icon>
          </ng-template>
        </button>
        <ng-select *ngIf="lpProductsType" class="custom d-inline-block"
                   style="padding-left: 20px; min-width: 90px;"
                   [items]="lpProductsType"
                   appearance="outline"
                   [searchable]="false"
                   [clearable]="false"
                   bindLabel="product_type"
                   dropdownPosition="bottom"
                   bindValue="product_type_id"
                   labelForId="product_type"
                   placeholder=""
                   [virtualScroll]="true"
                   [(ngModel)]="productTypeIdSelected"
                   (change)="updateProductTypeId()"
                   id="product_type">
          <ng-template ng-option-tmp let-item="item">
            <div><span> </span><span>{{item.product_type}}</span></div>
          </ng-template>
        </ng-select>
      </div>
      <div class="card-toolbar">
      </div>
    </div>

    <div class="card-body p-2" #collapse="ngbCollapse" [(ngbCollapse)]="isProductDetailsProfileCollapsed">
      <div class="row">
        <div class="col-md-8">
          <table class="table table-borderless text-center">
            <tr class="font-weight-bold">
              <td>Rank</td>
              <td>% of Spend</td>
              <td>LTM Spend</td>
            </tr>
            <tr class="light-grey ">
              <td>{{productDetailsDisplayedData.Rank}}</td>
              <td>{{productDetailsDisplayedData.pctOfSpend  | number: '1.0-0'   }}%</td>
              <td>{{productDetailsDisplayedData.LTMSpend | currency : 'USD' : 'symbol' : '1.0-0'}}</td>
            </tr>

            <tr class="font-weight-bold">
              <td>New Spend</td>
              <td>Average Spend</td>
              <td>75th Percentile</td>
            </tr>

            <tr class="light-grey ">
              <td>{{productDetailsDisplayedData.NewSpend  | number: '1.0-0'  }}%</td>
              <td>{{productDetailsDisplayedData.AverageSpend | currency : 'USD' : 'symbol' : '1.0-0'}}</td>
              <td>{{productDetailsDisplayedData.percentile75 | currency : 'USD' : 'symbol' : '1.0-0'}}</td>
            </tr>

            <tr class="font-weight-bold">
              <td>Type</td>
              <td>Accounts</td>
              <td>Users</td>
            </tr>

            <tr class="light-grey ">
              <td>{{productDetailsDisplayedData.Type}}</td>
              <td>{{productDetailsDisplayedData.accounts | number}}</td>
              <td>{{productDetailsDisplayedData.users | number}}</td>
            </tr>
          </table>
        </div>
        <div class="col-md-4">
          <highcharts-chart *ngIf="spendBySalesPercentileChartObj"
                            [Highcharts]="Highcharts"
                            [options]="spendBySalesPercentileChartObj"
                            [oneToOne]="true"
                            style="height: 250px; display: block;margin: auto 0;"
          ></highcharts-chart>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8">
          <highcharts-chart *ngIf="ltmSalesChartObj"
                            [Highcharts]="Highcharts"
                            [options]="ltmSalesChartObj"
                            [oneToOne]="true"
                            style="height: 250px; display: block;margin: auto 0;"
          ></highcharts-chart>
        </div>
      </div>
    </div>
  </div>

  <div class="card card-custom mb-4">
    <div class="card-header" style="padding: 0 15px 0 0.25rem;">
      <div class="card-toolbar">
        <button type="button" class="btn"
                (click)="isProdDetailsHistoricalDataCollapsed=!isProdDetailsHistoricalDataCollapsed;"
                [attr.aria-expanded]="!isProdDetailsHistoricalDataCollapsed"
                aria-controls="collapseIframe">
          <fa-icon [icon]="faChevronCircleDown" class="fa-cheveron fa-gray"
                   *ngIf="isProdDetailsHistoricalDataCollapsed;else tplChevUp"></fa-icon>
          <ng-template #tplChevUp>
            <fa-icon [icon]="faChevronCircleUp" class="fa-cheveron fa-gray"></fa-icon>
          </ng-template>
        </button>
        <div class="card-title">Historical Data</div>
      </div>
    </div>
    <div class="card-body p-2" #collapse="ngbCollapse" [(ngbCollapse)]="isProdDetailsHistoricalDataCollapsed">
      <ng2-smart-table class="smart-table-wrapper" [settings]="kpiChartDataComponentCfg.prodDetailsHistoricalData"
                       [source]="prodDetailsHistoricalData"></ng2-smart-table>
    </div>
  </div>

  <div class="card card-custom mb-4">
    <div class="card-header" style="padding: 0 15px 0 0.25rem;">
      <div class="card-toolbar">
        <button type="button" class="btn"
                (click)="isProdDetailsAccountDataCollapsed=!isProdDetailsAccountDataCollapsed;"
                [attr.aria-expanded]="!isProdDetailsAccountDataCollapsed"
                aria-controls="collapseIframe">
          <fa-icon [icon]="faChevronCircleDown" class="fa-cheveron fa-gray"
                   *ngIf="isProdDetailsAccountDataCollapsed;else tplChevUp"></fa-icon>
          <ng-template #tplChevUp>
            <fa-icon [icon]="faChevronCircleUp" class="fa-cheveron fa-gray"></fa-icon>
          </ng-template>
        </button>
        <div class="card-title">Accounts</div>
      </div>
    </div>
    <div class="card-body p-2" #collapse="ngbCollapse" [(ngbCollapse)]="isProdDetailsAccountDataCollapsed">
      <ng2-smart-table class="smart-table-wrapper" [settings]="kpiChartDataComponentCfg.prodDetailsAccountData"
                       [source]="prodDetailsAccountData"></ng2-smart-table>
    </div>
  </div>

  <div class="card card-custom mb-4">
    <div class="card-header" style="padding: 0 15px 0 0.25rem;">
      <div class="card-toolbar">
        <button type="button" class="btn"
                (click)="isProdDetailsSaleDetailsDataCollapsed=!isProdDetailsSaleDetailsDataCollapsed;"
                [attr.aria-expanded]="!isProdDetailsSaleDetailsDataCollapsed"
                aria-controls="collapseIframe">
          <fa-icon [icon]="faChevronCircleDown" class="fa-cheveron fa-gray"
                   *ngIf="isProdDetailsSaleDetailsDataCollapsed;else tplChevUp"></fa-icon>
          <ng-template #tplChevUp>
            <fa-icon [icon]="faChevronCircleUp" class="fa-cheveron fa-gray"></fa-icon>
          </ng-template>
        </button>
        <div class="card-title">Sales Details</div>
      </div>
    </div>
    <div class="card-body p-2" #collapse="ngbCollapse" [(ngbCollapse)]="isProdDetailsSaleDetailsDataCollapsed">
      <ng2-smart-table class="smart-table-wrapper" [settings]="kpiChartDataComponentCfg.prodDetailsSaleDetailsData"
                       [source]="prodDetailsSaleDetailsData"></ng2-smart-table>
    </div>
  </div>

  <div class="card card-custom mb-4">
    <div class="card-header" style="padding: 0 15px 0 0.25rem;">
      <div class="card-toolbar">
        <button type="button" class="btn"
                (click)="isProdDetailsSalesPercentileDataCollapsed=!isProdDetailsSalesPercentileDataCollapsed;"
                [attr.aria-expanded]="!isProdDetailsSalesPercentileDataCollapsed"
                aria-controls="collapseIframe">
          <fa-icon [icon]="faChevronCircleDown" class="fa-cheveron fa-gray"
                   *ngIf="isProdDetailsSalesPercentileDataCollapsed;else tplChevUp"></fa-icon>
          <ng-template #tplChevUp>
            <fa-icon [icon]="faChevronCircleUp" class="fa-cheveron fa-gray"></fa-icon>
          </ng-template>
        </button>
        <div class="card-title">Sales Percentile</div>
      </div>
    </div>
    <div class="card-body p-2" #collapse="ngbCollapse" [(ngbCollapse)]="isProdDetailsSalesPercentileDataCollapsed">
      <ng2-smart-table class="smart-table-wrapper" [settings]="kpiChartDataComponentCfg.prodDetailsSalesPercentileData"
                       [source]="prodDetailsSalesPercentileData"></ng2-smart-table>
    </div>
  </div>

  <div class="card card-custom mb-4">
    <div class="card-header" style="padding: 0 15px 0 0.25rem;">
      <div class="card-toolbar">
        <button type="button" class="btn"
                (click)="isProdDetailsAccountTypeDataCollapsed=!isProdDetailsAccountTypeDataCollapsed;"
                [attr.aria-expanded]="!isProdDetailsAccountTypeDataCollapsed"
                aria-controls="collapseIframe">
          <fa-icon [icon]="faChevronCircleDown" class="fa-cheveron fa-gray"
                   *ngIf="isProdDetailsAccountTypeDataCollapsed;else tplChevUp"></fa-icon>
          <ng-template #tplChevUp>
            <fa-icon [icon]="faChevronCircleUp" class="fa-cheveron fa-gray"></fa-icon>
          </ng-template>
        </button>
        <div class="card-title">Account Type</div>
      </div>
    </div>
    <div class="card-body p-2" #collapse="ngbCollapse" [(ngbCollapse)]="isProdDetailsAccountTypeDataCollapsed">
      <ng2-smart-table class="smart-table-wrapper" [settings]="kpiChartDataComponentCfg.prodDetailsAccountTypeData"
                       [source]="prodDetailsAccountTypeData"></ng2-smart-table>
    </div>
  </div>

  <div class="card card-custom mb-4">
    <div class="card-header" style="padding: 0 15px 0 0.25rem;">
      <div class="card-toolbar">
        <button type="button" class="btn"
                (click)="isProdDetailsAccountCategoryDataCollapsed=!isProdDetailsAccountCategoryDataCollapsed;"
                [attr.aria-expanded]="!isProdDetailsAccountCategoryDataCollapsed"
                aria-controls="collapseIframe">
          <fa-icon [icon]="faChevronCircleDown" class="fa-cheveron fa-gray"
                   *ngIf="isProdDetailsAccountCategoryDataCollapsed;else tplChevUp"></fa-icon>
          <ng-template #tplChevUp>
            <fa-icon [icon]="faChevronCircleUp" class="fa-cheveron fa-gray"></fa-icon>
          </ng-template>
        </button>
        <div class="card-title">Account Category</div>
      </div>
    </div>
    <div class="card-body p-2" #collapse="ngbCollapse" [(ngbCollapse)]="isProdDetailsAccountCategoryDataCollapsed">
      <ng2-smart-table class="smart-table-wrapper" [settings]="kpiChartDataComponentCfg.prodDetailsAccountCategoryData"
                       [source]="prodDetailsAccountCategoryData"></ng2-smart-table>
    </div>
  </div>
</div>

