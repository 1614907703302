<div *ngIf="!tableDMLoaded" class="text-center">
  <div class=""></div>
</div>

<div *ngIf="tableDMLoaded" class="row m-0 p-1">
  <div class="col-md-12">
    <table class="table scr-main-table">
      <thead>
      <tr>
        <td colspan="14" class="text-center pagination-td">
          <div class="row">
            <div class="col page-items-count-dd text-left d-flex">
              <div>
                <div class="d-flex">
                  <span class="page-status-info form-control-static mr-1">View</span>
                  <div class="btn-group" ngbDropdown [autoClose]="true">
                    <button ngbDropdownToggle type="button"
                            class=" btn btn-light mr-1"
                            id="pageSize1" aria-expanded="false">
                      {{tablePagination.itemsByPage}}
                    </button>
                    <ul class="dropdown-menu" ngbDropdownMenu>
                      <li ngbDropdownItem><a
                        (click)="tablePagination.updateItemsPerPage(10)">10</a></li>
                      <li ngbDropdownItem><a
                        (click)="tablePagination.updateItemsPerPage(30)">30</a></li>
                      <li ngbDropdownItem><a
                        (click)="tablePagination.updateItemsPerPage(50)">50</a></li>
                      <li ngbDropdownItem><a
                        (click)="tablePagination.updateItemsPerPage(100)">100</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col text-center">
              <div class="row">
                <div class="">
                  <div class="scr-pagination">
                    <form class="form-inline ng-pristine ng-valid">
                      <button type="button" class="btn btn-light mr-1"
                              (click)="tablePagination.selectPage(1)">
                        <fa-icon [icon]="faAngleDoubleLeft"></fa-icon>
                      </button>
                      <button [disabled]="tablePagination.currentPage <= 1"
                              type="button" class="btn btn-light mr-1"
                              (click)="tablePagination.selectPage(tablePagination.currentPage - 1)">
                        <fa-icon [icon]="faAngleLeft"></fa-icon>
                      </button>
                      <input type="text"
                             class="form-control page-input ng-pristine ng-untouched ng-valid ng-empty mr-1"
                             id="usr1" [(ngModel)]="tablePagination.pageNum"
                             placeholder="Go to page" [ngModelOptions]="{standalone: true}" style="height: 36px">
                      <button [disabled]="!tablePagination.pageNum || isNaN(tablePagination.pageNum)"
                              type="button" class="btn btn-light mr-1"
                              (click)="tablePagination.selectPage(tablePagination.pageNum)"
                              disabled="disabled">Go
                      </button>
                      <button [disabled]="tablePagination.currentPage >= tablePagination.numPages"
                              type="button" class="btn btn-light mr-1"
                              (click)="tablePagination.selectPage(tablePagination.currentPage + 1)"
                              disabled="disabled">
                        <fa-icon [icon]="faAngleRight" class=""></fa-icon>
                      </button>
                      <button type="button" class="btn btn-light mr-1"
                              (click)="tablePagination.selectPage(tablePagination.numPages)">
                        <fa-icon [icon]="faAngleDoubleRt" class=""></fa-icon>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col text-right">
              <div class="">
                                        <span class="page-status-info pull-right pagination-text">
                                            {{(((tablePagination.currentPage - 1) * tablePagination.itemsByPage) + 1) | number: '1.0-0'}}
                                          - {{((tablePagination.currentPage * tablePagination.itemsByPage <= tablePagination.totalItemCount) ? tablePagination.currentPage * tablePagination.itemsByPage : tablePagination.totalItemCount) | number: '1.0-0'}}
                                          of {{ tablePagination.totalItemCount | number: '1.0-0'}}
                                          (Page {{tablePagination.currentPage | number: '1.0-0'}}
                                          / {{tablePagination.numPages | number: '1.0-0'}})
                                        </span>
              </div>
            </div>
          </div>
        </td>
      </tr>
      </thead>

      <thead class="sticky-top" header-scroll-classes="nav-menu-z">
      <tr class="scr-master-grid-header-row">
        <th class="scr-blank-button-cell sticky-topleft-corner">
          <!-- Blank Thead for opening Buttons option -->
        </th>
        <th *ngFor="let colDef of stColDefs" (click)="quickSortSTTable(colDef)"
            class="scr-master-grid-header-cell">
          <span class="noselect" [ngbTooltip]="colDef.colTp"
                [disableTooltip]="fieldDefinitionsToolTipEnabled">
            {{colDef.displayName}}
          </span>
          <span *ngIf="colDef.enableSorting" class="pull-right">
                            <fa-icon [icon]="faSort" *ngIf="!colDef.sort" class=""></fa-icon>
                            <fa-icon [icon]="faSortAsc" *ngIf="colDef.sort == 'asc'" class=""></fa-icon>
                            <fa-icon [icon]="faSortDesc" *ngIf="colDef.sort == 'desc'" class=""></fa-icon>
                        </span>
        </th>
      </tr>
      <tr class="scr-master-grid-header-row">
        <th class="scr-blank-button-cell sticky-topleft-corner">
          <!-- Blank Thead for opening Buttons option -->
        </th>
        <th *ngFor="let colDef of stColDefs" (click)="quickSortSTTable(colDef)"
            class="scr-master-grid-header-cell">
          <input *ngIf="colDef.enableFiltering" (click)="$event.stopPropagation();"
                 [ngModelOptions]="{standalone: true}"
                 [(ngModel)]="colDef.quickColSearchStr"
                 class="form-control scr-col-filter-input noselect"
                 (ngModelChange)="updateTableForQuickColSearch(colDef)"
                 placeholder="Search" type="text"/>
        </th>
      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let row of stDM; let i = index"
          [ngClass]="(i % 2 === 0) ? 'scr-master-grid-data-row even' : 'scr-master-grid-data-row odd'">
        <td class="scr-master-grid-data-cell ">
        </td>
        <td *ngFor="let colDef of stColDefs"
            class="{{'scr-master-grid-data-cell'+' '+colDef.type+' '+colDef.colAlign}}">
                        <span *ngIf="colDef.type == 'target-link'">
                            <a (click)="generateTargetReport(row.TransactionID)" uib-tooltip="Launch Report">
                                {{row[colDef.field]}}
                            </a>
                        </span>
          <span *ngIf="colDef.type != 'target-link'"
                [innerHTML]="row[colDef.field] | screenerCellType:{ type: colDef.type }
                 | screenerCellFormatter:{ type: colDef.type, formatter: colDef.formatter, fractionSize: colDef.fractionSize }"
                [class.text-danger]="row[colDef.field]<0 && (colDef.formatter==='$' || colDef.formatter==='-$')">
                        </span>
        </td>
      </tr>
      </tbody>

      <tfoot>
      <tr>
        <td colspan="14" class="text-center">
          <div class="row">
            <div class="col page-items-count-dd text-left">
              <div>
                <div class="d-flex">
                  <span class="page-status-info form-control-static mr-1">View</span>
                  <div class="btn-group" ngbDropdown [autoClose]="true">
                    <button ngbDropdownToggle type="button"
                            class="dropdown-toggle btn btn-light mr-1"
                            data-toggle="dropdown" id="pageSize" aria-expanded="false">
                      {{tablePagination.itemsByPage}}

                    </button>
                    <ul class="dropdown-menu" ngbDropdownMenu>
                      <li ngbDropdownItem><a
                        (click)="tablePagination.updateItemsPerPage(10)">10</a></li>
                      <li ngbDropdownItem><a
                        (click)="tablePagination.updateItemsPerPage(30)">30</a></li>
                      <li ngbDropdownItem><a
                        (click)="tablePagination.updateItemsPerPage(50)">50</a></li>
                      <li ngbDropdownItem><a
                        (click)="tablePagination.updateItemsPerPage(100)">100</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col text-center">
              <div class="row">
                <div class="">
                  <div class="scr-pagination">
                    <form class="form-inline ng-pristine ng-valid">
                      <button type="button" class="btn  btn-light mr-1"
                              (click)="tablePagination.selectPage(1)">
                        <fa-icon [icon]="faAngleDoubleLeft" class=""></fa-icon>
                      </button>
                      <button [disabled]="tablePagination.currentPage <= 1"
                              type="button" class="btn btn-light mr-1"
                              (click)="tablePagination.selectPage(tablePagination.currentPage - 1)">
                        <fa-icon [icon]="faAngleLeft" class=""></fa-icon>
                      </button>
                      <input type="text"
                             class="form-control page-input ng-pristine ng-untouched ng-valid ng-empty mr-1"
                             id="usr" [(ngModel)]="tablePagination.pageNum"
                             [ngModelOptions]="{standalone: true}"
                             placeholder="Go to page" style="height: 36px">
                      <button [disabled]="!tablePagination.pageNum || isNaN(tablePagination.pageNum)"
                              type="button" class="btn btn-light mr-1"
                              (click)="tablePagination.selectPage(tablePagination.pageNum)"
                              disabled="disabled">Go
                      </button>
                      <button [disabled]="tablePagination.currentPage >= tablePagination.numPages"
                              type="button" class="btn btn-light mr-1"
                              (click)="tablePagination.selectPage(tablePagination.currentPage + 1)"
                              disabled="disabled">
                        <fa-icon [icon]="faAngleRight" class=""></fa-icon>
                      </button>
                      <button type="button" class="btn btn-light mr-1"
                              (click)="tablePagination.selectPage(tablePagination.numPages)">
                        <fa-icon [icon]="faAngleDoubleRt" class=""></fa-icon>
                      </button>
                    </form>
                  </div>
                </div>

              </div>
            </div>
            <div class="col text-right">
              <div class="">
                                        <span class="page-status-info pull-right pagination-text">
                                            {{(((tablePagination.currentPage - 1) * tablePagination.itemsByPage) + 1) | number: '1.0-0'}}
                                          - {{((tablePagination.currentPage * tablePagination.itemsByPage <= tablePagination.totalItemCount) ? tablePagination.currentPage * tablePagination.itemsByPage : tablePagination.totalItemCount) | number: '1.0-0'}}
                                          of {{ tablePagination.totalItemCount | number: '1.0-0'}}
                                          (Page {{tablePagination.currentPage | number: '1.0-0'}}
                                          / {{tablePagination.numPages | number: '1.0-0'}})
                                        </span>
              </div>
            </div>
          </div>
        </td>
      </tr>
      </tfoot>
    </table>
  </div>
</div>
