// tslint:disable-next-line:component-class-suffix
import {ServerDataSource} from "ng2-smart-table";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {ServerSourceConf} from "ng2-smart-table/lib/lib/data-source/server/server-source.conf";
import {Observable} from "rxjs";
import * as _ from 'lodash';
export class CustomServerDataSource extends ServerDataSource {
  extra: any;

  constructor(protected http: HttpClient, inputParams: any | {} = {}, conf: ServerSourceConf | {} = {}) {
    super(http, conf);
    inputParams = inputParams || {};
    this.extra = {
      headers: inputParams.headers,
      body: inputParams
    };
  }

  protected addSortRequestParams(httpParams: HttpParams): HttpParams {
    if (this.sortConf) {
      this.sortConf.forEach((fieldConf) => {
        httpParams = httpParams.set(this.conf.sortFieldKey, fieldConf.field);
        httpParams = httpParams.set(this.conf.sortDirKey, fieldConf.direction.toUpperCase());
      });
    }

    return httpParams;
  }

  protected addFilterRequestParams(httpParams: HttpParams): HttpParams {

    if (this.filterConf.filters) {
      this.filterConf.filters.forEach((fieldConf: any) => {
        if (fieldConf.search) {
          httpParams = httpParams.set(this.conf.filterFieldKey.replace('#field#', fieldConf.field), fieldConf.search);
        }
      });
    }

    return httpParams;
  }

  protected addPagerRequestParams(httpParams: HttpParams): HttpParams {

    if (this.pagingConf && this.pagingConf.page && this.pagingConf.perPage) {
      httpParams = httpParams.set(this.conf.pagerPageKey, this.pagingConf.page);
      httpParams = httpParams.set(this.conf.pagerLimitKey, this.pagingConf.perPage);
    }
    return httpParams;
  }

  protected createRequesParams(): HttpParams {
    let httpParams = new HttpParams();

    httpParams = this.addSortRequestParams(httpParams);
    httpParams = this.addFilterRequestParams(httpParams);
    return this.addPagerRequestParams(httpParams);
  }

  protected requestElements(): Observable<any> {
    const httpParams = this.createRequesParams();
    const smartTableFilters = {};
    httpParams.keys().forEach((key) => {
      smartTableFilters[key] = httpParams.get(key);
    });
    return this.http.post(this.conf.endPoint, _.extend({
      smartTableFilter: smartTableFilters
    }, this.extra.body), {
      params: httpParams,
      headers: this.extra.headers,
      observe: 'response'
    });
  }
}
