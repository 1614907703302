<div class="card-main-wrapper mb-4">
  <div >

    <div class="card card-custom">
      <div class="card-header" style="padding: 0 15px 0 0.25rem;">
        <div class="card-title">
          Admin Management, User, Access
        </div>

      </div>

      <div class="card-body p-2">
        <div class="error-msg-container alert-danger" *ngIf="noAccessToGridMessage">{{noAccessToGridMessage}}</div>
        <ng2-smart-table class="smart-table-wrapper"  [settings]="settings" [source]="source"></ng2-smart-table>
        <div>
          <button type="button" class="btn btn-outline-dark" (click)="openEditUser({})">Add New
          </button>
        </div>
      </div>
    </div>

  </div>
</div>


