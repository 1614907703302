<ng-container class="card-main-wrapper mb-4" *ngFor="let spec of specs; let i = index">
  <div class="card-main-wrapper mb-4" *ngIf="currentKpiName === spec.kpiName">
    <div class="card card-custom mb-4">
      <div class="card-header" style="padding: 0 15px 0 0.25rem;">

        <div class="card-toolbar">
          <button type="button" class="btn"
                  (click)="togglePane('chart',spec);"
                  [attr.aria-expanded]="!spec.isChartCollapsed"
                  aria-controls="collapseIframe">
            <fa-icon [icon]="faChevronCircleDown" class="fa-cheveron fa-gray"
                     *ngIf="spec.isChartCollapsed;else tplChevUp"></fa-icon>
            <ng-template #tplChevUp>
              <fa-icon [icon]="faChevronCircleUp" class="fa-cheveron fa-gray"></fa-icon>
            </ng-template>
          </button>
          <div class="card-title">{{spec.kpiLabel}}</div>
        </div>
      </div>
      <div class="card-body p-2" #collapse="ngbCollapse" [(ngbCollapse)]="spec.isChartCollapsed">
        <highcharts-chart *ngIf="chartObj"
                          [Highcharts]="Highcharts"
                          [options]="chartObj"
                          [oneToOne]="true"
                          style="width: 50%; height: 400px; display: block;margin: 0 auto;"
        ></highcharts-chart>
      </div>
    </div>

    <div class="card card-custom mb-4">
      <div class="card-header" style="padding: 0 15px 0 0.25rem;">
        <div class="card-toolbar">
          <button type="button" class="btn"
                  (click)="togglePane('data',spec);"
                  [attr.aria-expanded]="!spec.isDataCollapsed"
                  aria-controls="collapseIframe">
            <fa-icon [icon]="faChevronCircleDown" class="fa-cheveron fa-gray"
                     *ngIf="spec.isDataCollapsed;else tplChevUp"></fa-icon>
            <ng-template #tplChevUp>
              <fa-icon [icon]="faChevronCircleUp" class="fa-cheveron fa-gray"></fa-icon>
            </ng-template>
          </button>
          <div class="card-title">KPI Data</div>
        </div>
      </div>
      <div class="card-body p-2" #collapse="ngbCollapse" [(ngbCollapse)]="spec.isDataCollapsed">
        <ng2-smart-table class="smart-table-wrapper" [settings]="spec.dataSmartTableCfg"
                         [source]="dataObj"></ng2-smart-table>
      </div>
    </div>

    <div class="card card-custom mb-4">
      <div class="card-header" style="padding: 0 15px 0 0.25rem;">
        <div class="card-toolbar">
          <button type="button" class="btn"
                  (click)="togglePane('account',spec);"
                  [attr.aria-expanded]="!spec.isAccountCollapsed"
                  aria-controls="collapseIframe">
            <fa-icon [icon]="faChevronCircleDown" class="fa-cheveron fa-gray"
                     *ngIf="spec.isAccountCollapsed;else tplChevUp"></fa-icon>
            <ng-template #tplChevUp>
              <fa-icon [icon]="faChevronCircleUp" class="fa-cheveron fa-gray"></fa-icon>
            </ng-template>
          </button>
          <div class="card-title">Accounts</div>
        </div>
      </div>
      <div class="card-body p-2" #collapse="ngbCollapse" [(ngbCollapse)]="spec.isAccountCollapsed">
        <ng2-smart-table class="smart-table-wrapper"  [settings]="spec.accSmartTableCfg"
                         [source]="accObj"></ng2-smart-table>
      </div>
    </div>
  </div>
</ng-container>
