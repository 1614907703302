import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import {Component, Input, OnInit} from '@angular/core';
import {AppDataFetchService} from "../../../services/app-data-fetch.service";
import {AppsettingsService} from "../../../services/appsettings.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-kpi-form-account-product-details-modal',
  templateUrl: './kpi-form-account-product-details-modal.component.html',
  styleUrls: ['./kpi-form-account-product-details-modal.component.less']
})
export class KpiFormAccountProductDetailsModalComponent implements OnInit {
  @Input() productId: any;
  productDetails: any;

  constructor(
    public activeModal: NgbActiveModal,
    private appDataFetchSvc: AppDataFetchService,
    public appSettings: AppsettingsService) {
  }

  ngOnInit(): void {
    this.productDetails={};
    this.appDataFetchSvc.fetchFirmAccountProfileProductModal({product: this.productId}).subscribe(data => {
      this.productDetails = data;
    });
  }

}
