<div class="container py-5" *ngIf="reSave===false">
  <div class="row">
    <div class="col-md-10 mx-auto">
      <form [formGroup]="fg" (ngSubmit)="saveAs()" >
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Save Screen</h4>
          <div class="float-right">
            <button type="button" class="btn btn-warning" (click)="activeModal.dismiss('Cross click')">Cancel
            </button>
            &nbsp;&nbsp;
            <button type="submit" class="btn btn-primary float-right">Save</button>
          </div>
        </div>

        <div class="modal-body">
          <div class="form-group row">
            <label for="title" class="col-sm-2  col-form-label">Title</label>
            <div class="col-sm-10">
              <div class="input-group">
                <input type="text" class="form-control" id="title" formControlName="title">
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="notes" class="col-sm-2  col-form-label">Notes</label>
            <div class="col-sm-10">
              <div class="input-group">
                <input type="text" class="form-control" id="notes" formControlName="notes">
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-warning" (click)="activeModal.dismiss()">Cancel</button>
          <button type="submit" class="btn btn-primary float-right">Save Screen</button>
        </div>

      </form>
    </div>
  </div>
</div>
