import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';
import {faTrash, faSortUp, faSortDown} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.less']
})

// tslint:disable:triple-equals
export class SortComponent implements OnInit {
  @Input() gridCfg: any;
  @Input() fieldDefinitionsToolTipEnabled: any;
  @Output() updateSortColsSelection = new EventEmitter<any>();

  faTrash = faTrash;
  faSortAsc = faSortUp;
  faSortAscDesc = faSortDown;

  sortCols: any;

  constructor() {
  }

  ngOnInit(): void {
    this.sortCols = this.getSortColsModel();
    this.sortCols.availableCols = _.chain(this.gridCfg.allCols)
      .filter(col => col.sortable == true)
      .sortBy('col_name')
      .value();
  }

  getSortColsModel(): any {
    return {
      activeSortFilterID: "",
      activeSortFilterDM: {},
      availableCols: [],
      selectedCols: [],
      appliedSortFiltersDM: {}
    };
  }

  getSortFilterDMForFilterID(sortFilterID): any {
    if (true) {
      return {
        sort_col_id: sortFilterID,
        sort_order: "UNSORTED"
      };
    }
  }

  itemNotInSelectedList(availableItemList, selectedItemList, key): any {
    return _.filter(availableItemList, avlItem =>
      _.findIndex(selectedItemList, selItem => selItem[key] == avlItem[key]) == -1);
  }

  onDnbDragCb(item, list: any, action): any {
    const index = list.indexOf(item);
    list.splice(index, 1);
  }

  onDropped(event: any, list: any): any {
    if (list && (event.dropEffect === 'copy' || event.dropEffect === 'move')) {
      let index = event.index;
      if (typeof index === 'undefined') {
        index = list.length;
      }
      list.splice(index, 0, event.data);
      console.log('on dropped');
    }
  }

  removeSortColFromSelectedCols(colIdx: number): void {
    if (this.sortCols.selectedCols.length <= 0) {
      console.log('No sorting selected');
      return;
    }
    if (this.sortCols.selectedCols[colIdx].col_id === this.sortCols.activeSortFilterID) {
      this.sortCols.activeSortFilterID = '';
    }
    this.sortCols.selectedCols.splice(colIdx, 1);
    this.updateForSortingUpdate();
  }

  updateActiveSortFilterID(colId: string): void {
    this.sortCols.activeSortFilterID = colId;
    this.updateActiveSortFilterDM(this.sortCols.activeSortFilterID);
  }

  applyCurrentSortingCol(sortModel: any): void {
    this.sortCols.appliedSortFiltersDM[sortModel.sort_col_id] = _.cloneDeep(sortModel);
    this.sortCols.activeSortFilterDM = this.sortCols.appliedSortFiltersDM[sortModel.sort_col_id];
    this.updateForSortingUpdate();
  }

  private updateActiveSortFilterDM(sortFilterID: string): void {
    if (!this.sortCols.appliedSortFiltersDM.hasOwnProperty(sortFilterID)) {
      this.sortCols.appliedSortFiltersDM[sortFilterID] = this.getSortFilterDMForFilterID(sortFilterID);
    }
    this.sortCols.radioModel = _.cloneDeep(this.sortCols.appliedSortFiltersDM[sortFilterID]);
    this.sortCols.activeSortFilterDM = this.sortCols.appliedSortFiltersDM[sortFilterID];
    return;
  }

  public removeColumnFromSortingDueToDisplayColsRemoval(removedCol: any): void {
    if (this.sortCols && this.sortCols.availableCols) {
      _.remove(this.sortCols.availableCols, col => col.col_id === removedCol.col_id);
      const removedSorts = _.remove(this.sortCols.selectedCols, col => col.col_id === removedCol.col_id);
      if (removedSorts.length) {
        console.log(removedSorts, "Re-Sort in Parent");
      }
    }
  }

  public updateFiltersDMForTableQuickSort(params): any{
    const { sortOrder, sortModel } = params;

    if (!sortOrder || !sortModel) {
      const availCols = this.sortCols.availableCols;
      this.sortCols = this.getSortColsModel();
      this.sortCols.availableCols = availCols;
      return;
    }

    this.sortCols.selectedCols = _.sortBy(
      _.filter(this.sortCols.availableCols, (col) => sortModel.hasOwnProperty(col.col_id)),
      (col) => _.findIndex(sortOrder, (sortCol) => sortCol.col_id === col.col_id)
    );

    this.sortCols.appliedSortFiltersDM = sortModel;
    this.sortCols.activeSortFilterID = '';
    this.sortCols.activeSortFilterDM = {};
    this.updateSortColsSelection.emit(this.sortCols);
  }

  private updateForSortingUpdate(): any {
    this.updateSortColsSelection.emit(this.sortCols);
  }

  async applySavedScreenSortCols(saved): Promise<void> {
    return new Promise<void>(async (resolve) => {
      if (saved && saved.selectedCols && saved.appliedSortFiltersDM) {
        this.sortCols.selectedCols = saved.selectedCols ;
        this.sortCols.appliedSortFiltersDM = saved.appliedSortFiltersDM;
        this.updateForSortingUpdate();
        resolve();
      }
      else{
        resolve();
      }
    });
  }

}
